import React from "react";
import ParcelFlexbox from "./parcel-flexbox.component";
import FullScreenDialog from "../full-dialog";
import {useState} from "react";
import ParcelCard from "./ParcelCard";
import {Paper, Grid, Box, TextField, Tabs, Button} from "@material-ui/core";
import {Badge} from "@material-ui/core";
import {Tab} from "@material-ui/core";
import {useEffect} from "react";
import {convertPhone} from "functions/convertPhone";
import {Typography} from "@material-ui/core";

const getLabeledParcels = (search, parcels) => {
	let labeledParcels = parcels.filter((parcel) => {
		let choose = false;
		if (parcel.labels) {
			parcel.labels.forEach((label) => {
				if (label.label.toLowerCase().includes(search.toLowerCase())) {
					choose = true;
				}
			});
		}
		return choose;
	});
	return labeledParcels;
};

export default function ParcelFlexboxContainer({parcels, project}) {
	const [parcel, setParcel] = useState();
	// eslint-disable-next-line
	const [owners, setOwners] = useState([]);
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [searchLabel, setSearchLabel] = useState("");
	const [tab, setTab] = useState(0);
	const [labeledParcels, setLabeledParcels] = useState(parcels);
	const [page, setPage] = useState(1);
	const [labelNumber, setLabelNumber] = useState({
		prospect: 0,
		tentative: 0,
		definite: 0,
		confirmed: 0,
		cancelled: 0,
		terminated: 0,
	});

	useEffect(
		() => {
			console.log(parcels);
			let prospectParcels = getLabeledParcels("Prospect", parcels);
			let tentativeParcels = getLabeledParcels("Tentative", parcels);
			let definiteParcels = getLabeledParcels("Definite", parcels);
			let confirmedParcels = getLabeledParcels("Confirmed", parcels);
			let cancelledParcels = getLabeledParcels("Cancelled", parcels);
			let terminatedParcels = getLabeledParcels("Terminated", parcels);
			const labelNumber = {
				prospect: prospectParcels.length,
				tentative: tentativeParcels.length,
				definite: definiteParcels.length,
				confirmed: confirmedParcels.length,
				cancelled: cancelledParcels.length,
				terminated: terminatedParcels.length,
			};
			console.log(labelNumber);
			setLabelNumber(labelNumber);
			setLabeledParcels(parcels);

		},
		[parcels]
	);

	useEffect(
		() => {
			setPage(1);
		},
		[tab]
	);

	useEffect(
		() => {
			setPage(1);
			setTab(0);
		},
		[project]
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
		console.log(newValue);
	};

	let myParcels = parcels;
	switch (tab) {
		case 0:
			break;
		case 1:
			myParcels = getLabeledParcels("Prospect", parcels);
			break;
		case 2:
			myParcels = getLabeledParcels("Tentative", parcels);
			break;
		case 3:
			myParcels = getLabeledParcels("Definite", parcels);
			break;
		case 4:
			myParcels = getLabeledParcels("Confirmed", parcels);
			break;
		case 5:
			myParcels = getLabeledParcels("Cancelled", parcels);
			break;
		case 6:
			myParcels = getLabeledParcels("Terminated", parcels);
			break;
		default:
	}

	let filteredParcels = myParcels;

	filteredParcels = filteredParcels.filter((parcel) => {
		let choose = false;
		if (parcel.project.name.toLowerCase().includes(search.toLowerCase())) {
			choose = true;
		}

		if (parcel.parcelNumber.toLowerCase().includes(search.toLowerCase())) {
			choose = true;
		}

		return choose;
	});

	if (searchLabel) {
		filteredParcels = filteredParcels.filter((parcel) => {
			let choose = false;
			if (parcel.labels) {
				parcel.labels.forEach((label) => {
					if (label.label.toLowerCase().includes(searchLabel.toLowerCase())) {
						choose = true;
					}
				});
			}

			if (parcel.parcelStatus) {
				if (parcel.parcelStatus.toLowerCase().includes(searchLabel.toLocaleLowerCase())) {
					choose = true;
				}
			}

			if (parcel.package) {
				if (parcel.package.name.toLowerCase().includes(searchLabel.toLocaleLowerCase())) {
					choose = true;
				}
			}

			if (parcel.owners) {
				parcel.owners.forEach((owner) => {
					if (owner.name.toLowerCase().includes(searchLabel.toLowerCase())) {
						choose = true;
					}
					if (owner.phone) {
						if (convertPhone(owner.phone).includes(searchLabel.toLowerCase())) {
							choose = true;
						}
					}
				});
			}
			return choose;
		});
	}

	const itemsPerPage = 30;
	let totalPages = Math.ceil(filteredParcels.length / itemsPerPage);
	if (!totalPages) totalPages = 1;
	filteredParcels = filteredParcels.slice((page - 1) * itemsPerPage, page * itemsPerPage);

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Box display="flex" alignItems="center">
					<Tabs
						value={tab}
						onChange={handleChangeTab}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab
							label={
								<Badge badgeContent={parcels.length} max={999} color="primary">
									Main
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["prospect"]} max={999} color="primary">
									Prospect
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["tentative"]} max={999} color="primary">
									Tentative
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["definite"]} max={999} color="primary">
									Definite
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["confirmed"]} max={999} color="primary">
									Confirmed
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["cancelled"]} max={999} color="primary">
									Cancelled
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["terminated"]} max={999} color="primary">
									Terminated
								</Badge>
							}
						/>
					</Tabs>
				</Box>
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={6}>
					<Box mr={1}>
						<TextField
							variant="outlined"
							label="search parcel"
							value={search}
							onChange={(e) => {
								setPage(1);
								setSearch(e.target.value);
							}}
							fullWidth
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<TextField
							variant="outlined"
							label="label, owners and phone"
							value={searchLabel}
							onChange={(e) => {
								setPage(1);
								setSearchLabel(e.target.value);
							}}
							fullWidth
						/>
					</Box>
				</Grid>
			</Grid>
			<Paper style={{width: "100%", padding: "10px"}}>
				{filteredParcels.map((parcel, i) => (
					<ParcelFlexbox
						parcel={parcel}
						setOpen={setOpen}
						setParcel={setParcel}
						setOwners_={setOwners}
						key={i}
					/>
				))}
			</Paper>
			<Paper style={{width: "100%", padding: "10px", marginTop: "10px"}}>
				<Box display="flex" alignItems="center">
					<Button
						variant="outlined"
						style={{marginRight: "5px"}}
						disabled={page === 1}
						onClick={() => {
							window.scrollTo(0, 0);
							setPage(page - 1);
						}}
					>
						Previous Page
					</Button>
					<Button
						variant="outlined"
						disabled={page === totalPages}
						onClick={() => {
							window.scrollTo(0, 0);
							setPage(page + 1);
						}}
					>
						Next Page
					</Button>
					<Typography variant="body1" color="textSecondary" style={{paddingLeft: "10px"}}>
						Page {page} of {totalPages}
					</Typography>
				</Box>
			</Paper>
			<FullScreenDialog
				open={open}
				handleClose={handleClose}
				title="Parcel Card"
				component={<ParcelCard parcel_={parcel} handleCloseCard={handleClose} />}
			/>
		</React.Fragment>
	);
}
