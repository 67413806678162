import {getFirestoreData} from "./getFirestoreData";

export async function loadUsers() {
	try {
		const users = getFirestoreData("users");
		return users;
	} catch (error) {
		console.log(error);
	}
}
