import firebase from "../firebase/firebase-utils";
import { mapLeads } from "./mapLeads";
import { notification } from "./notification";

export function getLeadsFromPagesOnSnapshot(pages, setPagesLeads, setLoading, number) {
  try {
    setLoading(true);
    const arrayPageId = pages.map((page) => page.id);
    console.log(arrayPageId);
    return firebase
      .firestore()
      .collection("leads")
      .where("page_id", "in", arrayPageId)
      .orderBy("created", "desc")
      .limit(number)
      .onSnapshot(
        (querySnapshot) => {
          if (querySnapshot) {
            const leads = querySnapshot.docs.map((doc) => doc.data());
            const mappedLeads = mapLeads(leads);
            setPagesLeads(mappedLeads);
            setLoading(false);
          }
        },
        (err) => {
          console.log(err.message);
          notification("Opps", err.message, "danger");
        }
      );
  } catch (error) {
    console.log(error);
    setLoading(false);
    return () => null;
  }
}
