import { firestore } from "firebase/firebase-utils";
import { mapSnapshot } from "./mapSnapshot";

export async function getTemplates(user) {
  try {
    const snapshot = await firestore
      .collection("templates")
      .where("userEmail", "==", user)
      .get();

    if (snapshot.empty) {
      return [];
    }
    const templates = mapSnapshot(snapshot);
    return templates;
  } catch (error) {
    console.log(error);
  }
}
