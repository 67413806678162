import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import {Link, withRouter} from "react-router-dom";
import CustomizedMenus from "./menu-dropdown";
import {Paper} from "@material-ui/core";
import {AuthContext} from "../../context/auth-context";
import logo from "../../assets/Logo.png";
import {useEffect} from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		color: "white",
		textDecoration: "none",
		fontFamily: "Montserrat, Poppins, Roboto",
	},
	toolbar: theme.mixins.toolbar,
}));

let menuDropDown = [
	{action: "Home", url: "/", icon: "home"},
	{action: "Connect Google", url: "/connect-google", icon: "add_to_drive"},
	{action: "Divider", url: "/", icon: ""},
	{action: "Learning Page", url: "/learning-page", icon: "school"},
	{action: "Divider", url: "/", icon: ""},
	{action: "Create New Owner", url: "/manual-owner", icon: "person_outline"},
	{action: "Create New Parcel", url: "/parcel-create", icon: "bed"},
	{action: "Business Development Module", url: "/business-development", icon: "add_business"},
	{action: "Divider", url: "/", icon: ""},
	{action: "Create New Client", url: "/client-create", icon: "supervisor_account"},
	{action: "Sales Module", url: "/sale-page", icon: "shopping_bag"},
	{action: "Divider", url: "/", icon: ""},
	{action: "Sign Out", url: "/sign-out", icon: "logout"},
];

const Menu = ({history}) => {
	const classes = useStyles();
	const {user} = useContext(AuthContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [menu, setMenu] = useState();

	useEffect(
		() => {
			if (user) {
				setMenu(menuDropDown);
			} else {
				let menu = [{action: "Home", url: "/", icon: "home"}];
				setMenu(menu);
			}
		},
		[user]
	);

	const handleClick = (event) => {
		if (anchorEl) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = (event) => {
		const action = event.currentTarget.getAttribute("data-index");
		console.log(action);
		setAnchorEl(null);
		menuDropDown.every((item) => {
			if (item.action === action) {
				history.push(item.url);
				return false;
			}
			return true;
		});
	};

	return (
		<div className={classes.root}>
			<AppBar position="fixed">
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
						onClick={handleClick}
						style={{marginRight: "0"}}>
						<MenuIcon />
					</IconButton>
					<CustomizedMenus
						anchorEl={anchorEl}
						handleClose={handleClose}
						handleClick={handleClick}
						menuDropDown={menu}
					/>
					<Typography variant="h6" className={classes.title} component={Link} to="/">
						<img className={classes.title} width="100px" alt="logo" src={logo} style={{marginTop: "6px"}} />
					</Typography>
					{user ? (
						<Button color="inherit" component={Link} to="/user-profile">
							{user && user.displayName}
						</Button>
					) : (
						<Button component={Link} to="/sign-in" color="inherit">
							Login {user && user.displayName}
						</Button>
					)}
				</Toolbar>
			</AppBar>
			<Paper>
				<div className={classes.toolbar} />
			</Paper>
		</div>
	);
};

export default withRouter(Menu);
