import { firestore } from "firebase/firebase-utils";

export async function getDisplayName(email) {
  try {
    const querySnapshot = await firestore
      .collection("users")
      .where("email", "==", email)
      .get();
    const user = querySnapshot.docs[0].data();
    return user.displayName;
  } catch (error) {
    console.log(error);
  }
}
