import React, { useContext } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import { useEffect } from "react";
import { getPages } from "../functions/getPages";
import { useState } from "react";
import ChipDc from "../components/ChipDc";
import { getLeadsFromPagesOnSnapshot } from "../functions/getLeadsFromPagesOnSnapshot";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  typography: {
    fontSize: "13px",
  },
}));

const OnSnapshotTable = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [pages, setPages] = useState([]);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unsubscribe = "";
    const fetchData = async () => {
      setLoading(true);
      const pages = await getPages(user.email);
      setPages(pages);
      unsubscribe = getLeadsFromPagesOnSnapshot(pages, setLeads);
      setLoading(false);
    };
    fetchData();

    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  return (
    <Box m={1}>
      <Paper className={classes.control}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Box m={2}>
              <Typography align="center" variant="h5">
                Welcome {user && user.displayName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.control}>
        <Grid container spacing={1}>
          <Grid item align="left" xs={12}>
            <Typography variant="h5">Pages</Typography>
          </Grid>
          {pages.map((page, i) => (
            <Grid item xs={12} key={`${page.name}${i}1`}>
              <Typography>{page.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Paper className={classes.control}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">Leads</Typography>
          </Grid>
          {leads &&
            leads.map((lead, i) => (
              <React.Fragment key={`${lead.name}${i}`}>
                <Grid item xs={1} zeroMinWidth>
                  <Typography noWrap className={classes.typography}>
                    {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={4} zeroMinWidth>
                  <Typography noWrap className={classes.typography}>
                    {lead.name}
                  </Typography>
                </Grid>
                <Grid item xs={5} zeroMinWidth>
                  <Typography
                    noWrap
                    align="right"
                    className={classes.typography}
                  >
                    {lead.phone}
                  </Typography>
                </Grid>
                <Grid item xs={2} zeroMinWidth>
                  {lead.day && <ChipDc text={lead.day} />}
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </Paper>
      <Grid container justify="center">
        <Grid item>{loading && <CircularProgress />}</Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(OnSnapshotTable);
