import { firestore } from "firebase/firebase-utils";

export function loadParcel(parcelId, setParcel) {
  try {
    return firestore
      .collection("parcels")
      .doc(parcelId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const parcel = { ...doc.data(), id: doc.id };
          setParcel(parcel);
        }
      });
  } catch (error) {
    console.log(error);
  }
}
