import { firestore } from "../firebase/firebase-utils"

export async function addRemark(collection, action, doc, remark){
    try {
        const docRef = firestore.collection(collection).doc(doc.id).collection("actions").doc(action.id);
        await docRef.update({remark: remark});
        console.log("Added Remark into action")

    } catch (error) {
        console.log(error)
    }
}