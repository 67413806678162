import {firestore} from "firebase/firebase-utils";

export async function saveTheme(name, project, user) {
	try {
		const theme = {
			name: name,
			projectId: project.id,
			created: new Date(),
			createdBy: user.email,
		};
		const response = await firestore.collection("themes").add(theme);
		return {...theme, id: response.id};
	} catch (error) {
		console.log(error);
	}
}
