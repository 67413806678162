import {Icon} from "@material-ui/core";
import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const checkAlignment = (key) => {
	switch (key) {
		case "name":
		case "address":
		case "transactions":
			return "left";
		default:
			return "center";
	}
};

const checkType = (key, row) => {
	switch (key) {
		case "parcelNumber":
		case "phoneNumber":
			return row[key].toString();
		default:
			return row[key];
	}
};

function ExportToExcel({data, fieldToDisplay, sheetName, filename}) {
	let columns = [];
	let dataSet = [];
	Object.keys(fieldToDisplay).forEach((key) => {
		const column = {
			title: fieldToDisplay[key],
			width: {wpx: 150},
			style: {font: {sz: "16", bold: true}, alignment: {wrapText: true, horizontal: "center", vertical: "top"}},
		};
		columns.push(column);
	});
	data.forEach((row) => {
		let dataRow = [];
		Object.keys(row).forEach((key) => {
			const cell = {
				value: checkType(key, row),
				style: {numFmt: "#,##0.00", alignment: {horizontal: checkAlignment(key), vertical: "center"}},
			};
			dataRow.push(cell);
		});
		dataSet.push(dataRow);
	});

	const styledMultiDataSet = [
		{
			columns: columns,
			data: dataSet,
		},
	];

	console.log(styledMultiDataSet);

	return (
		<ExcelFile filename={filename} element={<Icon color="primary">cloud_download</Icon>}>
			<ExcelSheet dataSet={styledMultiDataSet} name={sheetName} />
		</ExcelFile>
	);
}

export default ExportToExcel;
