import { firestore } from "firebase/firebase-utils";
import users_backup from "../test-functions/backup/users_backup.json";

export async function importUsers() {
  try {
    users_backup.forEach(async (user) => {
      await firestore.collection("users").doc(user.email).set(user);
      console.log(user.email + " uploaded to Firestore");
    });
  } catch (error) {
    console.log(error);
  }
}
