import { Box, Button, Grid, FormControl, Select } from "@material-ui/core";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import UploadFile from "components/upload-file.component";
import React, { useState } from "react";
import { notification } from "functions/notification";
import InputLabel from "@material-ui/core/InputLabel";
import { useEffect } from "react";
import { convertUndefined } from "functions/convertUndefined";
import { saveParcels } from "functions/saveParcels";
import { TextField } from "@material-ui/core";
import { useContext } from "react";
import { ListContext } from "context/list-context";
import { loadProjects } from "functions/loadProjects";
import { createProject } from "functions/createProject";
import { InputContext } from "context/input-context";
import { AuthContext } from "context/auth-context";
import { capitaliseName } from "functions/capitaliseName";

const INITIAL_VALUES = {
    parcelNumber: "",
    type: "",
    size: "",
    parcelStatus: "",
    spaPrice: "",
    netPrice: "",
    package: "",
    ownerName1: "",
    ownerName2: "",
    ownerName3: "",
    nationality: "",
    icNumber1: "",
    icNumber2: "",
    icNumber3: "",
    phone1: "",
    phone2: "",
    phone3: "",
    email1: "",
    email2: "",
    email3: "",
    address: "",
    theme: "",
};

const NAMES = [
    "parcelNumber",
    "type",
    "size",
    "parcelStatus",
    "spaPrice",
    "netPrice",
    "package",
    "ownerName1",
    "ownerName2",
    "ownerName3",
    "nationality",
    "icNumber1",
    "icNumber2",
    "icNumber3",
    "phone1",
    "phone2",
    "phone3",
    "email1",
    "email2",
    "email3",
    "address",
    "theme",
];

export default function SeedParcels() {
    const [rows, setRows] = useState();
    const [columns, setColumns] = useState();
    const [state, setState] = useState(INITIAL_VALUES);
    const [names, setNames] = useState(NAMES);
    const [project, setProject] = useState();
    const { openGlobalList } = useContext(ListContext);
    const { openDialog } = useContext(InputContext);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        console.log(names);
    }, []);

    const handleClickUpload = (files) => {
        console.log(files);
        fileHandler(files);
    };

    const fileHandler = (files) => {
        let fileObj = files[0];

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                setRows(resp.rows);
                setColumns(resp.cols);
            }
        });
    };

    const handleSeed = async () => {
        if (!rows) {
            notification("Opps", "Please select a valid Excel file first", "warning");
            return;
        }
        const parcels = [];
        for (let i = 1; i < rows.length; i++) {
            let parcel = {
                parcelNumber: rows[i][parseInt(state["parcelNumber"])],
                type: rows[i][parseInt(state["type"])],
                size: rows[i][parseInt(state["size"])],
                parcelStatus: rows[i][parseInt(state["parcelStatus"])]?.trim() || "",
                spaPrice: rows[i][parseInt(state["spaPrice"])],
                netPrice: rows[i][parseInt(state["netPrice"])],
                owners: [
                    {
                        name: capitaliseName(rows[i][parseInt(state["ownerName1"])]),
                        nationality: rows[i][parseInt(state["nationality"])],
                        icNumber: rows[i][parseInt(state["icNumber1"])],
                        phone: rows[i][parseInt(state["phone1"])],
                        address: rows[i][parseInt(state["address"])],
                        email: rows[i][parseInt(state["email1"])],
                    },
                    {
                        name: capitaliseName(rows[i][parseInt(state["ownerName2"])]),
                        nationality: rows[i][parseInt(state["nationality"])],
                        icNumber: rows[i][parseInt(state["icNumber2"])],
                        phone: rows[i][parseInt(state["phone2"])],
                        address: rows[i][parseInt(state["address"])],
                        email: rows[i][parseInt(state["email2"])],
                    },
                    {
                        name: capitaliseName(rows[i][parseInt(state["ownerName3"])]),
                        nationality: rows[i][parseInt(state["nationality"])],
                        icNumber: rows[i][parseInt(state["icNumber3"])],
                        phone: rows[i][parseInt(state["phone3"])],
                        address: rows[i][parseInt(state["address"])],
                        email: rows[i][parseInt(state["email3"])],
                    },
                ],
                package: rows[i][parseInt(state["package"])],
                theme: rows[i][parseInt(state["theme"])],
            };
            parcel = convertUndefined(parcel);
            if (parcel.parcelNumber) {
                parcels.push(parcel);
            }
        }
        console.log(parcels);
        await saveParcels(parcels, project, user);
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setState({ ...state, [name]: value });
    };

    const addNewProject = async () => {
        const projectName = await openDialog("Add new project", "Please enter the project name", "Project name", "");
        if (!projectName) {
            return;
        }
        const project = await createProject(projectName);
        console.log(project);
        setProject(project);
    };

    const hanldeClickProject = async () => {
        const projects = await loadProjects();
        console.log(projects);
        const response = await openGlobalList(projects, "Select Project", "name", "location_city", true, true);
        if (!response) {
            return;
        }
        if (response === "addAccount") {
            await addNewProject();
        } else {
            setProject(response);
        }
    };

    return (
        <Box m={1}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <UploadFile handleClickUpload={handleClickUpload} />
                    <Box mt={1}>
                        <Button size="large" variant="contained" color="secondary" onClick={handleSeed}>
                            Seed
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box m={1}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            onClick={hanldeClickProject}
                            label="Project"
                            name="project"
                            value={project && project.name}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {names &&
                        names.map((key, i) => (
                            <Grid item xs={12} md={7} key={i}>
                                <FormControl variant="outlined" fullWidth style={{ margin: "10px" }}>
                                    <InputLabel>{key}</InputLabel>
                                    <Select
                                        native
                                        value={state[key]}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: key,
                                        }}
                                    >
                                        {rows &&
                                            rows[0].map((header, i) => (
                                                <React.Fragment key={i}>
                                                    {i === 0 && <option value={""} />}
                                                    <option value={i}>{header}</option>
                                                </React.Fragment>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))}
                </Grid>

                <Grid item xs={12}>
                    <OutTable
                        data={rows ? rows : []}
                        columns={columns ? columns : []}
                        tableClassName="ExcelTable2007"
                        tableHeaderRowClass="heading"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
