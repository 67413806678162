import moment from "moment";
import { convertDate } from "./convertDate";

export function mapToExcel(parcels, project) {
    console.log(project);
    console.log(parcels);
    const labelsToString = (labels) => {
        if (labels) {
            let label = "";
            labels.forEach((doc) => {
                label = label + doc.label + " ";
            });
            return label;
        } else {
            return "";
        }
    };
    const data = parcels.map((parcel) => {
        const primaryOwner = parcel.primaryOwner ? parcel.primaryOwner : parcel.owners[0];
        let columns = {
            projectName: parcel.project.name,
            parcelNumber: parcel.parcelNumber,
            spaPrice: parcel.spaPrice,
            netPrice: parcel.netPrice,
            labels: labelsToString(parcel.labels),
            name: primaryOwner.name,
            phone: primaryOwner.phone,
            icNumber: primaryOwner.icNumber,
            nationality: primaryOwner.nationality,
            address: primaryOwner.address,
            package: parcel.package.name,
            parcelStatus: parcel.parcelStatus,
            theme: parcel.theme ? parcel.theme.name : "",
            balance: parcel.balance,
            transactions: "",
        };

        if (parcel.transactions) {
            parcel.transactions.sort((a, b) => convertDate(a.date) - convertDate(b.date));
            let transaction = "";
            parcel.transactions.forEach((a) => {
                transaction =
                    transaction +
                    moment(convertDate(a.date)).format("YYYY-MM-DD") +
                    " : " +
                    "RM" +
                    a.amount.toString() +
                    " |\n";
            });
            columns = { ...columns, transactions: transaction };
        }

        if (project.documents) {
            project.documents.forEach((doc, i) => {
                let document = { name: doc, checked: false };
                if (parcel.documents) {
                    let found = parcel.documents.find((a) => a.name === doc);
                    if (found) document = found;
                }
                columns = { ...columns, [`document${i + 1}`]: document.checked ? "✔" : "" };
            });
        }

        return columns;
    });
    console.log(data);
    return data;
}
