import {firestore} from "firebase/firebase-utils";
import firebase from "firebase";

export async function addDocumentToProject(document, project) {
	try {
		await firestore
			.collection("projects")
			.doc(project.id)
			.update({documents: firebase.firestore.FieldValue.arrayUnion(document)});
		console.log("Document " + document + " added into project " + project.name);
	} catch (error) {
		console.log(error);
	}
}
