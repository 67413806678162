import { auth } from "../firebase/firebase-utils";
import { notification } from "./notification";

export async function signIn(state) {
  try {
    const { user } = await auth.signInWithEmailAndPassword(
      state.email.toLowerCase(),
      state.password
    );
    console.log(user.email);
    return user;
  } catch (err) {
    let message = err.message;

    if (err.code === "auth/user-not-found") {
      message =
        message + "\nPlease check if you have entered the correct email";
    }
    notification("Opps", message, "danger");
    console.log(err);
  }
}
