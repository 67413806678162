import {Box, Checkbox, FormControlLabel, FormGroup, Icon, Typography} from "@material-ui/core";
import {ConfirmationContext} from "context/ConfirmationContext";
import {deleteDocumentFromProject} from "functions/deleteDocumentFromProject";
import {updateDocuments} from "functions/updateDocuments";
import {useContext} from "react";
import {useEffect, useState} from "react";

export default function DocumentsCheckList({
	documents_,
	collection,
	docId,
	editDocuments,
	handleRemoveDocument,
}) {
	const [documents, setDocuments] = useState();
	const {openConfirmation} = useContext(ConfirmationContext);

	useEffect(
		() => {
			setDocuments(documents_);
		},
		[documents_]
	);

	const handleChange = async (e) => {
		let newDocuments = [...documents];
		const index = newDocuments.findIndex((doc) => {
			return doc.name === e.target.name;
		});
		newDocuments[index].checked = !newDocuments[index].checked;
		setDocuments(newDocuments);
		await updateDocuments(newDocuments, collection, docId);
	};

	const handleClickDelete = async (doc) => {
		handleRemoveDocument(doc);
	};

	return (
		<Box>
			<FormGroup row>
				{documents &&
					documents.map((doc, i) => (
						<Box display="flex" alignItems="center">
							{editDocuments && (
								<Icon
									size="small"
									color="error"
									style={{cursor: "pointer"}}
									onClick={() => handleClickDelete(doc)}
								>
									delete
								</Icon>
							)}
							<FormControlLabel
								key={i}
								control={
									<Checkbox
										checked={doc.checked}
										onChange={handleChange}
										name={doc.name}
										color="primary"
									/>
								}
								label={
									<Box display="flex" alignItems="center">
										<Typography variant="body2" color="textSecondary">
											{doc.name}
										</Typography>
									</Box>
								}
							/>
						</Box>
					))}
			</FormGroup>
		</Box>
	);
}
