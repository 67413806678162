import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDj0saq4HlF_KCK2YFCZq_UGAhdVkEH-GQ",
	authDomain: "mykey-bd-app.firebaseapp.com",
	projectId: "mykey-bd-app",
	storageBucket: "mykey-bd-app.appspot.com",
	messagingSenderId: "849889437410",
	appId: "1:849889437410:web:4d70e5723a396b416360bb",
	measurementId: "G-CVY9GHPZ2Q",
};

const firebaseConfig_test = {
  apiKey: "AIzaSyD7DY0xIi5-F0mKIgYxKHJAldnF9R8Jl_w",
  authDomain: "mykey-cube-app.firebaseapp.com",
  databaseURL: "https://mykey-cube-app.firebaseio.com",
  projectId: "mykey-cube-app",
  storageBucket: "mykey-cube-app.appspot.com",
  messagingSenderId: "449467205328",
  appId: "1:449467205328:web:d5f900cd40b55efb9380d5",
  measurementId: "G-3ZV5HE4F2E"
};

firebase.initializeApp(firebaseConfig);

// For Local Firebase Emulator
// if (window.location.hostname === "localhost") {
// 	firebase.functions().useEmulator("localhost", 5001);
// 	firebase.firestore().useEmulator("localhost", 8080);
// 	firebase.auth().useEmulator("http://localhost:9099");
// 	console.log("Using Local Emulator");
// }

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export default firebase;
