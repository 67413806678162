import {Button as MuiButton, Icon, styled, Typography as MuiTypography} from "@material-ui/core";
import {Box, Grid} from "@material-ui/core";
import {useState} from "react";
import {spacing} from "@material-ui/system";
import {TextField as MuiTextField} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {InputAdornment} from "@material-ui/core";
import {useContext} from "react";
import {AuthContext} from "context/auth-context";
import {saveTransaction} from "functions/saveTransaction";
import {convertDate} from "functions/convertDate";
import moment from "moment";
import React from "react";
import {Divider} from "@material-ui/core";
import {useEffect} from "react";
import {toCurrency} from "functions/toCurrency";
import {LinearProgress} from "@material-ui/core";
import {ConfirmationContext} from "context/ConfirmationContext";
import {deleteTransaction} from "functions/deleteTransaction";
import FullScreenDialog from "components/full-dialog";
import UploadDocument from "components/UploadDocument";
import {IconButton} from "@material-ui/core";

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const INITIAL_VALUES = {
	date: "",
	description: "",
	amount: "",
};

export default function Payments({collection, doc, title = "", handleClose}) {
	const [type, setType] = useState();
	const [state, setState] = useState(INITIAL_VALUES);
	const [balance, setBalance] = useState();
	const {user} = useContext(AuthContext);
	const {openConfirmation} = useContext(ConfirmationContext);
	const [loading, setLoading] = useState(false);
	const [openUpload, setOpenUpload] = useState(false);
	const [files, setFiles] = useState();

	useEffect(
		() => {
			let balance = 0;
			if (doc.transactions) {
				doc.transactions.forEach((transaction) => {
					balance = balance + parseFloat(transaction.amount);
				});
				setBalance(balance);
			}
		},
		[doc]
	);

	const handleChange = (e) => {
		const {value, name} = e.target;
		setState({...state, [name]: value});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const transaction = await saveTransaction(collection, doc.id, state, type, user);
		setState(INITIAL_VALUES);
		setLoading(false);
		handleClose(transaction, files)
	};

	const handleClickDelete = async (transaction) => {
		const response = await openConfirmation("Confirm delete?", "Do you want to delete this transaction?");
		if (response) {
			setLoading(true);
			await deleteTransaction(transaction);
			setLoading(false);
		}
	};

	const handleCloseUpload = () => {
		setOpenUpload(false);
	};

	const handleFile = (files, noteId) => {
		console.log(files);
		if (files) {
			setFiles(files);
		}
		setOpenUpload(false);
	};

	return (
		<Box m={1}>
			{loading && <LinearProgress />}
			<Grid container spacing={1}>
				<Grid container item xs={12} md={8}>
					<Typography m={1}>{title}</Typography>
				</Grid>
				<Grid container item xs={12} md={8}>
					<Box display="flex" alignItems="center">
						<Button m={1} variant="contained" color="primary" onClick={() => setType("invoice")}>
							Add Invoice
						</Button>
						<Button m={1} variant="contained" color="primary" onClick={() => setType("payment")}>
							Add Payment
						</Button>
					</Box>
				</Grid>
				{type && (
					<form onSubmit={handleSubmit}>
						<Grid container item xs={12} md={8}>
							<Typography m={1} variant="body1" color="textSecondary">
								{type.toUpperCase()}
							</Typography>
							<TextField
								m={1}
								variant="outlined"
								label="Date"
								type="date"
								name="date"
								value={state.date}
								onChange={handleChange}
								required
								InputLabelProps={{shrink: true}}
								fullWidth
							/>
							<TextField
								m={1}
								variant="outlined"
								label="Description"
								type="text"
								name="description"
								value={state.description}
								onChange={handleChange}
								required
								fullWidth
							/>
							<NumberFormat
								m={1}
								variant="outlined"
								customInput={TextField}
								InputProps={{startAdornment: <InputAdornment position="start">RM </InputAdornment>}}
								type="text"
								name="amount"
								value={state.amount}
								onValueChange={(values) => {
									setState({...state, amount: values.value});
								}}
								label="Amount"
								required
								fullWidth
								thousandSeparator={true}
							/>
							<IconButton
								onClick={() => {
									setOpenUpload(true);
								}}
							>
								<Icon color="primary">upload_file</Icon>
								<Typography variant="body1">Upload File</Typography>{" "}
							</IconButton>
							<Grid item xs={12}>
								{files &&
									files.map((file, i) => (
										<React.Fragment key={i}>
											<Box m={1} display="flex" alignItems="center">
												<Icon color="primary">insert_drive_file</Icon>
												<a href={file.downloadURL} key={i} target="blank">
													<Typography m={1} variant="body1" color="textSecondary">
														{file.name}
													</Typography>
												</a>
											</Box>
										</React.Fragment>
									))}
							</Grid>
							<Grid item xs={12}>
								<Button m={1} variant="contained" color="secondary" type="submit">
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
				<Grid item xs={12} md={8}>
					<Divider style={{marginTop: "10px", marginBottom: "10px"}} />
				</Grid>
				{doc.transactions && (
					<React.Fragment>
						<Grid item container xs={12} md={8}>
							<Grid item xs={3}>
								<Typography variant="body2" color="textSecondary">
									Date
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" color="textSecondary">
									Description
								</Typography>
							</Grid>
							<Grid item xs={3} align="right">
								<Typography variant="body2" color="textSecondary">
									Amount
								</Typography>
							</Grid>
							<Grid item xs={1} align="right" />
						</Grid>
						{doc.transactions.map((transaction, i) => (
							<Grid item container xs={12} md={8}>
								<Grid item xs={3}>
									<Typography variant="body2" color="textSecondary">
										{moment(convertDate(transaction.date)).format("YYYY-MM-DD")}
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2" color="textSecondary">
										{" "}
										{transaction.description}
									</Typography>
								</Grid>
								<Grid item xs={3} align="right">
									<Typography variant="body2" color="textSecondary">
										{toCurrency(transaction.amount)}
									</Typography>
								</Grid>
								<Grid item xs={1} align="right">
									<Typography variant="body2" color="textSecondary">
										<Icon
											fontSize="small"
											style={{cursor: "pointer"}}
											onClick={() => handleClickDelete(transaction)}
										>
											clear
										</Icon>
									</Typography>
								</Grid>
							</Grid>
						))}
						<Grid container item xs={12} md={8}>
							<Grid item xs={8} align="right">
								<Typography variant="body2" color="textSecondary">
									Balance:
								</Typography>
							</Grid>
							<Grid item xs={3} align="right">
								<Typography variant="body2" color="textSecondary">
									{balance && toCurrency(balance)}
								</Typography>
							</Grid>
							<Grid item xs={1} />
						</Grid>
					</React.Fragment>
				)}
			</Grid>
			<FullScreenDialog
				open={openUpload}
				handleClose={handleCloseUpload}
				title="Upload file"
				component={<UploadDocument handleFile={handleFile} parcel={doc} />}
			/>
		</Box>
	);
}
