import { firestore } from "firebase/firebase-utils";
import ReactDOMServer from "react-dom/server";

export async function sendEmail(email) {
  const { toEmails, fromEmail, ccEmails, replyTo, subject, htmlObject } = email;

  const html = ReactDOMServer.renderToStaticMarkup(htmlObject);
  const message = {
    subject: subject,
    html: html,
  };

  const emailToBeSent = {
    date: new Date(),
    to: toEmails,
    from: fromEmail,
    cc: ccEmails,
    replyTo: replyTo,
    message: message,
  };

  await firestore.collection("cloudMails").add(emailToBeSent);
  console.log("Email created in Firestore");
}
