import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

export default function ConfirmationDialog({ open, handleClose, title, message }) {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
          {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose("Ok")} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}