import { firestore, storage } from "firebase/firebase-utils";

export async function deleteNote(note, user) {
  try {
    const storageRef = storage.ref();
    const docRef = firestore
      .collection("users")
      .doc(user.id)
      .collection("notes")
      .doc(note.id);
    if (note.files) {
      note.files.forEach(async (file) => {
        storageRef
          .child(file.folderRef)
          .listAll()
          .then((result) => {
            result.items.forEach((imageRef) => {
              imageRef.delete().then(() => {
                console.log(imageRef.name + " is deleted");
              });
            });
          });
      });
      await docRef.delete();
      console.log("Note deleted!");
    }
  } catch (error) {
    console.log(error);
  }
}
