import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import { arrayIsEmpty } from "../functions/arrayIsEmpty";
import { blue } from "@material-ui/core/colors";
import { Icon, Grid, TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function GlobalList(props) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    objects,
    displayName,
    title,
    icon,
    addAccount,
    search,
  } = props;

  const [searchValue, setSearchValue] = useState("");

  const handleClose = () => {
    setSearchValue("");
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    setSearchValue("");
    onClose(value);
  };

  let filteredObjects = objects.filter((object) => {
    if (object[displayName]) {
      if (
        object[displayName].toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title" style={{ padding: "10px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <IconButton size="small" onClick={handleClose}>
                <Icon>clear</Icon>
              </IconButton>
              {title}
            </Box>
          </Grid>
          {search && (
            <Grid item xs={12}>
              <Box m={1}>
                <TextField
                  variant="outlined"
                  label="search"
                  fullWidth
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List style={{ minWidth: "350px", height: "600px" }}>
          {!arrayIsEmpty(objects) &&
            filteredObjects.map((object, i) => (
              <ListItem
                button
                onClick={() => handleListItemClick(object)}
                key={i}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <Icon>{icon}</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={object[displayName]} />
              </ListItem>
            ))}
          {addAccount && (
            <ListItem button onClick={() => handleListItemClick("addAccount")}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add account" />
            </ListItem>
          )}
        </List>
      </div>
    </Dialog>
  );
}
