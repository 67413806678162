import { convertDate } from "./convertDate";
import { getDay } from "./getDay";

export function mapLeads(leads) {
  let mappedleads = leads.map((lead) => {
    let mappedLead = {};
    let { field_data, created_time, lastAction, ...others } = lead;
    let totalViews = 0;
    if (lead.views) {
      totalViews = lead.views.length;
    }

    lead.field_data.forEach((data) => {
      if (
        data.name.toLowerCase().includes("name") ||
        data.name.toLowerCase().includes("全名")
      ) {
        mappedLead = { ...mappedLead, name: data.values[0] };
      } else if (
        data.name.toLowerCase().includes("phone") ||
        data.name.toLowerCase().includes("手机号")
      ) {
        mappedLead = { ...mappedLead, phone: data.values[0] };
      } else if (
        data.name.toLowerCase().includes("email") ||
        data.name.toLowerCase().includes("邮箱")
      ) {
        mappedLead = { ...mappedLead, email: data.values[0] };
      } else if (data.name.toLowerCase().includes("intention")) {
        mappedLead = { ...mappedLead, intention: data.values[0] };
      } else {
        mappedLead = { ...mappedLead, [data.name]: data.values[0] };
      }
    });
    created_time = convertDate(created_time);
    
    let day = getDay(created_time);

    const returnLead = {
      ...mappedLead,
      ...others,
      created_time,
      day: day,
      totalViews: totalViews,
      lastAction: lastAction ? { ...lastAction, created: convertDate(lastAction.created) } : null,
    };
    return returnLead;
  });
  mappedleads.sort(
    (a, b) => new Date(b.created_time) - new Date(a.created_time)
  );
  mappedleads = mappedleads.filter((lead) => !lead.deleted);
  return mappedleads;
}
