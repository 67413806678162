import {firestore} from "firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export async function getFirestoreData(collection, doc) {
	try {
		if (doc) {
			const docSnapshot = await firestore.collection(collection).doc(doc).get();
			if (docSnapshot.exists) {
				const data = {...docSnapshot.data(), id: docSnapshot.id};
				return data;
			} else {
				return null;
			}
		} else {
			const snapshot = await firestore.collection(collection).get();
			const data = mapSnapshot(snapshot);
			return data;
		}
	} catch (error) {
		console.log(error);
	}
}
