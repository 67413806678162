import React, { useContext, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AuthContext } from "../context/auth-context";
import { signIn } from "../functions/signIn";
import { withRouter } from "react-router";
import { notification } from "../functions/notification";
import LinearProgress from "@material-ui/core/LinearProgress";
import { sendEmailVerification } from "../functions/sendEmailVerification";
import { InputContext } from "../context/input-context";
import { forgetPassword } from "../functions/forgetPassword";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://leads.djcsystem.com/">
        DJC Venture
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
  },
}));

export const SignIn = ({ history }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [state, setState] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const {openDialog} = useContext(InputContext);

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        notification("Hello", "You have already signed in", "success");
        history.push("/");
      }
    }
    // eslint-disable-next-line
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = await signIn(state);
    setLoading(false);
    if (user) {
      notification(
        "Welcome",
        "You have signed in successfully",
        "success",
        3000
      );
      setState({ email: "", password: "" });
      history.push("/");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleForgetPassword =async ()=>{
    console.log("Clicked forget password")
    const email = await openDialog("Forget Password", "Please enter your email address", "email", "");
    await forgetPassword(email)
  }

  return !user ? (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={handleChange}
          />
          {loading && <LinearProgress />}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={handleForgetPassword}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                onClick={() => {
                  history.push("/sign-up");
                }}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  ) : (
    <Box m={3}>
      <Grid container justify="center">
        <Grid xs={12} md={8} item>
          <Typography align="center">Please verify your email.</Typography>
          <Typography align="center">Kindly check your mailbox</Typography>
          <Typography align="center" variant="h6">
            {user.email}
          </Typography>
          <Typography align="center">
            for the Email Verification Link.
          </Typography>
        </Grid>
        <Grid xs={8} md={8} item align="center">
          <Box m={2}>
            <Typography variant="subtitle1">
              If you did not receive the email, click{" "}
              <span
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                  setOpenEmail(true);
                }}
              >
                here
              </span>
            </Typography>
            {openEmail && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={sendEmailVerification}
              >
                Send Email Verification
              </Button>
            )}
          </Box>
          <Box m={2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(SignIn);
