import {Box, makeStyles, Tooltip} from "@material-ui/core";
import {CardHeader} from "@material-ui/core";
import {IconButton} from "@material-ui/core";
import {Avatar} from "@material-ui/core";
import {CardContent} from "@material-ui/core";
import {Icon} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {Card, Divider} from "@material-ui/core";
import {blue, green, red} from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import {whatsapp} from "../../functions/whatsapp";
import {callNumber} from "../../functions/callNumber";
import {email} from "../../functions/email";
import {addAction} from "../../functions/addAction";
import {useContext} from "react";
import {AuthContext} from "../../context/auth-context";
import {loadActions} from "../../functions/loadActions";
import {UsersContext} from "../../context/users-context";
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {saveUndoAction} from "../../functions/saveUndoAction";
import {deleteAction} from "../../functions/deleteAction";
import FormDialog from "../../components/form-dialog";
import {addRemark} from "../../functions/addRemark";
import LabelsList from "../../components/labels-list.component";
import {removeLabel} from "../../functions/removeLabel";
import SelectLabel from "../../components/label-select.component";
import {saveLabel} from "../../functions/saveLabel";
import ActionFlexbox from "../../components/action-flexbox/action-flexbox.component";
import FullScreenDialog from "../../components/full-dialog";
import UploadDocument from "../UploadDocument";
import {arrayIsEmpty} from "../../functions/arrayIsEmpty";
import {loadClient} from "../../functions/loadClient";
import ClientCreate from "../../pages/client-create";
import ConnectGoogle from "../../pages/connect-google";
import { AddTodo } from "components/AddTodo";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up("xs")]: {
			width: "100%",
		},
		[theme.breakpoints.up("md")]: {
			width: "600px",
		},
	},
	avatar: {
		backgroundColor: red[500],
	},
	icon: {
		cursor: "pointer",
		marginLeft: "3px",
		color: green[500],
	},
	control: {
		marginRight: "4px",
		color: blue[500],
	},
	appointment: {
		marginRight: "4px",
		color: red[800],
	},
	label: {
		paddingRight: "4px",
		paddingLeft: "4px",
		borderRadius: "6px",
		fontSize: "11px",
	},
}));

export default function SaleCard({client_}) {
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [actions, setActions] = useState([]);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [action, setAction] = useState();
	const [open, setOpen] = useState(false);
	const [label, setLabel] = useState();
	const [openLabel, setOpenLabel] = useState(false);
	const {user} = useContext(AuthContext);
	const {users, setUsers} = useContext(UsersContext);
	const [client, setClient] = useState(client_);
	const [openUpload, setOpenUpload] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [contacts, setContacts] = useState();
	const [openTemplate, setOpenTemplate] = useState(false);
	const [openTodo, setOpenTodo] = useState(false);

	useEffect(() => {
		console.log("Client:");
		console.log(client);
		console.log(contacts);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const unsubscribe = loadActions("clients", client, setActions, users, setUsers, setLoading, user);
		return unsubscribe;
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const unsubscribe = loadClient(client_.id, setClient);

		return unsubscribe;
		// eslint-disable-next-line
	}, []);

	useEffect(
		() => {
			const fetchData = async () => {
				const contacts = client.contacts;
				setContacts(contacts);
			};
			fetchData();
			// eslint-disable-next-line
		},
		[client]
	);

	const handleClickLabel = () => {
		setOpenLabel(true);
	};

	const handleClickAction = async (action, owner) => {
		switch (action) {
			case "Whatsapp":
				whatsapp(owner.phone, owner.name);
				break;
			case "Call":
				callNumber(owner.phone);
				break;
			case "Email":
				email(owner.email);
				break;
			case "Add":
				break;
			case "Google Doc":
				handleOpenTemplate();
				return;
			case "Payment":
			case "Signed document":
			case "Document":
				setAction(action);
				setOpenUpload(true);
				return;
			case "Appointment":
			default:
				console.log(action);
		}
		if (owner) {
			action = action + " " + owner.name;
		}
		await addAction("clients", client.id, action, "", user);
	};

	const handleClickDelete = async (action) => {
		setOpenSnackBar(true);
		setAction(action);
		await deleteAction("clients", action, action.clientId);
	};

	const handleClickRemark = async (action) => {
		setAction(action);
		setOpen(true);
	};

	const handleCloseSnackBar = async (undo) => {
		if (undo === "undo") {
			console.log("undo delete");
			await saveUndoAction("clients", action, client);
			setOpenSnackBar(false);
		} else {
			setOpenSnackBar(false);
		}
	};

	const saveRemark = async (remark) => {
		await addRemark("clients", action, client, remark);
	};

	const handleDeleteLabel = async (label) => {
		await removeLabel("clients", label, client);
	};

	const handleCloseLabel = async (value) => {
		if (!value) {
			setOpenLabel(false);
			return;
		}
		console.log(value);
		setOpenLabel(false);
		setLabel(value); // value = {label: "LabelName", color: "Color", fontColor: "fontColor"}
		await saveLabel("clients", value, client, user);
	};

	const handleClose = () => {
		setOpenUpload(false);
	};

	const handleFile = async (files, noteId) => {
		setOpenUpload(false);
		console.log(files);
		if (!arrayIsEmpty(files)) {
			await addAction("clients", client.id, action, "", user, files);
			console.log("Files saved");
			console.log(files);
		}
	};

	const handleClickEdit = () => {
		setOpenEdit(true);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleOpenTemplate = () => {
		setOpenTemplate(true);
	};

	const handleCloseTemplate = async (file) => {
		setOpenTemplate(false);
		if (!file) {
			return;
		}
		await addAction("clients", client.id, "Google Doc generated", "", user, [file]);
	};

	const handleClickToDo = async () => {
		setOpenTodo(true);
	};

	const handleCloseTodo = () => {
		setOpenTodo(false);
	};

	return (
		<Grid container item xs={12} justify="center">
			<Box m={1}>
				{loading && <CircularProgress />}
				<Card className={classes.root}>
					<CardHeader
						avatar={
							<Avatar aria-label="recipe" className={classes.avatar}>
								{client && client.name}
							</Avatar>
						}
						action={
							<Box display="flex">
								<IconButton aria-label="settings" onClick={handleClickEdit}>
									<Icon>edit</Icon>
								</IconButton>
								<IconButton aria-label="settings" onClick={handleClickLabel}>
									<MoreVertIcon />
								</IconButton>
							</Box>
						}
						title={client && client.name}
						subheader={
							client && (
								<Box display="flex">
									<Typography variant="subtitle1" style={{marginRight: "10px"}}>
										{client.phone}
									</Typography>
								</Box>
							)
						}
					/>
					<SelectLabel onClose={handleCloseLabel} selectedValue={label} open={openLabel} />
					<CardContent>
						<Grid container item xs={12} justify="center">
							<Grid item xs={12}>
								<Box my={1}>
									<Divider light />
								</Box>
							</Grid>
						</Grid>
						{contacts &&
							contacts.map((contact, i) => (
								<Grid container item xs={12} justify="center" key={i}>
									<Grid item xs={4}>
										<Box display="flex" alignItems="center" style={{marginBottom: "3px"}}>
											<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
												account_circle
											</Icon>

											<Typography variant="body2" color="textSecondary">
												Contact {i + 1}
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={8}>
										<Typography variant="body2" color="textSecondary" style={{fontWeight: "700"}}>
											{contact.name}
										</Typography>
									</Grid>

									<Grid item xs={4}>
										<Box display="flex" alignItems="center">
											<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
												phone
											</Icon>
											<Typography variant="body2" color="textSecondary">
												Phone:
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2" color="textSecondary">
											{contact.phone}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Box display="flex" justifyContent="flex-end">
											<WhatsAppIcon
												className={classes.icon}
												onClick={(e) => handleClickAction("Whatsapp", contact)}
											/>
											<Icon
												className={classes.icon}
												onClick={(e) => handleClickAction("Call", contact)}
											>
												phone
											</Icon>
											<Icon
												className={classes.icon}
												onClick={(e) => handleClickAction("Email", contact)}
											>
												email
											</Icon>
										</Box>
									</Grid>
									<Grid item xs={4}>
										<Box display="flex" align="center">
											<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
												email
											</Icon>
											<Typography variant="body2" color="textSecondary">
												Email:
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={8}>
										<Typography variant="body2" color="textSecondary">
											{contact.email}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Box my={1}>
											<Divider light />
										</Box>
									</Grid>
								</Grid>
							))}
						<Grid container item xs={12}>
							<Grid item xs={3}>
								<Typography
									className={classes.control}
									variant="subtitle1"
									style={{fontWeight: "bold"}}
								>
									Actions
								</Typography>
							</Grid>
							<Grid item xs={5} align="left">
								{loading && <CircularProgress />}
							</Grid>
							<Grid item xs={4} align="right">
								<Box display="flex" alignItems="center" justifyContent="flex-end">
									<Tooltip title="Add To Dos" aria-label="add">
										<Icon className={classes.icon} onClick={() => handleClickToDo()}>
											flaky
										</Icon>
									</Tooltip>
									<Icon className={classes.icon} onClick={() => handleClickAction("Add")}>
										add
									</Icon>
									<Icon className={classes.icon} onClick={() => handleClickAction("Payment")}>
										credit_card
									</Icon>
									<Icon className={classes.icon} onClick={() => handleClickAction("Google Doc")}>
										add_to_drive
									</Icon>
									<Icon className={classes.icon} onClick={() => handleClickAction("Signed document")}>
										picture_as_pdf
									</Icon>
									<Icon className={classes.icon} onClick={() => handleClickAction("Document")}>
										description
									</Icon>
									<Icon className={classes.icon} onClick={() => handleClickAction("Appointment")}>
										supervisor_account
									</Icon>
								</Box>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							{client && <LabelsList parcel={client} handleDeleteLabel={handleDeleteLabel} />}
						</Grid>
						<Grid item xs={12}>
							<Box my={2}>
								<Grid container spacing={1}>
									{actions.map((action, i) => (
										<ActionFlexbox
											action={action}
											handleClickRemark={handleClickRemark}
											handleClickDelete={handleClickDelete}
											key={i}
										/>
									))}
								</Grid>
							</Box>
						</Grid>
					</CardContent>
				</Card>
				<FormDialog
					open={open}
					setOpen={setOpen}
					onSave={saveRemark}
					title="Add Remark"
					text="Add remark into action"
					label="remark"
					initialValue={action && action.remark}
				/>
				<Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
					<Alert onClose={handleCloseSnackBar} severity="warning">
						Action Deleted
						<IconButton
							size="small"
							onClick={() => {
								handleCloseSnackBar("undo");
							}}
						>
							Undo
						</IconButton>
					</Alert>
				</Snackbar>
			</Box>
			<FullScreenDialog
				open={openUpload}
				handleClose={handleClose}
				title="Upload file"
				component={<UploadDocument handleFile={handleFile} action={action} parcel={client} />}
			/>
			<FullScreenDialog
				open={openEdit}
				handleClose={handleCloseEdit}
				title="Edit client"
				component={<ClientCreate client={client} handleClose={handleCloseEdit} />}
			/>
			<FullScreenDialog
				open={openTemplate}
				handleClose={(e) => handleCloseTemplate()}
				title="Google Doc Template"
				component={<ConnectGoogle client={client} contacts={contacts} handleClose={handleCloseTemplate} />}
			/>
				<FullScreenDialog
				open={openTodo}
				handleClose={handleCloseTodo}
				title="Add To-do"
				component={
					<AddTodo handleClose={handleCloseTodo} data={`${client && client.name}`} />
				}
			/>
		</Grid>
	);
}
