// @ts-nocheck
//1.
import React, { useState } from "react";
import { useRef } from "react";
import FormDialog from "../components/form-dialog";

//2.
export const InputContext = React.createContext();

//3.
export const InputProvider = ({ children }) => {
  const [input, setInput] = useState();
  const [state, setState] = useState({ title: "", text: "", label: "" });
  const [open, setOpen] = useState(false);
  const [initialValue, setInitialValue] = useState();

  const handleSave = (value) => {
    setInput(value);
    setInitialValue("");
    awaitingPromiseRef.current.resolve(value);
  };

  const awaitingPromiseRef = useRef();

  const openDialog = async (title, text, label, startValue) => {
    setState({
      title: title,
      text: text,
      label: label,
    });
    setInitialValue(startValue);
    setOpen(true);

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  return (
    <InputContext.Provider
      value={{ input, setState, setInitialValue, setOpen, openDialog }}
    >
      {children}
      <FormDialog
        open={open}
        setOpen={setOpen}
        title={state.title}
        text={state.text}
        label={state.label}
        onSave={handleSave}
        initialValue={initialValue}
      />
    </InputContext.Provider>
  );
};
