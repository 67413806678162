import { Box } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography, Grid } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  control: {
    marginRight: "4px",
    color: blue[500],
  },
}));

export default function ActionFlexbox({
  action,
  handleClickRemark,
  handleClickDelete,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container wrap="nowrap" item xs={4} md={4} direction="column">
        <Grid item zeroMinWidth>
          <Typography
            noWrap
            className={classes.control}
            variant="subtitle1"
            style={{ fontWeight: "bold" }}
          >
            {action.userName}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.control} variant="subtitle2">
            {moment(action.created).format("DD-MMM LT")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Typography
          className={classes.control}
          variant="subtitle1"
          style={{ fontWeight: "bold" }}
        >
          {action.action}
        </Typography>
        <Typography className={classes.control} variant="subtitle1">
          {action.remark}
        </Typography>
        {action.files &&
          action.files.map((file, i) => (
            <Typography className={classes.control} variant="subtitle1" key={i}>
              <a href={file.downloadURL} target="blank">
                {file.name}
              </a>
            </Typography>
          ))}
      </Grid>
      <Grid item xs={1} align="right">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={() => handleClickRemark(action)} size="small">
            <Icon fontSize="small" style={{ color: blue[700] }}>
              edit
            </Icon>
          </IconButton>
          <IconButton onClick={() => handleClickDelete(action)} size="small">
            <Icon fontSize="small" style={{ color: blue[700] }}>
              clear
            </Icon>
          </IconButton>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
