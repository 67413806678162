import { notification } from "./notification";
import { firestore } from "../firebase/firebase-utils";

export async function addAction(collection, id, action, remark, user, files, others={}) {
  try {
    const collecionRef = firestore
      .collection(collection)
      .doc(id)
      .collection("actions");
    const actionData = {
      ...others,
      files: files ? files : null,
      created: new Date(),
      user: user.email,
      action: action,
      remark: remark,
    };

    await collecionRef.add(actionData);
    console.log("Action " + action + " added into Firestore");

    await firestore
      .collection(collection)
      .doc(id)
      .update({ lastAction: actionData });
    console.log("Update lastAction");
    console.log(actionData);
  } catch (error) {
    notification("Opps", error.message, "danger");
    console.log(error);
  }
}
