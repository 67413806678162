import { firestore } from "firebase/firebase-utils";

export async function createProject(projectName) {
  try {
    const response = await firestore
      .collection("projects")
      .add({ name: projectName });
    return { id: response.id, name: projectName };
  } catch (error) {
    console.log(error);
  }
}
