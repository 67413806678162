import {Box, Button, Grid, FormControl, Select} from "@material-ui/core";
import {OutTable, ExcelRenderer} from "react-excel-renderer";
import UploadFile from "components/upload-file.component";
import React, {useState} from "react";
import {notification} from "functions/notification";
import InputLabel from "@material-ui/core/InputLabel";
import {useEffect} from "react";
import { convertUndefined } from "functions/convertUndefined";
import { saveClients } from "functions/saveClients";
import { capitaliseName } from "functions/capitaliseName";

const INITIAL_VALUES = {
	name: "",
	address: "",
	country: "",
	email: "",
	phone: "",
	contactName: "",
	designation: "",
	contactNumber: "",
	contactEmail: "",
	remark: "",
};

const NAMES = [
	"name",
	"address",
	"country",
	"email",
	"phone",
	"contactName",
	"designation",
	"contactNumber",
	"contactEmail",
	"remark",
];

export default function SeedClients() {
	const [rows, setRows] = useState();
	const [columns, setColumns] = useState();
	const [state, setState] = useState(INITIAL_VALUES);
	const [names, setNames] = useState(NAMES);

	useEffect(() => {
		console.log(names);
	}, []);

	const handleClickUpload = (files) => {
		console.log(files);
		fileHandler(files);
	};

	const fileHandler = (files) => {
		let fileObj = files[0];

		//just pass the fileObj as parameter
		ExcelRenderer(fileObj, (err, resp) => {
			if (err) {
				console.log(err);
			} else {
				setRows(resp.rows);
				setColumns(resp.cols);
			}
		});
	};

	const handleSeed = async () => {
		if (!rows) {
			notification("Opps", "Please select a valid Excel file first", "warning");
			return;
		}
		const clients = [];
		for (let i = 1; i < rows.length; i++) {
			let client = {
				name: rows[i][parseInt(state["name"])],
				address: rows[i][parseInt(state["address"])],
				country: rows[i][parseInt(state["country"])],
				email: rows[i][parseInt(state["email"])],
				phone: rows[i][parseInt(state["phone"])],
				contacts: [
					{
						name: capitaliseName(rows[i][parseInt(state["contactName"])]),
						designation: rows[i][parseInt(state["designation"])],
						email: rows[i][parseInt(state["contactEmail"])],
						phone: rows[i][parseInt(state["contactNumber"])],
						remark: rows[i][parseInt(state["remarks"])],
					},
				],
			};
            client = convertUndefined(client);
			console.log(client);
			clients.push(client);
		}
		console.log(clients)
        await saveClients(clients);
	};

	const handleChange = (e) => {
		const {value, name} = e.target;
		setState({...state, [name]: value});
	};

	return (
		<Box m={1}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<UploadFile handleClickUpload={handleClickUpload} />
					<Box mt={1}>
						<Button size="large" variant="contained" color="secondary" onClick={handleSeed}>
							Seed
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12}>
					{names &&
						names.map((key, i) => (
							<Grid item xs={12} md={7} key={i}>
								<FormControl variant="outlined" fullWidth style={{margin: "10px"}}>
									<InputLabel>{key}</InputLabel>
									<Select
										native
										value={state[key]}
										onChange={handleChange}
										inputProps={{
											name: key,
										}}
									>
										{rows &&
											rows[0].map((header, i) => (
												<React.Fragment key={i}>
													{i === 0 && <option value={""} />}
													<option value={i}>{header}</option>
												</React.Fragment>
											))}
									</Select>
								</FormControl>
							</Grid>
						))}
				</Grid>
				<Grid item xs={12}>
					<OutTable
						data={rows ? rows : []}
						columns={columns ? columns : []}
						tableClassName="ExcelTable2007"
						tableHeaderRowClass="heading"
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
