import {getFirestoreData} from "functions/getFirestoreData";

export async function replaceOwners() {
	try {
		const parcels = await getFirestoreData("parcels");
		const owners = await getFirestoreData("owners");

		parcels.forEach((parcel) => {
			parcel.owners.forEach(async (owner) => {
				const fOwner = owners.find((dbOwner) => dbOwner.id === owner.id);
				if (!fOwner) {
					console.log(owner.name + " id:" + owner.id + " cannot be found in DB");
                    console.log(parcel)
				}
			});
		});
	} catch (error) {
		console.log(error);
	}
}
