import {Grid, Icon, IconButton, makeStyles, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Box, Typography, Paper, Card, CardContent} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import {useState} from "react";
import {useContext} from "react";
import {ListContext} from "../context/list-context";
import {AuthContext} from "../context/auth-context";
import ManualOwner from "./manual-owner";
import FullScreenDialog from "../components/full-dialog";
import {useEffect} from "react";
import {saveClient} from "../functions/saveClient";
import {loadClients} from "../functions/loadClients";
import React from "react";
import {InputContext} from "context/input-context";
import {deleteClient} from "functions/deleteClient";

const useStyles = makeStyles((theme) => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(1),
	},
}));

const INITIAL_VALUE = {
	name: "",
	address: "",
	country: "",
	email: "",
	phone: "",
	id: "",
	labels: [],
};

const INITIAL_CONTACT = {
	name: "",
	designation: "",
	phone: "",
	email: "",
	remark: "",
};

export default function ClientCreate({client, handleClose}) {
	const classes = useStyles();

	const [state, setState] = useState(INITIAL_VALUE);
	const [contacts, setContacts] = useState([INITIAL_CONTACT]);
	const {user} = useContext(AuthContext);
	const [open, setOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	const {openGlobalList} = useContext(ListContext);
	const {openDialog} = useContext(InputContext);

	useEffect(() => {
		if (client) {
			setEdit(true);
			let {name, address, country, email, phone, contacts, id, labels} = client;
			if (!labels) labels = [];
			setState({name, address, country, email, phone, id, labels});
			setContacts(contacts);
		}
		// eslint-disable-next-line
	}, []);

	const handleChange = (e) => {
		const {name, value} = e.target;
		setState({...state, [name]: value});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const client = {...state, contacts: contacts};
		await saveClient(client, user, edit);
		setState(INITIAL_VALUE);
		setContacts([INITIAL_CONTACT]);
		setEdit(false);
		if (handleClose) {
			handleClose();
		}
	};

	const handleChangeContact = async (e, i) => {
		const {name, value} = e.target;
		let newContacts = [...contacts];
		newContacts[i] = {...newContacts[i], [name]: value};
		setContacts(newContacts);
	};

	const handleClickAdd = (i) => {
		const newContacts = [...contacts];
		newContacts.splice(i + 1, 0, INITIAL_CONTACT);
		setContacts(newContacts);
	};

	const handleClickRemove = (i) => {
		const newContacts = [...contacts];
		newContacts.splice(i, 1);
		setContacts(newContacts);
	};

	const handleClickEdit = async () => {
		const clients = await loadClients();
		const selectedClient = await openGlobalList(
			clients,
			"Select client",
			"name",
			"perm_contact_calendar",
			false,
			true
		);
		if (selectedClient) {
			const {contacts, ...others} = selectedClient;
			setState(others);
			setContacts(contacts);
			setEdit(true);
		}
	};

	const handleDelete = async () => {
		const response = await openDialog("Confirm delete?", "Type 'yes' to confirm delete", "Type 'yes'", "");
		if (response && response.toLowerCase() === "yes") {
			deleteClient(client);
			setState(INITIAL_VALUE);
			setContacts([INITIAL_CONTACT]);
		}
	};

	return (
		<Box m={1}>
			<Grid container spacing={1} justify="center">
				<Grid item xs={12} md={7}>
					<Grid item container xs={12}>
						<Grid item xs={12} align="center">
							<Box m={1} alignItems="center">
								<Typography variant="h5">Create New Client</Typography>
								<Box m={1} display="flex" alignItems="center" justifyContent="center">
									<IconButton onClick={handleClickEdit}>
										<Icon style={{color: "#5e5e5e"}}>edit</Icon>
										<Typography variant="subtitle1" color="textSecondary">
											Load existing client
										</Typography>
									</IconButton>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Paper className={classes.paper}>
						<form onSubmit={handleSubmit}>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Client Name"
											name="name"
											value={state.name}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Address"
											name="address"
											value={state.address}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Country"
											name="country"
											value={state.country}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Email"
											name="email"
											value={state.email}
											onChange={handleChange}
											required
											type="email"
										/>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Phone"
											name="phone"
											value={state.phone}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>

								<Grid item xs={12}>
									{contacts.map((contact, i) => (
										<Box m={1} key={i}>
											<Card>
												<Box display="flex" alignItems="center" m={1}>
													<Typography variant="h5" style={{paddingRight: "15px"}}>
														Contact {i + 1}
													</Typography>
													<Button
														size="small"
														color="primary"
														variant="contained"
														onClick={() => handleClickAdd(i)}
													>
														<Icon>add</Icon>
													</Button>
													<Button
														disabled={!i}
														size="small"
														color="primary"
														variant="contained"
														style={{marginLeft: "15px"}}
														onClick={() => handleClickRemove(i)}
													>
														<Icon>remove</Icon>
													</Button>
												</Box>
												<CardContent>
													<Grid item xs={12}>
														<Box m={1}>
															<TextField
																variant="outlined"
																fullWidth
																label="Name"
																name="name"
																value={contact.name}
																onChange={(e) => handleChangeContact(e, i)}
																required
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box m={1}>
															<TextField
																variant="outlined"
																fullWidth
																label="Designation"
																name="designation"
																value={contact.designation}
																onChange={(e) => handleChangeContact(e, i)}
																required
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box m={1}>
															<TextField
																variant="outlined"
																fullWidth
																label="Phone"
																name="phone"
																value={contact.phone}
																onChange={(e) => handleChangeContact(e, i)}
																required
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box m={1}>
															<TextField
																variant="outlined"
																fullWidth
																label="Email"
																name="email"
																value={contact.email}
																onChange={(e) => handleChangeContact(e, i)}
																required
															/>
														</Box>
													</Grid>

													<Grid item xs={12}>
														<Box m={1}>
															<TextField
																variant="outlined"
																fullWidth
																label="Remark"
																name="remark"
																value={contact.remark}
																onChange={(e) => handleChangeContact(e, i)}
															/>
														</Box>
													</Grid>
												</CardContent>
											</Card>
										</Box>
									))}
								</Grid>

								<Grid item xs={12}>
									<Box m={1}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											size="large"
											startIcon={<SaveIcon />}
										>
											{edit ? "Update" : "Save"}
										</Button>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										<IconButton size="small" color="secondary" onClick={handleDelete}>
											Delete
										</IconButton>
									</Box>
								</Grid>
							</Grid>
						</form>
					</Paper>
				</Grid>
			</Grid>
			<FullScreenDialog
				open={open}
				handleClose={() => setOpen(false)}
				title="Add Owner"
				component={<ManualOwner />}
			/>
		</Box>
	);
}
