import {Grid, Icon, makeStyles, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Box, Typography, Paper} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import {useState} from "react";
import {useContext} from "react";
import {AuthContext} from "../context/auth-context";
import {saveOwner} from "../functions/saveOwner";
import React from "react";
import {ListContext} from "context/list-context";
import {loadOwners} from "functions/loadOwners";
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(1),
	},
}));

const INITIAL_VALUE = {
	name: "",
	phone: "",
	email: "",
	icNumber: "",
	nationality: "",
	address: "",
};

export default function ManualOwner() {
	const classes = useStyles();

	const [state, setState] = useState(INITIAL_VALUE);
	const {user} = useContext(AuthContext);
	const {openGlobalList} = useContext(ListContext);
	const [edit, setEdit] = useState(false);

	const handleChange = (e) => {
		const {name, value} = e.target;
		setState({...state, [name]: value});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await saveOwner(state, user);
		setState(INITIAL_VALUE);
	};

	const handleClickEdit = async () => {
		const owners = await loadOwners();
		const selectedOwner = await openGlobalList(owners, "Select owner", "name", "account_circle", false, true);
		if (selectedOwner) {
			setState(selectedOwner);
			setEdit(true);
		}
	};

	return (
		<Box m={1}>
			<Grid container spacing={1} justify="center">
				<Grid item xs={12} md={7}>
					<Grid item container xs={12}>
						<Grid item xs={12} align="center">
							<Box m={1}>
								<Typography variant="h5">Create New Owner</Typography>
								<Box m={1} display="flex" alignItems="center" justifyContent="center">
									<IconButton onClick={handleClickEdit}>
										<Icon style={{color: "#294A92"}} >
											edit
										</Icon>
										<Typography variant="subtitle1">Load users</Typography>
									</IconButton>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Paper className={classes.paper}>
						<form onSubmit={handleSubmit}>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Name"
											name="name"
											value={state && state.name}
											onChange={handleChange}
											required
											inputProps={{
												autoComplete: "new-password",
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Phone"
											name="phone"
											value={state && state.phone}
											onChange={handleChange}
											required
											inputProps={{
												autoComplete: "new-password",
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Email"
											name="email"
											value={state && state.email}
											onChange={handleChange}
										/>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="IC Number"
											name="icNumber"
											value={state && state.icNumber}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Nationality"
											name="nationality"
											value={state && state.nationality}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										<TextField
											variant="outlined"
											fullWidth
											label="Postal Address"
											name="address"
											value={state && state.address}
											onChange={handleChange}
											required
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										<Button type="submit" variant="contained" color="primary" size="large" startIcon={<SaveIcon />}>
											{edit ? "Update" : "Save"}
										</Button>
									</Box>
								</Grid>
							</Grid>
						</form>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
}
