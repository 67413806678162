import { Box, makeStyles } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Card, Divider } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { whatsapp } from "../../functions/whatsapp";
import { callNumber } from "../../functions/callNumber";
import { email } from "../../functions/email";
import { addAction } from "../../functions/addAction";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { loadActions } from "../../functions/loadActions";
import { UsersContext } from "../../context/users-context";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { saveUndoAction } from "../../functions/saveUndoAction";
import { deleteAction } from "../../functions/deleteAction";
import FormDialog from "../form-dialog";
import { addRemark } from "../../functions/addRemark";
import { removeLabel } from "../../functions/removeLabel";
import SelectLabel from "../label-select.component";
import { saveLabel } from "../../functions/saveLabel";
import { loadParcel } from "../../functions/loadParcel";
import ActionFlexbox from "../action-flexbox/action-flexbox.component";
import FullScreenDialog from "../full-dialog";
import UploadDocument from "../UploadDocument";
import { arrayIsEmpty } from "../../functions/arrayIsEmpty";
import ParcelCreate from "../../pages/parcel-create";
import ConnectGoogle from "../../pages/connect-google";
import LabelsList from "components/labels-list.component";
import { Tooltip } from "@material-ui/core";
import { AddTodo } from "components/AddTodo";
import Payments from "components/Payments/Payments";
import { ConfirmationContext } from "context/ConfirmationContext";
import DocumentsCheckList from "components/DocumentsCheckList";
import { StoreContext } from "context/store-context";
import { InputContext } from "context/input-context";
import { addDocumentToProject } from "functions/addDocumentToProject";
import { loadProject } from "functions/loadProject";
import { deleteParcel } from "functions/deleteParcel";
import { deleteDocumentFromProject } from "functions/deleteDocumentFromProject";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "600px",
        },
    },
    avatar: {
        backgroundColor: red[500],
    },
    icon: {
        cursor: "pointer",
        marginLeft: "3px",
        color: green[500],
    },
    control: {
        marginRight: "4px",
        color: blue[500],
    },
    appointment: {
        marginRight: "4px",
        color: red[800],
    },
    label: {
        paddingRight: "4px",
        paddingLeft: "4px",
        borderRadius: "6px",
        fontSize: "11px",
    },
}));

const setupDocuments = (project, documents) => {
    console.log(project);
    if (project.documents) {
        const docs = project.documents.map((name) => {
            const found = documents.find((doc) => doc.name === name);
            if (found) {
                const document = { name: name, checked: found.checked };
                return document;
            } else {
                const document = { name: name, checked: false };
                return document;
            }
        });
        return docs;
    } else {
        return [];
    }
};

export default function ParcelCard({ parcel_, handleCloseCard }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [actions, setActions] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [action, setAction] = useState();
    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState();
    const [openLabel, setOpenLabel] = useState(false);
    const { user } = useContext(AuthContext);
    const { users, setUsers } = useContext(UsersContext);
    const [parcel, setParcel] = useState(parcel_);
    const [openUpload, setOpenUpload] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [owners, setOwners] = useState();
    const [openTemplate, setOpenTemplate] = useState(false);
    const [openTodo, setOpenTodo] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const { openConfirmation } = useContext(ConfirmationContext);
    const { store, setStore } = useContext(StoreContext);
    const [project, setProject] = useState();
    const { openDialog } = useContext(InputContext);
    const [documents, setDocuments] = useState(parcel.documents ? parcel.documents : []);
    const [editDocuments, setEditDocuments] = useState(false);

    useEffect(() => {
        console.log("Parcel:");
        console.log(parcel);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            console.log(store);
            if (store.project) {
                setProject(store.project);
                const docs = setupDocuments(store.project, documents);
                setDocuments(docs);
                console.log(docs);
            } else {
                console.log(parcel_);
                const project = await loadProject(parcel_.projectId);
                setProject(project);
                const docs = setupDocuments(project, documents);
                setDocuments(docs);
                console.log(docs);
            }
        };
        fetchData();
    }, [store]);

    useEffect(() => {
        console.log(documents);
    }, [documents]);

    useEffect(() => {
        const unsubscribe = loadActions("parcels", parcel, setActions, users, setUsers, setLoading, user);
        return unsubscribe;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const unsubscribe = loadParcel(parcel_.id, setParcel);

        return unsubscribe;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOwners(parcel.owners);
    }, [parcel]);

    const handleClickLabel = () => {
        setOpenLabel(true);
    };

    const handleOpenPayment = () => {
        setOpenPayment(true);
    };

    const handleClickAction = async (action, owner) => {
        switch (action) {
            case "Whatsapp":
                whatsapp(owner.phone, owner.name);
                break;
            case "Call":
                callNumber(owner.phone);
                break;
            case "Email":
                email(owner.email);
                break;
            case "Add":
                break;
            case "Google Doc":
                handleOpenTemplate();
                return;
            case "Payment":
                handleOpenPayment();
                return;
            case "Signed document":
            case "Document":
                setAction(action);
                setOpenUpload(true);
                return;
            case "Appointment":
            default:
                console.log(action);
        }
        if (owner) {
            action = action + " " + owner.name;
        }
        await addAction("parcels", parcel.id, action, "", user);
    };

    const handleClickDelete = async (action) => {
        console.log(action);
        if (action.action === "Transaction") {
            const response = await openConfirmation("Confirm delete?", "Are you sure you want to delete this action?");
            if (response) {
                await deleteAction("parcels", action, action.parcelId);
                return;
            }
        }
        // setOpenSnackBar(true);
        setAction(action);
        console.log(action);
        const response = await openConfirmation("Confirm delete?", "Are you sure you want to delete this action?");
        if (response) {
            await deleteAction("parcels", action, action.parcelId);
        }
    };

    const handleClickRemark = async (action) => {
        setAction(action);
        setOpen(true);
    };

    const handleCloseSnackBar = async (undo) => {
        if (undo === "undo") {
            console.log("undo delete");
            await saveUndoAction("parcels", action, parcel);
            setOpenSnackBar(false);
        } else {
            setOpenSnackBar(false);
        }
    };

    const saveRemark = async (remark) => {
        await addRemark("parcels", action, parcel, remark);
    };

    const handleDeleteLabel = async (label) => {
        await removeLabel("parcels", label, parcel);
    };

    const handleCloseLabel = async (value) => {
        if (!value) {
            setOpenLabel(false);
            return;
        }
        console.log(value);
        setOpenLabel(false);
        setLabel(value); // value = {label: "LabelName", color: "Color", fontColor: "fontColor"}
        await saveLabel("parcels", value, parcel, user);
    };

    const handleClose = () => {
        setOpenUpload(false);
    };

    const handleFile = async (files, noteId) => {
        setOpenUpload(false);
        console.log(files);
        if (!arrayIsEmpty(files)) {
            await addAction("parcels", parcel.id, action, "", user, files);
            console.log("Files saved");
            console.log(files);
        }
    };

    const handleClickEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleOpenTemplate = () => {
        setOpenTemplate(true);
    };

    const handleCloseTemplate = async (file) => {
        setOpenTemplate(false);
        if (!file) {
            return;
        }
        await addAction("parcels", parcel.id, "Google Doc generated", "", user, [file]);
    };

    const handleClickToDo = async () => {
        setOpenTodo(true);
    };

    const handleCloseTodo = () => {
        setOpenTodo(false);
    };

    const handleClosePayment = async (transaction, files) => {
        if (!transaction) {
            setOpenPayment(false);
            return;
        }
        setLoading(true);
        await addAction(
            "parcels",
            parcel.id,
            "Transaction",
            `${transaction.type} RM${transaction.amount}`,
            user,
            files
        );
        setOpenPayment(false);
        setLoading(false);
    };

    const handleAddDocument = async () => {
        const response = await openDialog("Add document", "Please enter the document name", "document name", "");
        if (response) {
            await addDocumentToProject(response, project);
            const newDocuments = [...documents, { name: response, checked: false }];
            setDocuments(newDocuments);
            setStore({ ...store, project: { ...project, documents: newDocuments.map((doc) => doc.name) } });
        }
    };

    const handleRemoveDocument = async (doc) => {
        const response = await openConfirmation(
            "Delete document?",
            "Are you sure you want to remove all documents of type " + doc.name + " from current project"
        );
        if (response) {
            await deleteDocumentFromProject(doc, project);
            const newDocuments = [...documents];
            const index = newDocuments.findIndex((a) => a.name === doc.name);
            newDocuments.splice(index, 1);
            setDocuments(newDocuments);
            setStore({ ...store, project: { ...project, documents: newDocuments.map((doc) => doc.name) } });
            setEditDocuments(false);
        }
    };

    const handleClickDeleteParcel = async () => {
        const response = await openConfirmation("Confirm delete?", "Are you sure you want to delete?");
        if (response) {
            await deleteParcel(parcel_.id);
            handleCloseCard();
        }
    };

    return (
        <Grid container item xs={12} justify="center">
            <Box m={1}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                {parcel && parcel.project.name}
                            </Avatar>
                        }
                        action={
                            <Box display="flex">
                                <IconButton aria-label="settings" onClick={handleClickDeleteParcel}>
                                    <Icon>delete</Icon>
                                </IconButton>
                                <IconButton aria-label="settings" onClick={handleClickEdit}>
                                    <Icon>edit</Icon>
                                </IconButton>
                                <IconButton aria-label="settings" onClick={handleClickLabel}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        }
                        title={parcel && parcel.project.name}
                        subheader={
                            parcel && (
                                <Box display="flex">
                                    <Typography style={{ marginRight: "10px" }}>{parcel.parcelNumber}</Typography>
                                </Box>
                            )
                        }
                    />
                    <SelectLabel onClose={handleCloseLabel} selectedValue={label} open={openLabel} />
                    <CardContent>
                        <Grid container item xs={12} justify="center">
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    SPA Price
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {new Intl.NumberFormat("en-GB", {
                                        style: "currency",
                                        currency: "MYR",
                                    }).format(parcel.spaPrice)}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    Net Price
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {new Intl.NumberFormat("en-GB", {
                                        style: "currency",
                                        currency: "MYR",
                                    }).format(parcel.netPrice)}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    Parcel type
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {parcel.type}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    Size
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {parcel.size || ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    Parcel status
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {parcel.parcelStatus}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    Package
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {parcel.package.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body2" color="textSecondary">
                                    Theme
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body2" color="textSecondary">
                                    {parcel.theme && parcel.theme.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={1}>
                                    <Divider light />
                                </Box>
                            </Grid>
                        </Grid>
                        {owners &&
                            owners.map((owner, i) => (
                                <Grid container item xs={12} justify="center" key={i}>
                                    <Grid item xs={4}>
                                        <Box display="flex" alignItems="center" style={{ marginBottom: "3px" }}>
                                            <Icon style={{ color: blue[500], marginRight: "4px" }} fontSize="small">
                                                account_circle
                                            </Icon>

                                            <Typography variant="body2" color="textSecondary">
                                                Owner {i + 1}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2" color="textSecondary" style={{ fontWeight: "700" }}>
                                            {owner.name}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Box display="flex" alignItems="center">
                                            <Icon style={{ color: blue[500], marginRight: "4px" }} fontSize="small">
                                                phone
                                            </Icon>
                                            <Typography variant="body2" color="textSecondary">
                                                Phone:
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="body2" color="textSecondary">
                                            {owner.phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <WhatsAppIcon
                                                className={classes.icon}
                                                onClick={(e) => handleClickAction("Whatsapp", owner)}
                                            />
                                            <Icon
                                                className={classes.icon}
                                                onClick={(e) => handleClickAction("Call", owner)}
                                            >
                                                phone
                                            </Icon>
                                            <Icon
                                                className={classes.icon}
                                                onClick={(e) => handleClickAction("Email", owner)}
                                            >
                                                email
                                            </Icon>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box display="flex" align="center">
                                            <Icon style={{ color: blue[500], marginRight: "4px" }} fontSize="small">
                                                email
                                            </Icon>
                                            <Typography variant="body2" color="textSecondary">
                                                Email:
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2" color="textSecondary">
                                            {owner.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box my={1}>
                                            <Divider light />
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary">
                                    Documents checklist
                                </Typography>
                            </Grid>
                            <Grid item xs={6} align="right">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setEditDocuments(!editDocuments);
                                    }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        Edit document type
                                    </Typography>
                                    <Icon fontSize="small" color="inherit">
                                        edit
                                    </Icon>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleAddDocument}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        Add new document type
                                    </Typography>
                                    <Icon fontSize="small" color="inherit">
                                        add
                                    </Icon>
                                </Box>
                            </Grid>
                            <Grid item={12}>
                                <DocumentsCheckList
                                    documents_={documents}
                                    collection="parcels"
                                    docId={parcel.id}
                                    editDocuments={editDocuments}
                                    handleRemoveDocument={handleRemoveDocument}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={1}>
                                    <Divider light />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <Typography
                                    className={classes.control}
                                    variant="subtitle1"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Actions
                                </Typography>
                            </Grid>
                            <Grid item xs={5} align="left">
                                {loading && <CircularProgress />}
                            </Grid>
                            <Grid item xs={4} align="right">
                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                    <Tooltip title="Add To Dos" aria-label="add">
                                        <Icon className={classes.icon} onClick={() => handleClickToDo()}>
                                            flaky
                                        </Icon>
                                    </Tooltip>
                                    <Icon className={classes.icon} onClick={() => handleClickAction("Add")}>
                                        add
                                    </Icon>
                                    <Tooltip title="Add Payments" aria-label="parments">
                                        <Icon className={classes.icon} onClick={() => handleClickAction("Payment")}>
                                            credit_card
                                        </Icon>
                                    </Tooltip>
                                    <Icon className={classes.icon} onClick={() => handleClickAction("Google Doc")}>
                                        add_to_drive
                                    </Icon>
                                    <Icon className={classes.icon} onClick={() => handleClickAction("Signed document")}>
                                        picture_as_pdf
                                    </Icon>
                                    <Icon className={classes.icon} onClick={() => handleClickAction("Document")}>
                                        description
                                    </Icon>
                                    <Icon className={classes.icon} onClick={() => handleClickAction("Appointment")}>
                                        supervisor_account
                                    </Icon>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {parcel && <LabelsList parcel={parcel} handleDeleteLabel={handleDeleteLabel} />}
                        </Grid>
                        <Grid item xs={12}>
                            <Box my={2}>
                                <Grid container spacing={1}>
                                    {actions.map((action, i) => (
                                        <ActionFlexbox
                                            action={action}
                                            handleClickRemark={handleClickRemark}
                                            handleClickDelete={handleClickDelete}
                                            key={i}
                                        />
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                    </CardContent>
                </Card>
                <FormDialog
                    open={open}
                    setOpen={setOpen}
                    onSave={saveRemark}
                    title="Add Remark"
                    text="Add remark into action"
                    label="remark"
                    initialValue={action && action.remark}
                />
                <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                    <Alert onClose={handleCloseSnackBar} severity="warning">
                        Action Deleted
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleCloseSnackBar("undo");
                            }}
                        >
                            Undo
                        </IconButton>
                    </Alert>
                </Snackbar>
            </Box>
            <FullScreenDialog
                open={openUpload}
                handleClose={handleClose}
                title="Upload file"
                component={<UploadDocument handleFile={handleFile} action={action} parcel={parcel} />}
            />
            <FullScreenDialog
                open={openEdit}
                handleClose={handleCloseEdit}
                title="Edit Parcel"
                component={<ParcelCreate parcel={parcel} owners_={owners} handleClose={handleCloseEdit} />}
            />
            <FullScreenDialog
                open={openTemplate}
                handleClose={(e) => handleCloseTemplate()}
                title="Google Doc Template"
                component={<ConnectGoogle parcel={parcel} owners={owners} handleClose={handleCloseTemplate} />}
            />
            <FullScreenDialog
                open={openTodo}
                handleClose={handleCloseTodo}
                title="Add To-do"
                component={
                    <AddTodo handleClose={handleCloseTodo} data={`${parcel.project.name} ${parcel.parcelNumber}`} />
                }
            />
            <FullScreenDialog
                open={openPayment}
                handleClose={() => handleClosePayment()}
                title="Add Payments"
                component={
                    <Payments
                        handleClose={handleClosePayment}
                        collection="parcels"
                        doc={parcel}
                        title={`${parcel.project.name} ${parcel.parcelNumber}`}
                    />
                }
            />
        </Grid>
    );
}
