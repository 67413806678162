import {firestore} from "firebase/firebase-utils";
import {mapSnapshot} from "functions/mapSnapshot";

export async function addProjectIds() {
	try {
		const snapshot = await firestore.collection("parcels").get();
		const parcels = mapSnapshot(snapshot);

		// find scarletz
		const scarletz = parcels.filter((parcel) => parcel.project.id === "GUAP4MwNDsIrmjBQyuqd");
		scarletz.forEach(async (unit) => {
			await firestore.collection("parcels").doc(unit.id).update({projectId: "GUAP4MwNDsIrmjBQyuqd"});
			console.log(unit.parcelNumber + " updated");
		});
	} catch (error) {
		console.log(error);
	}
}
