import {firestore} from "../firebase/firebase-utils";
import {arrayIsEmpty} from "./arrayIsEmpty";
import {convertDate} from "./convertDate";
import {getDisplayName} from "./getDisplayName";

export function loadActions(collection, document, setActions, users, setUsers, setLoading, user) {
	try {
		setLoading(true);
		const collectionRef = firestore.collection(collection).doc(document.id).collection("actions");
		return collectionRef.onSnapshot(async (snapShot) => {
			let actions = await Promise.all(
				snapShot.docs.map(async (doc) => {
					const email = doc.data().user;
					let userName = "";
					if (!arrayIsEmpty(users)) {
						const user = users.find((user) => user.email === email);
						if (!user) {
							userName = await getDisplayName(email);
							setUsers([...users, {displayName: userName, email: email}]);
						} else {
							userName = user.displayName;
						}
					} else {
						userName = await getDisplayName(email);
						setUsers([{displayName: userName, email: email}]);
					}
					
          let name = "";
					if (collection === "clients") name = "clientId";
					if (collection === "parcels") name = "parcelId";

					return {
						...doc.data(),
						[name]: document.id,
						id: doc.id,
						created: convertDate(doc.data().created),
						userName: userName,
					};
				})
			);
			// if (
			//   user.role !== "Super Admin" &&
			//   user.role !== "Admin" &&
			//   user.email !== lead.ownerEmail
			// ) {
			//   // Load only your own leads
			//   actions = actions.filter((action) => action.user === user.email);
			// }
			actions.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf());
			setLoading(false);
			setActions(actions);
		});
	} catch (error) {
		console.log(error);
		setLoading(true);
	}
}
