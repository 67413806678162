import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { AuthContext } from "context/auth-context";
import { addNote } from "functions/addNote";
import { getNotes } from "functions/getNotes";
import NotesList from "components/notes-list.component";
import UploadFile from "../components/upload-file.component";
import { uploadFile } from "functions/uploadFile";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
    margintBottom: theme.spacing(1),
  },
}));

export default function LearningPage() {
  const classes = useStyles();
  const [state, setState] = useState();
  const { user } = useContext(AuthContext);
  const [notes, setNotes] = useState([]);
  // eslint-disable-next-line
  const [note, setNote] = useState();
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState();

  useEffect(() => {
    const unsubscribe = getNotes(user, setNotes);

    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(notes);
  }, [notes]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleClickAdd = async (e) => {
    e.preventDefault();
    const note = {
      ...state,
      date: new Date(),
      userEmail: user.email,
      files: files,
    };
    await addNote(note, user);
  };

  const handleClickUpload = async (uploadedFiles) => {
    const folderRef = `notes/${user.email}/${uuidv4()}`
    const fileRef = folderRef + "/" + uploadedFiles[0].name;
    const downloadURL = await uploadFile(
      uploadedFiles[0],
      fileRef,
      setProgress
    );
    console.log(downloadURL);
    if (downloadURL) {
      setFiles([
        ...files,
        {
          name: uploadedFiles[0].name,
          downloadURL: downloadURL,
          owner: user.email,
          fileRef: fileRef,
          folderRef: folderRef,
        },
      ]);
    }
    setProgress(null);
  };

  return (
    <React.Fragment>
      <Box m={1}>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12} align="center">
            <Typography variant="h5">Learning Page</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper className={classes.control}>
              <form onSubmit={handleClickAdd}>
              <Grid item xs={12} align="left">
                <Typography variant="body1">Add new note</Typography>
                <TextField
                  className={classes.input}
                  id="url"
                  name="url"
                  label="URL"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  required
                />
                <TextField
                  className={classes.input}
                  id="title"
                  name="title"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  required
                />
                <TextField
                  className={classes.input}
                  id="description"
                  name="description"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  onChange={handleChange}
                  required
                />
                <TextField
                  className={classes.input}
                  id="label"
                  name="label"
                  label="Label"
                  variant="outlined"
                  fullWidth
                  multiline
                  onChange={handleChange}
                  required
                />
                {files &&
                  files.map((file, i) => (
                    <React.Fragment>
                      <Typography varint="h5">{file.name}</Typography>
                    </React.Fragment>
                  ))}
                <UploadFile
                  handleClickUpload={handleClickUpload}
                  progress={progress}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<AddIcon />}
                  className={classes.input}
                  type="submit"
                >
                  Add
                </Button>
              </Grid>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper className={classes.control}>
              <Grid item xs={12} align="left">
                <NotesList notes={notes} setNote={setNote} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
