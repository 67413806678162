import {firestore} from "firebase/firebase-utils";

export async function saveTodoDone(todo) {
	try {
		await firestore.collection("todos").doc(todo.id).update({completed: !todo.completed});
        console.log("todo updated!")
	} catch (error) {
		console.log(error);
	}
}
