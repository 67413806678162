import React, {useContext} from "react";
import {Grid, Typography, Box, makeStyles, LinearProgress} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {AuthContext} from "../context/auth-context";
import {useEffect} from "react";
import {useState} from "react";
import {loadParcels} from "../functions/loadParcels";
import ParcelFlexboxContainer from "../components/parcel-flexbox/parcel-flexbox.container";
import {loadProjects} from "functions/loadProjects";
import {ListContext} from "context/list-context";
import {IconButton} from "@material-ui/core";
import ExportToExcel from "components/ExportToExcel";
import {arrayIsEmpty} from "functions/arrayIsEmpty";
import {mapToExcel} from "functions/mapToExcel";
import {StoreContext} from "context/store-context";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	control: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
	},
	project: {
		borderStyle: "solid",
		borderRadius: "5px",
		borderWidth: "1px",
		padding: "3px",
		marginLeft: "4px",
		marginRight: "4px",
		cursor: "pointer",
	},
}));

let fieldToDisplay = {
	projectName: "Project Name",
	parcelNumber: "Parcel Number",
	spaPrice: "SPA Price",
	netPrice: "Net Price",
	labels: "Labels",
	name: "Name",
	phone: "Phone",
	icNumber: "IC Number",
	nationality: "Nationality",
	address: "Address",
	package: "Package",
	parcelStatus: "Parcel Status",
	theme: "Renovation Theme",
	balance: "Renovation Balance",
	transactions: "Transactions"
};

const BusinessDevelopment = ({history}) => {
	const classes = useStyles();
	const {user} = useContext(AuthContext);
	const [parcels, setParcels] = useState([]);
	const [loading, setLoading] = useState(false);
	const {openGlobalList} = useContext(ListContext);
	const [project, setProject] = useState();
	const {store, setStore} = useContext(StoreContext);

	useEffect(
		() => {
			let unsubscribe1 = () => null;
			const fetchData = async () => {
				if (project) {
					unsubscribe1 = loadParcels(setParcels, project, setLoading);
				}
			};
			fetchData();

			if (project && project.documents) {
				project.documents.forEach((doc, i) => {
					fieldToDisplay = {...fieldToDisplay, [`document${i+1}`]: doc};
				});
			}

			return () => {
				unsubscribe1();
			};
		},
		// eslint-disable-next-line
		[project]
	);

	const hanldeClickProject = async () => {
		const projects = await loadProjects();
		console.log(projects);
		const response = await openGlobalList(projects, "Select Project", "name", "location_city", false, true);
		if (!response) {
			return;
		}
		setProject(response);
		setStore({...store, project: response});
	};

	return (
		<Box m={1}>
			{loading && <LinearProgress />}
			<Grid container spacing={1} justify="center">
				<Grid item container xs={12} md={8}>
					<Grid item xs={7}>
						<Box m={1} alignItems="center">
							<Typography variant="h6">Welcome {user.displayName}</Typography>
						</Box>
					</Grid>
					<Grid item xs={5} align="right">
						<Box m={1} display="flex" alignItems="center" justifyContent="flex-end">
							<IconButton onClick={hanldeClickProject} size="small">
								<Typography className={classes.project} align="center">
									{project ? project.name : "Select a project"}
								</Typography>
							</IconButton>
							{!arrayIsEmpty(parcels) && (
								<ExportToExcel
									data={mapToExcel(parcels, project)}
									fieldToDisplay={fieldToDisplay}
									sheetName="parcels"
									filename={parcels[0].project.name}
								/>
							)}
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Grid container item>
							<ParcelFlexboxContainer parcels={parcels} project={project} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default withRouter(BusinessDevelopment);
