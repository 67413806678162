import gapi from "context/google-context";
import { notification } from "./notification";

const updateText = async (state, docId) => {
  console.log(state)
  const requests = state.map((doc) => {
    const request = {
      replaceAllText: {
        containsText: {
          text: doc.label.trim(),
          matchCase: true,
        },
        replaceText: doc.displayName,
      },
    };
    return request;
  });

  try {
    await gapi.client.docs.documents.batchUpdate({
      documentId: docId,
      resource: {
        requests,
      },
    });
    notification("Success", "Document text has been updated", "success");
  } catch (err) {
    console.log(err);
    notification("Opps", err.result.error.message, "danger");
  }
};

export default updateText;
