export function getDay(date) {
  const n = date.getDay();
  let day = "";
  switch (n) {
    case 1:
      day = "Mon";
      break;
    case 2:
      day = "Tues";
      break;
    case 3:
      day = "Wed";
      break;
    case 4:
      day = "Thurs";
      break;
    case 5:
      day = "Fri";
      break;
    case 6:
      day = "Sat";
      break;
    case 0:
      day = "Sun";
      break;
    default:
  }

  return day;
}
