import { firestore } from "firebase/firebase-utils"
import { mapSnapshot } from "functions/mapSnapshot";

export async function populateParcelsWithOwners(){
    try {
        const snapshot = await firestore.collection("parcels").get();
        const parcels = mapSnapshot(snapshot);
        const ownersSnapshot = await firestore.collection("owners").get();
        const owners = mapSnapshot(ownersSnapshot);

        parcels.forEach(async parcel=>{
            const fullOwners = parcel.owners.map(owner => {
                const foundOwner = owners.find(doc=> doc.id === owner)
                if(foundOwner){
                    return foundOwner
                }else {
                    console.log("Cannot find owner " + owner)
                    return {id: owner}
                }
            })
            console.log(fullOwners)
            await firestore.collection("parcels").doc(parcel.id).update({owners: fullOwners, ownerIds: parcel.owners})
            console.log("Parcel " + parcel.parcelNumber + " is updated with owners details");
        })
    } catch (error) {
        console.log(error)
    }
}