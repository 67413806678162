import { firestore } from "firebase/firebase-utils";
import { mapSnapshot } from "./mapSnapshot";

export async function loadProjects() {
  try {
    const snapshot = await firestore.collection("projects").get();
    if (snapshot.empty) {
      return [];
    } else {
      const projects = mapSnapshot(snapshot);
      console.log("Projects loaded:")
      console.log(projects)
      return projects
    }
  } catch (error) {
    console.log(error);
  }
}
