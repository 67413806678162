//1.
import React, { useState } from "react";

//2.
export const UsersContext = React.createContext();

//3.
export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState();

  return (
    <UsersContext.Provider value={{ users, setUsers }}>
      {children}
    </UsersContext.Provider>
  );
};
