import { firestore } from "firebase/firebase-utils";
import rawLeads from "../test-functions/backup/rawLeads_backup.json";

export async function importRawLeads() {
  try {
    const collectionRef = firestore.collection("rawLeads");
    rawLeads.forEach(async (rawLead) => {
      await collectionRef.add(rawLead);
      console.log("Raw Lead added: " + rawLead.id);
    });
  } catch (error) {
    console.log(error);
  }
}
