import {firestore} from "../firebase/firebase-utils";
import {mapSnapshot} from "../functions/mapSnapshot";

export async function loadClients() {
	try {
		const snapshot = await firestore.collection("clients").get();
		const clients = mapSnapshot(snapshot);
		return clients;
	} catch (error) {
		console.log(error);
	}
}
