//1.
import React, { createContext, useState } from "react";

//2.
export const LeadsContext = createContext();

//3.
export const LeadsProvider = ({ children }) => {
  const [globalLeads, setGlobalLeads] = useState({});

  return (
    <LeadsContext.Provider value={{ globalLeads, setGlobalLeads }}>
      {children}
    </LeadsContext.Provider>
  );
};
