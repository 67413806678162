import firebase from "../firebase/firebase-utils";
import { notification } from "./notification";

export async function getPages(email) {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection("pages")
      .where("admins", "array-contains", email)
      .get();
    const pages = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return pages;
  } catch (err) {
    console.log(err);
    notification("Opps", err.message, "danger");
  }
}
