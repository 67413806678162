import { Box, Button, Grid } from "@material-ui/core";
import { addProjectIds } from "./addProjectIds";
import { checkDuplicatedOwners } from "./checkDuplicatedOwners";
import { populateParcelsWithOwners } from "./populateParcelsWithOwners";
import { replaceOwners } from "./replaceOwners";
import { seedRenovationFees } from "./seedRenovationFees";
import { firestore } from "firebase/firebase-utils";
import { mapSnapshot } from "functions/mapSnapshot";

export function EditData() {
    const deleteEmptyParcels = async () => {
        console.log("deleteEmptyParcels");
        const collectionRef = firestore.collection("parcels").where("projectId", "==", "onQnkl5M711zkrDXPloV");
        const snapshot = await collectionRef.get();
        const parcels = mapSnapshot(snapshot);

        const emptyParcels = parcels.filter((parcel) => !parcel.parcelNumber);
        console.log(emptyParcels);
        emptyParcels.forEach(async (parcel) => {
            await firestore.collection("parcels").doc(parcel.id).delete();
            console.log("deleted", parcel.id);
        });
    };

    return (
        <Box m={1}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" color="primary" onClick={populateParcelsWithOwners}>
                        Populate Parcels with Owners Details
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" color="primary" onClick={addProjectIds}>
                        Add projectId to parcels
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" color="primary" onClick={checkDuplicatedOwners}>
                        Check Duplicated owners
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" color="primary" onClick={replaceOwners}>
                        Replace deleted owners in Parcels
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" color="primary" onClick={seedRenovationFees}>
                        Seed Scarletz Renovation Fees
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="small" color="primary" onClick={deleteEmptyParcels}>
                        Delete Empty Parcels
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
