import {firestore} from "../firebase/firebase-utils";
import {mapParcelSnapshot} from "./mapParcelSnapshot";

export function loadParcels(setParcels, project, setLoading) {
	try {
		setLoading(true);
		return firestore
			.collection("parcels")
			.where("projectId", "==", project.id)
			.orderBy("parcelNumber")
			.onSnapshot(async (snapshot) => {
				if (snapshot.empty) {
					setParcels([]);
					setLoading(false);
				} else {
					let parcels = mapParcelSnapshot(snapshot);
					setParcels(parcels);
					setLoading(false);
					console.log(parcels);
				}
			});
	} catch (error) {
		console.log(error);
		setLoading(false);
	}
}
