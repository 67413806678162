import firebase from "../firebase/firebase-utils";
import { notification } from "../functions/notification";

export async function getUsers() {
  try {
   
    const collectionRef = firebase.firestore().collection("users");
    const snapShot = await collectionRef.get();
    const users = snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return users;
  } catch (err) {
    notification("Opps!", err.message, "danger");
  }
}
