import { auth } from "../firebase/firebase-utils";
import { notification } from "./notification";

export async function sendEmailVerification() {
  try {
    const user = auth.currentUser;
    await user.sendEmailVerification();
    notification("Verification Email sent", `Please check your email at ${user.email}`, "info")
  } catch (err) {
    notification("Opps!", err.message, "danger");
  }
}
