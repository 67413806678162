import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Switch,
} from "@material-ui/core";
import { AuthContext } from "context/auth-context";
import { ListContext } from "context/list-context";
import { getUserFromFS } from "functions/getUserFromFS";
import { getUsers } from "functions/getUsers";
import { notification } from "functions/notification";
import { updateSuperAdmin } from "functions/updateSuperAdmin";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";

const UserProfile = ({ history }) => {
  const { user, setUser, authUser } = useContext(AuthContext);
  const [state, setState] = useState();
  const [checkedSuperAdmin, setCheckedSuperAdmin] = useState(false);
  const { openGlobalList } = useContext(ListContext);

  useEffect(() => {
    if (user.email === "daveckw@gmail.com") {
      if (user.role === "Super Admin") {
        setCheckedSuperAdmin(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await getUserFromFS(state.signInAs);
    setUser(user);
    console.log(user);
    if (user) {
      notification("Sign In As", "Signed in as " + user.email, "success");
      history.push("/");
    }
  };

  const handleChangeSuperAdmin = async () => {
    const superAdmin = !checkedSuperAdmin;
    let role = "";
    if (superAdmin) {
      role = "Super Admin";
      setUser({ ...user, role: role });
    } else {
      role = "User";
      setUser({ ...user, role: role });
    }
    updateSuperAdmin(user, role);
    setCheckedSuperAdmin(superAdmin);
  };

  const handleSignInAs = async () => {
    const users = await getUsers();
    const object = await openGlobalList(
      users,
      "Select a user",
      "displayName",
      "face",
      false, //Add account is false
      true // Search is true
    );
    if (object) {
      setState({ ...state, signInAs: object.email });
    }
  };

  return (
    <React.Fragment>
      <Box m={1}>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12} md={7}>
            <Grid item xs={12}>
              <Box m={2}>
                <Typography align="center" variant="h4">
                  Welcome {user.displayName}
                </Typography>
              </Box>
            </Grid>
            <Paper>
              <Box
                m={1}
                display={
                  authUser.email === "daveckw@gmail.com" ? "block" : "none"
                }
              >
                <form onSubmit={handleSubmit}>
                  <Grid container item xs={12} spacing={1} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        required
                        type="email"
                        variant="outlined"
                        name="signInAs"
                        onChange={handleChange}
                        fullWidth
                        disabled
                        onClick={handleSignInAs}
                        value={state && state.signInAs}
                      />
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Button variant="outlined" type="submit">
                        Sign In As
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                <Grid container item xs={12} spacing={1} alignItems="center">
                  <Grid item xs={8} align="center">
                    <Box m={1}>
                      <Typography variant="h5">Super Admin</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} align="center">
                    <Box m={1}>
                      <Switch
                        checked={checkedSuperAdmin}
                        onChange={handleChangeSuperAdmin}
                        name="checkedSuperAdmin"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
