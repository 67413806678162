import {firestore} from "firebase/firebase-utils";
import {getFirestoreData} from "functions/getFirestoreData";
import {mapSnapshot} from "functions/mapSnapshot";

export async function checkDuplicatedOwners() {
	try {
		const owners = await getFirestoreData("owners");
		let duplicatedOwners = [];
		let uniqueOwners = [];

		const addDuplicatedOwner = (foundOwner) => {
			const fOwner = duplicatedOwners.find((dOwner) => dOwner.name === foundOwner.name);
			if (!fOwner) {
				duplicatedOwners.push(foundOwner);
			}
		};

		owners.forEach((owner) => {
			const foundOwner = uniqueOwners.find((dOwner) => dOwner.name === owner.name);
			if (foundOwner) {
				console.log(foundOwner.name + " is duplicated");
				addDuplicatedOwner(foundOwner);
			} else {
				uniqueOwners.push(owner);
			}
		});
		console.log("Duplicated Owners:");
		console.log(duplicatedOwners);

		duplicatedOwners.forEach(async (dOwner) => {
			const snapshot = await firestore.collection("owners").where("name", "==", dOwner.name).get();
			const dOwners = mapSnapshot(snapshot);
			console.log(dOwners);
			dOwners.forEach(async (dOwner, i) => {
				if (i === 0) return;
				// await firestore.collection("owners").doc(dOwner.id).delete();
				// console.log(dOwner.name + " id: " + dOwner.id + " is deleted");
			});
		});
	} catch (error) {
		console.log(error);
	}
}
