import {firestore} from "firebase/firebase-utils";
import {notification} from "./notification";

export async function deleteClient(client) {
	try {
		await firestore.collection("clients").doc(client.id).delete();
		console.log("You have successfully deleted the client");
		notification("Deleted", "You have deleted the client", "warning");
	} catch (error) {
		console.log(error);
	}
}
