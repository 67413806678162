import { firestore } from "firebase/firebase-utils";
import leads_backup from "../test-functions/backup/leads_backup.json";
// import pages_backup from "../test-functions/backup/pages_backup.json";
// import subscriptions_backup from "../test-functions/backup/subscriptions_backup.json";

import actions_backup from "../test-functions/backup/actions_backup.json";

export async function importAll() {
  try {
    console.log(actions_backup);
    leads_backup.forEach(async (lead) => {
      //   await firestore.collection("leads").doc(lead.id).set(lead);
      //   console.log("lead " + lead.id + " uploaded");

      const actionsCollection = actions_backup.find(
        (action) => action.leadId === lead.id
      );

      if (actionsCollection) {
        console.log(actionsCollection.leadId);
        actionsCollection.actions.forEach(async (action) => {
          await firestore
            .collection("leads")
            .doc(lead.id)
            .collection("actions")
            .doc(action.id)
            .set(action);
          console.log("Action " + action.id + " uploaded");
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
}
