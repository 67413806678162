import { firestore } from "firebase/firebase-utils";

export function getNotes(user, setNotes) {
  try {
    return firestore
      .collection("users")
      .doc(user.id)
      .collection("notes")
      .onSnapshot((snapshot) => {
        const notes = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setNotes(notes);
      });
  } catch (error) {
    console.log(error);
  }
}
