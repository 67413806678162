import { firestore } from "firebase/firebase-utils";
import firebase from "firebase";

export async function removeLabel(collection, label, doc) {
  try {
    await firestore
      .collection(collection)
      .doc(doc.id)
      .update({ labels: firebase.firestore.FieldValue.arrayRemove(label) });
    console.log("Label removed from Firestore");
  } catch (error) {
    console.log(error);
  }
}
