//1.
import React, { useState } from "react";

//2.
export const StoreContext = React.createContext();

//3.
export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({});

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {children}
    </StoreContext.Provider>
  );
};
