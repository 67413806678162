import {firestore} from "firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export async function loadOwners() {
	try {
		const snapshot = await firestore.collection("owners").get();
		const owners = mapSnapshot(snapshot);
		return owners;
	} catch (error) {
		console.log(error);
	}
}
