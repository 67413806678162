import { firestore } from "firebase/firebase-utils";
import firebase from "firebase";
import { mapSnapshot } from "./mapSnapshot";

export async function saveParcels(parcels, project, user) {
    try {
        for (let parcelIndex = 0; parcelIndex < parcels.length; parcelIndex++) {
            let parcel = parcels[parcelIndex];
            parcel.owners = parcel.owners.filter((owner) => owner.name);

            for (let ownerIndex = 0; ownerIndex < parcel.owners.length; ownerIndex++) {
                let owner = parcel.owners[ownerIndex];
                const ownerSnapshot = await firestore.collection("owners").where("name", "==", owner.name).get();
                if (ownerSnapshot.empty) {
                    // Create new owner
                    const response = await firestore.collection("owners").add(owner);
                    console.log("New Owner " + owner.name + " created!");
                    parcel.owners[ownerIndex].id = response.id;
                } else {
                    console.log("Owner " + owner.name + " is already in database");
                    await firestore.collection("owners").doc(ownerSnapshot.docs[0].id).update(owner);
                    parcel.owners[ownerIndex] = {
                        ...ownerSnapshot.docs[0].data(),
                        ...owner,
                        id: ownerSnapshot.docs[0].id,
                    };
                }
            }

            parcels[parcelIndex] = parcel; // this is important to reflect changes in original array
            console.log(parcel.owners);
        }

        const packagesSnapshot = await firestore.collection("packages").where("projectId", "==", project.id).get();
        const packages = mapSnapshot(packagesSnapshot);
        let importedPackages = parcels.map((parcel) => parcel.package);
        importedPackages = importedPackages.filter((c, index) => importedPackages.indexOf(c) === index);
        importedPackages.forEach(async (doc) => {
            const foundPackage = packages.find((package_) => package_.name === doc);
            if (foundPackage) {
                console.log("Package is already created. Skip!");
                return;
            }
            const response = await firestore
                .collection("packages")
                .add({ created: new Date(), createdBy: user.email, name: doc, projectId: project.id });
            console.log("Package is created: " + response.id);
            packages.push({ id: response.id, name: doc });
        });

        const findPackage = (parcel) => {
            const foundPackage = packages.find((doc) => doc.name === parcel.package);
            if (!foundPackage) {
                console.log("Cannot find package, something went wrong");
                return null;
            } else {
                return foundPackage.id;
            }
        };

        // THEMES
        const themesSnapshot = await firestore.collection("themes").where("projectId", "==", project.id).get();
        const themes = mapSnapshot(themesSnapshot);
        let importedThemes = parcels
            .filter((parcel) => parcel.theme) // excludes "", null, undefined, and other falsy values
            .map((parcel) => parcel.theme);
        importedThemes = [...new Set(importedThemes)]; // a quick way to remove duplicates
        console.log(importedThemes);
        console.log(parcels);

        for (let doc of importedThemes) {
            const foundTheme = themes.find((theme) => theme.name === doc);
            if (foundTheme) {
                console.log("Theme is already created. Skip!");
            } else {
                const response = await firestore
                    .collection("themes")
                    .add({ created: new Date(), createdBy: user.email, name: doc, projectId: project.id });
                console.log("Theme is created: " + response.id);
                themes.push({
                    id: response.id,
                    name: doc,
                    created: new Date(),
                    createdBy: user.email,
                    projectId: project.id,
                });
            }
        }

        const findTheme = (parcel) => {
            const foundTheme = themes.find((doc) => doc.name === parcel.theme);
            if (!foundTheme) {
                console.log("Cannot find theme, something went wrong");
                return null;
            } else {
                return foundTheme;
            }
        };

        //Seed parcels
        parcels.forEach(async (parcel) => {
            // Find existing parcel
            const snapshot = await firestore
                .collection("parcels")
                .where("parcelNumber", "==", parcel.parcelNumber)
                .where("projectId", "==", project.id)
                .get();

            const packageId = findPackage(parcel);
            const theme = findTheme(parcel);
            if (snapshot.empty) {
                // create new parcel
                const response = await firestore.collection("parcels").add({
                    ...parcel,
                    created: new Date(),
                    updated: new Date(),
                    ownerIds: parcel.owners.map((owner) => owner.id),
                    owners: parcel.owners,
                    package: { id: packageId, name: parcel.package },
                    project: { id: project.id, name: project.name },
                    projectId: project.id,
                    theme,
                });
                console.log(response.id + " parcel is created!");
            } else {
                console.log(parcel.parcelNumber + " is already in Database");
            }
        });
    } catch (error) {
        console.log(error);
    }
}
