import exportFromJSON from "export-from-json";
import { firestore } from "firebase/firebase-utils";

export async function exportActions() {
  try {
    const leadsSnapshot = await firestore
      .collection("leads")
      .where("source", "==", "FB")
      .get();
    const leads = leadsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    console.log(leads);

    let ref = { resolve: () => null, reject: () => null };
    const actionsPromise = new Promise((resolve, reject) => {
      ref = { resolve, reject };
    });
    let actions = [];
    leads.forEach(async (lead, i) => {
      const actionsSnapshot = await firestore
        .collection("leads")
        .doc(lead.id)
        .collection("actions")
        .get();
      if (!actionsSnapshot.empty) {
        const subActions = actionsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          leadId: lead.id,
        }));
        actions.push({leadId: lead.id, actions: subActions});
      }
      if (i === leads.length - 1) {
        ref.resolve(actions);
      }
    });

    const actionsResolved = await actionsPromise;
    console.log(actionsResolved);

    //Export as file
    const data = actionsResolved;
    const fileName = "actions_backup";
    const exportType = "json";
    exportFromJSON({ data, fileName, exportType });
  } catch (error) {
    console.log(error);
  }
}
