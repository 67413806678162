import React, {useContext, useEffect} from "react";
import {Grid, Typography, Box, Button} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import {convertLeads} from "../functions/convertLeads";
import {StoreContext} from "../context/store-context";
import {populateLeadsData} from "../test-functions/populateLeadsData";
import {exportLeads} from "../test-functions/exportLeads";
import {importLeads} from "../test-functions/importLeads";
import {setActiveUsers} from "../test-functions/setActiveUsers";
import {exportPages} from "../test-functions/exportPages";
import {importPages} from "../test-functions/importPages";
import {InputContext} from "../context/input-context";
import {getRawLeads} from "../test-functions/getRawLeads";
import {firestore} from "../firebase/firebase-utils";
import {exportRawLeads} from "../test-functions/exportRawLeads";
import {importRawLeads} from "../test-functions/importRawLeads";
import {fixDate} from "../test-functions/fixDate";
import {exportActions} from "../test-functions/exportActions";
import {exportSubscriptions} from "../test-functions/exportSubscriptions";
import {exportUsers} from "../test-functions/exportUsers";
import {importAll} from "../test-functions/importAll";
import {importUsers} from "../test-functions/importUsers";
import {importSubscriptions} from "../test-functions/importSubscriptions";
import {sendWelcomeEmails} from "../test-functions/sendWelcomeEmails";
import {mapLeads} from "../functions/mapLeads";
import {removeDuplicates} from "../functions/removeDuplicates";
import {getWarriorNames} from "../functions/getWarriorNames";
import { modifyNumbers } from "test-functions/modifyNumbers";

const TestPage = () => {
	const {store, setStore} = useContext(StoreContext);
	const {openDialog} = useContext(InputContext);

	useEffect(
		() => {
			console.log(store);
		},
		[store]
	);

	// eslint-disable-next-line
	const changeDatabase = () => {
		let number = parseInt(store);
		number = number + 1;
		setStore(number);
	};

	const handleOpenDialog = async () => {
		let response = await openDialog("Testing123", "This is a Global Context Dialog Box", "try me!", "Testing123!");

		console.log(response);
	};

	const testPromise = async () => {
		let ref = {resolve: () => null, reject: () => null};
		let chatIdsRef = new Promise((resolve, reject) => {
			// @ts-ignore
			ref = {resolve, reject};
		});

		let chatIds = [];
		let emails = ["daveckw@gmail.com", "debbieleow.dl@gmail.com", "keatwei.chong@gmail.com"];
		emails.forEach(async (email, i) => {
			const snapshot = await firestore.collection("users").where("email", "==", email).get();
			if (!snapshot.empty) {
				const chatId = snapshot.docs[0].data().chatId;
				if (chatId) {
					chatIds.push(chatId);
					console.log(chatId);
				}
			}
			if (i === emails.length - 1) {
				// @ts-ignore
				ref.resolve(chatIds);
			}
		});

		console.log(await chatIdsRef);
	};

	const sendTestLead = async () => {
		const lead = {
			ad_id: "6247468473968",
			form_id: "458967401815708",
			formName: "Pj Project_Lorraine",
			field_data: [
				{name: "full_name", values: ["Testy Wong"]},
				{name: "phone_number", values: ["+60173328298"]},
				{values: ["corynnwong@gmail.com"], name: "email"},
				{values: ["investment"], name: "intention"},
			],
			page_id: "295016180591184",
			adgroup_id: "6247468473968",
			created_time: 1622133427,
			campaignName: "PJ Project_Own Stay_Klang Valley_Video",
			leadgen_id: "1184966685309178",
			id: "1184966685309178",
			source: "FB",
			pageName: "马来西亚投资网 | Malaysia Investment Portal",
		};

		await firestore.collection("leads").add(lead);
		console.log(lead);
		console.log("Added into Firestore");
	};

	const getAllUsers = async () => {
		const userSnapshot = await firestore.collection("users").get();
		const users = userSnapshot.docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
		}));
		console.log(users);
	};

	const testEmail = async () => {
		await sendWelcomeEmails();
	};

	const testQuery = async () => {
		firestore
			.collection("leads")
			.where("admins", "array-contains", "aya_raine@yahoo.com.au")
			.orderBy("created", "desc")
			.onSnapshot(async (snapshot) => {
				const leads = snapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}));
				let uniqueLeads = removeDuplicates(leads);
				let mappedLeads = mapLeads(uniqueLeads);
				mappedLeads = await getWarriorNames(mappedLeads);
				console.log(mappedLeads);
			});
	};

	return (
		<Box m={2}>
			<Grid container spacing={1} justify="center">
				<Box m={1} mb={0}>
					<Button
						size="small"
						variant="outlined"
						color="primary"
						component={Link}
						to="/facebook-login"
						fullWidth>
						<Typography variant="h6">Facebook Login</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button
						size="small"
						variant="outlined"
						color="primary"
						component={Link}
						to="/facebook-connect"
						fullWidth>
						<Typography variant="h6">Facebook Connect</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" component={Link} to="/get-lead" fullWidth>
						<Typography variant="h6">Get Lead from Facebook</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button
						size="small"
						variant="outlined"
						color="primary"
						component={Link}
						to="/leads-table"
						fullWidth>
						<Typography variant="h6">Leads Table</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button
						size="small"
						variant="outlined"
						color="primary"
						component={Link}
						to="/leadgen-form"
						fullWidth>
						<Typography variant="h6">Leadgen Forms</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" component={Link} to="/sign-up" fullWidth>
						<Typography variant="h6">Sign Up</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={convertLeads} fullWidth>
						<Typography variant="h6">Convert Leads</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={populateLeadsData} fullWidth>
						<Typography variant="h6">populateLeadsData</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={exportLeads} fullWidth>
						<Typography variant="h6">Export Leads</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={importLeads} fullWidth>
						<Typography variant="h6">Import Leads</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={setActiveUsers} fullWidth>
						<Typography variant="h6">Set Active Users</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={exportPages} fullWidth>
						<Typography variant="h6">Export Pages</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={importPages} fullWidth>
						<Typography variant="h6">Import Pages</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={handleOpenDialog} fullWidth>
						<Typography variant="h6">Open Input Dialog</Typography>
					</Button>
				</Box>

				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={getRawLeads} fullWidth>
						<Typography variant="h6">Test Function</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={testPromise} fullWidth>
						<Typography variant="h6">Test Promise</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={sendTestLead} fullWidth>
						<Typography variant="h6">Send Test Lead</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={getAllUsers} fullWidth>
						<Typography variant="h6">Get all users</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={exportRawLeads} fullWidth>
						<Typography variant="h6">Export Raw Leads</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={importRawLeads} fullWidth>
						<Typography variant="h6">Import Raw Leads</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={fixDate} fullWidth>
						<Typography variant="h6">Fix created_date</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={exportActions} fullWidth>
						<Typography variant="h6">Export Leads Actions</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={exportSubscriptions} fullWidth>
						<Typography variant="h6">Export Subscriptions</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={exportUsers} fullWidth>
						<Typography variant="h6">Export Users</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={importAll} fullWidth>
						<Typography variant="h6">Import Leads and Actions</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={importUsers} fullWidth>
						<Typography variant="h6">Import Users</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={importSubscriptions} fullWidth>
						<Typography variant="h6">Import Subscriptions</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={testEmail} fullWidth>
						<Typography variant="h6">Test Email</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={testQuery} fullWidth>
						<Typography variant="h6">Test Query</Typography>
					</Button>
				</Box>
				<Box m={1} mb={0}>
					<Button size="small" variant="outlined" color="primary" onClick={modifyNumbers} fullWidth>
						<Typography variant="h6">Modify Numbers</Typography>
					</Button>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box m={1} mb={0}>
					<Typography variant="subtitle1">DJC Playaground</Typography>
				</Box>
			</Grid>
		</Box>
	);
};

export default withRouter(TestPage);
