import {firestore} from "firebase/firebase-utils";

export async function deleteParcel(id) {
	try {
		await firestore.collection("parcels").doc(id).delete();
		console.log("Parcel deleted: " + id);
	} catch (error) {
		console.log(error);
	}
}
