import {firestore} from "firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export async function saveClients(clients) {
	try {
		const snapshot = await firestore.collection("clients").get();
		const existingClients = mapSnapshot(snapshot);

		// Check each client whether it is already in database
		const batch = firestore.batch();
		clients.forEach((client) => {
			const findClient = existingClients.find((exClient) => exClient.name === client.name);
			if (findClient) {
				console.log(client.name + " is already in the Database. Skip");
				return;
			}
			const docRef = firestore.collection("clients").doc();
			batch.set(docRef, client);
			console.log("Adding " + client.name + " to database");
		});
		await batch.commit();
		console.log("Batch upload completed");
	} catch (error) {
		console.log(error);
	}
}
