import firebase, { firestore } from "../firebase/firebase-utils";
import { notification } from "./notification";

export async function signUp(user) {
  const displayName = `${user.firstName} ${user.lastName}`;
  let { email, firstName, lastName, phone } = user;
  email = email.toLowerCase();
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, user.password);
    notification(
      "Welcome",
      "You have successfully signed up. Please check your email for the verification link",
      "info"
    );
    const newUser = firebase.auth().currentUser;
    await newUser.updateProfile({
      displayName: displayName,
    });
    await newUser.sendEmailVerification();

    //Create leadsAppSignUps Firestore entry
    const collectionRef = firestore.collection("appSignUps");
    await collectionRef.doc(email).set({
      displayName,
      firstName,
      lastName,
      phone,
      email,
    });
    console.log(displayName + " added into Firestore Collection");

    return newUser;
  } catch (err) {
    notification("Opps!", err.message, "danger");
    return "error";
  }
}
