import exportFromJSON from "export-from-json";
import { firestore } from "../firebase/firebase-utils";

export async function exportPages() {
  const collectionRef = firestore.collection("pages");

  const snapShot = await collectionRef.get();
  const pages = snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  console.log(pages);
  const json = JSON.stringify(pages);
  console.log(json);

  //Export as file
  const data = pages;
  const fileName = "pages_backup";
  const exportType = "json";
  exportFromJSON({ data, fileName, exportType });
}
