import {firestore} from "../firebase/firebase-utils";

export function loadClient(clientId, setClient) {
	try {
		return firestore.collection("clients").doc(clientId).onSnapshot((doc) => {
			if (doc.exists) {
				const client = {...doc.data(), id: doc.id};
				setClient(client);
			}
		});
	} catch (error) {
		console.log(error);
	}
}
