import {firestore} from "firebase/firebase-utils";
import firebase from "firebase";

export async function deleteDocumentFromProject(doc, project) {
	try {
		await firestore
			.collection("projects")
			.doc(project.id)
			.update({documents: firebase.firestore.FieldValue.arrayRemove(doc.name)});
		console.log("Document deleted from project");
	} catch (error) {
		console.log(error);
	}
}
