import {firestore} from "firebase/firebase-utils";
import {notification} from "./notification";

export async function updateProject(id, projectName) {
	try {
		await firestore.collection("projects").doc(id).update({name: projectName});
		notification("Success", "You have updated the project name", "success");
	} catch (error) {
		console.log(error);
	}
}
