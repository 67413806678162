import { firestore } from "firebase/firebase-utils";

export async function updateSuperAdmin(user, role) {
  try {
    await firestore.collection("users").doc(user.id).update({ role: role });
    console.log("Updated Role in Firestore");
  } catch (err) {
    console.log(err);
  }
}
