import gapi from "../context/google-context";
import { notification } from "./notification";

export async function getGoogleDoc(docId) {
  try {
    if (docId) {
      const response = await gapi.client.docs.documents.get({
        documentId: docId,
      });

      const doc = response.result;
      const title = doc.title;
      console.log("TITLE : " + title);
      return title;
    }
  } catch (error) {
    notification(
      "Error",
      "Something went wrong. Please check you are using the correct google account or correct doc id.",
      "danger"
    );
    console.log(error);
  }
}
