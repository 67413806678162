import React from "react";
import FullScreenDialog from "../full-dialog";
import {useState} from "react";
import {Paper, Grid, Box, TextField, Tabs} from "@material-ui/core";
import {Badge} from "@material-ui/core";
import {Tab} from "@material-ui/core";
import {useEffect} from "react";
import SaleCard from "./sale-card.component";
import SaleFlexbox from "./sale-flexbox.component";

const getLabeledClients = (search, clients) => {
	let labeledClients = clients.filter((client) => {
		let choose = false;
		if (client.labels) {
			client.labels.forEach((label) => {
				if (label.label.toLowerCase().includes(search.toLowerCase())) {
					choose = true;
				}
			});
		}
		return choose;
	});
	return labeledClients;
};

export default function SalesFlexboxContainer({clients}) {
	const [client, setClient] = useState();
	// eslint-disable-next-line
	const [contacts, setContacts] = useState([]);
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [searchLabel, setSearchLabel] = useState("");
	const [tab, setTab] = useState(0);
	const [labeledClients, setLabeledClients] = useState(clients);
	const [labelNumber, setLabelNumber] = useState({
		prospect: 0,
		tentative: 0,
		definite: 0,
		confirmed: 0,
		cancelled: 0,
		terminated: 0,
	});

	useEffect(
		() => {
			console.log(clients);
			let prospectParcels = getLabeledClients("Prospect", clients);
			let tentativeParcels = getLabeledClients("Tentative", clients);
			let definiteParcels = getLabeledClients("Definite", clients);
			let confirmedParcels = getLabeledClients("Confirmed", clients);
			let cancelledParcels = getLabeledClients("Cancelled", clients);
			let terminatedParcels = getLabeledClients("Terminated", clients);
			const labelNumber = {
				prospect: prospectParcels.length,
				tentative: tentativeParcels.length,
				definite: definiteParcels.length,
				confirmed: confirmedParcels.length,
				cancelled: cancelledParcels.length,
				terminated: terminatedParcels.length,
			};
			console.log(labelNumber);
			setLabelNumber(labelNumber);
			setLabeledClients(clients);
		},
		[clients]
	);

	useEffect(
		() => {
			let myClients = clients;
			switch (tab) {
				case 0:
					break;
				case 1:
					myClients = getLabeledClients("Prospect", clients);
					break;
				case 2:
					myClients = getLabeledClients("Tentative", clients);
					break;
				case 3:
					myClients = getLabeledClients("Definite", clients);
					break;
				case 4:
					myClients = getLabeledClients("Confirmed", clients);
					break;
				case 5:
					myClients = getLabeledClients("Cancelled", clients);
					break;
				case 6:
					myClients = getLabeledClients("Terminated", clients);
					break;
				default:
			}
			console.log(myClients);
			setLabeledClients(myClients);
			// eslint-disable-next-line
		},
		// eslint-disable-next-line
		[tab]
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
		console.log(newValue);
	};

	let filteredClients = labeledClients;

	filteredClients = filteredClients.filter((client) => {
		let choose = false;
		if (client.name.toLowerCase().includes(search.toLowerCase())) {
			choose = true;
		}

		return choose;
	});

	if (searchLabel) {
		filteredClients = filteredClients.filter((parcel) => {
			let choose = false;
			if (parcel.labels) {
				parcel.labels.forEach((label) => {
					if (label.label.toLowerCase().includes(searchLabel.toLowerCase())) {
						choose = true;
					}
				});
			}
			return choose;
		});
	}

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Box display="flex" alignItems="center">
					<Tabs
						value={tab}
						onChange={handleChangeTab}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto">
						<Tab
							label={
								<Badge badgeContent={clients.length} color="primary">
									Main
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["prospect"]} color="primary">
									Prospect
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["tentative"]} color="primary">
									Tentative
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["definite"]} color="primary">
									Definite
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["confirmed"]} color="primary">
									Confirmed
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["cancelled"]} color="primary">
									Cancelled
								</Badge>
							}
						/>
						<Tab
							label={
								<Badge badgeContent={labelNumber["terminated"]} color="primary">
									Terminated
								</Badge>
							}
						/>
					</Tabs>
				</Box>
			</Grid>
			<Grid item container xs={12}>
				<Grid item xs={6}>
					<Box mr={1}>
						<TextField
							variant="outlined"
							label="search client name"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							fullWidth
						/>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<TextField
							variant="outlined"
							label="search label"
							value={searchLabel}
							onChange={(e) => setSearchLabel(e.target.value)}
							fullWidth
						/>
					</Box>
				</Grid>
			</Grid>
			<Paper style={{width: "100%", padding: "10px"}}>
				{filteredClients.map((client, i) => (
					<SaleFlexbox
						client={client}
						setOpen={setOpen}
						setClient={setClient}
						setContacts_={setContacts}
						key={i}
					/>
				))}
			</Paper>
			<FullScreenDialog
				open={open}
				handleClose={handleClose}
				title="Client Card"
				component={<SaleCard client_={client} />}
			/>
		
		</React.Fragment>
	);
}
