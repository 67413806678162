import { Box, Button, Card, CardContent, Grid, LinearProgress, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { AuthContext } from "context/auth-context";
import { addNote } from "functions/addNote";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import UploadFile from "./upload-file.component";
import { uploadFiles } from "functions/uploadFiles";

export default function UploadDocument({ handleFile, action, parcel }) {
    const [progress, setProgress] = useState([]);
    const [files, setFiles] = useState([]);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const handleClickUpload = async (uploadedFiles) => {
        setLoading(true);
        const folderRef = `notes/${user.email}/${uuidv4()}`;
        console.log(uploadedFiles);

        const filesArray = Array.from(uploadedFiles);
        filesArray.forEach(async (file, i) => {
            const fileRef = folderRef + "/" + file.name;
            const downloadURL = await uploadFiles(file, fileRef, setProgress, i, filesArray.length);
            console.log(downloadURL);
            if (downloadURL) {
                setFiles((prevFiles) => {
                    return [
                        ...prevFiles,
                        {
                            name: file.name,
                            downloadURL: downloadURL,
                            owner: user.email,
                            fileRef: fileRef,
                            folderRef: folderRef,
                        },
                    ];
                });
            }
        });

        setLoading(false);
        setProgress(null);
    };

    const handleClickSave = async () => {
        const note = { files: files, userEmail: user.email, parcelId: parcel.id };
        const noteId = await addNote(note, parcel);
        handleFile(files, noteId);
    };

    return (
        <Box m={1}>
            <Grid container justify="center">
                {loading && <LinearProgress />}
                {files.map((file, i) => (
                    <Card style={{ width: "100%" }} key={i}>
                        <CardContent>
                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <a href={file.downloadURL} target="blank">
                                            {file.name}
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
                <Grid item xs={12} align="center">
                    <UploadFile handleClickUpload={handleClickUpload} progress={progress} />
                </Grid>
                <Grid item xs={12} align="center">
                    <Box m={1}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<SaveIcon />}
                            onClick={handleClickSave}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
