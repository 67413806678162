import { firestore } from "firebase/firebase-utils";
import { convertDate } from "../functions/convertDate";

export async function fixDate() {
  try {
    const snapshot = await firestore
      .collection("leads")
      .where("source", "==", "FB")
      .get();
    const leads = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      created: convertDate(doc.data().created_time),
    }));
    leads.forEach(async (lead) => {
      console.log(lead.created);
      await firestore
        .collection("leads")
        .doc(lead.id)
        .update({ created: lead.created });
      console.log("Updated in Firestore");
    });
  } catch (error) {
    console.log(error);
  }
}
