import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import "./index.css";
import App from "./App";
import {AuthProvider} from "./context/auth-context";
import Menu from "./components/menu/menu.component";
import ReactNotification from "react-notifications-component";
import {StoreProvider} from "./context/store-context";
import {InputProvider} from "./context/input-context";
import {UsersProvider} from "./context/users-context";
import {LeadsProvider} from "./context/leads-context";
import {ListProvider} from "./context/list-context";
import {GoogleProvider} from "./context/google-context";
import {ConfirmationProvider} from "./context/ConfirmationContext";

ReactDOM.render(
	<Router>
		<AuthProvider>
			<GoogleProvider>
				<StoreProvider>
					<UsersProvider>
						<LeadsProvider>
							<InputProvider>
								<ListProvider>
									<ConfirmationProvider>
										<ReactNotification />
										<Menu />
										<App />
									</ConfirmationProvider>
								</ListProvider>
							</InputProvider>
						</LeadsProvider>
					</UsersProvider>
				</StoreProvider>
			</GoogleProvider>
		</AuthProvider>
	</Router>,
	document.getElementById("root")
);
