import { firestore } from "firebase/firebase-utils";
import { sendEmail } from "functions/sendEmail";

export async function sendWelcomeEmails() {
  const snapshot = await firestore.collection("users").get();
  const users = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  users.forEach(async (user) => {
    const toEmails = [user.email];
    const fromEmail = ["admin@djcsystem.com"];
    const ccEmails = ["na"];
    const replyTo = "admin@djcsystem.com";
    const subject = "Welcome to DJC Leads App";
    const htmlObject = (
      <div>
        <p>
          <img
            src="https://eliteone.com.my/wp-content/uploads/2021/06/DJC-Logo-980x435.jpg"
            alt=""
            width="264"
            height="117"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </p>
        <p style={{ textAlign: "center" }}>Dear {user.displayName}</p>
        <p style={{ textAlign: "center" }}>
          Congratulation for signing up with{" "}
          <strong>
            <a href="https://leads.djcsystem.com">leads.djcsystem.com</a>
          </strong>
        </p>
        <p style={{ textAlign: "center" }}>
          Recently, we have upgraded our database and changed a new Telegram
          Bot.
        </p>
        <p style={{ textAlign: "center" }}>
          Kindly say "/start" to{" "}
          <strong>
            <a href="https://t.me/djcLeadsBot">https://t.me/djcLeadsBot</a>
          </strong>
        </p>
        <p style={{ textAlign: "center" }}>
          to activate your Telegram Subscription.
        </p>
        <p style={{ textAlign: "center" }}>
          For those who face login issue, kindly <strong>re-sign-up</strong>{" "}
          using the same email address that you previously used.
        </p>
        <p style={{ textAlign: "center" }}>
          We hope to serve you better with more upgraded service.
        </p>
        <p style={{ textAlign: "center" }}>Thank you.</p>
        <p style={{ textAlign: "center" }}></p>
        <p style={{ textAlign: "center" }}>&copy; DJC Venture 2021</p>
      </div>
    );
    const email = {
      toEmails,
      fromEmail,
      ccEmails,
      replyTo,
      subject,
      htmlObject,
    };
    console.log(email);
    await sendEmail(email);
  });
}
