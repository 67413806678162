import {firestore} from "firebase/firebase-utils";
import firebase from "firebase";
import {notification} from "./notification";

export async function deleteTransaction(transaction) {
	try {
		await firestore
			.collection(transaction.collection)
			.doc(transaction.docId)
			.update({transactions: firebase.firestore.FieldValue.arrayRemove(transaction)});
		await firestore.collection("transactions").doc(transaction.id).delete();
		notification("Deleted", "Transaction is deleted!", "info");
	} catch (error) {
		console.log(error);
	}
}
