import { firestore } from "../firebase/firebase-utils"

export async function setActiveUsers(){
    try{
        const collectionRef = firestore.collection("users");
        const snapshot = await collectionRef.get();
        const users = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        console.log(users)
        const activeUsers = users.filter(user => user.personalSalesIndex)
        console.log(activeUsers)
    }catch(err){
        console.log(err)
    }
}