import { firestore } from "firebase/firebase-utils";
import { notification } from "./notification";

export async function saveTemplate(template, googleUser, state) {
  console.log(template);
  try {
    const saveTemplate = {
      ...template,
      userEmail: googleUser,
      state: state ? state : null,
      created: new Date(),
    };
    if (!template.id) {
      const response = await firestore
        .collection("templates")
        .add(saveTemplate);
      notification("Success", "New Template added", "success");
      return { ...saveTemplate, id: response.id };
    } else {
      await firestore
        .collection("templates")
        .doc(template.id)
        .set(saveTemplate);
      console.log("Updated template");
      notification("Success", "Updated template", "success");
      return saveTemplate;
    }
  } catch (error) {
    console.log(error);
  }
}
