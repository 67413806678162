export function removeDuplicates(leads) {
  let uniqueLeads = [];
  let uniqueLeadsId = [];
  leads.forEach((lead) => {
    if (!uniqueLeadsId.includes(lead.id)) {
      uniqueLeads.push(lead);
      uniqueLeadsId.push(lead.id);
    }
  });
  uniqueLeads.sort((a, b) => b.created_time - a.created_time);
  return uniqueLeads;
}
