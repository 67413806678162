import firebase from "firebase";
import { firestore } from "firebase/firebase-utils";
import {mapSnapshot} from "functions/mapSnapshot";

export async function seedRenovationFees() {
	try {
		const snapshot = await firestore.collection("parcels").where("projectId", "==", "GUAP4MwNDsIrmjBQyuqd").get();
		const parcels = mapSnapshot(snapshot);
		let type = {A: 0, B: 0, C: 0, D: 0, E: 0};
		let amount = 0;
		parcels.forEach(async (parcel) => {
			switch (parcel.type) {
				case "A":
					type["A"] = type["A"] + 1;
					amount = -28000;
					break;
				case "B":
					type["B"] = type["B"] + 1;
					amount = -28000;
					break;
				case "C":
					type["C"] = type["C"] + 1;
					amount = -28000;
					break;
				case "D":
					type["D"] = type["D"] + 1;
					amount = -42000;
					break;
				case "E":
					type["E"] = type["E"] + 1;
					amount = -42000;
					break;
				default:
			}
			let transaction = {
				date: new Date(),
				created: new Date(),
				createdBy: "daveckw@gmail.com",
				docId: parcel.id,
				collection: "parcels",
				type: "payment",
				amount: 3000,
				description: `Initial payment`,
			};
			const response = await firestore.collection("transactions").add(transaction);
			transaction = {...transaction, id: response.id};
			await firestore
				.collection("parcels")
				.doc(parcel.id)
				.update({transactions: firebase.firestore.FieldValue.arrayUnion(transaction)});
			console.log("Transaction is added!");
			console.log(transaction);
		});
        console.log(type)
	} catch (error) {
		console.log(error);
	}
}
