export function getWhatsappNumber(phone) {
  var number = phone.match(/\d/g);
  number = number.join("");
  
  if(number[0] === "0"){
      number = "6" + number
  }

  console.log(number)
  return number
}
