// @ts-nocheck
import React, { useState } from "react";
import { useRef } from "react";
import ConfirmationDialog from "../components/confirmation-dialog.component";

export const ConfirmationContext = React.createContext();

export const ConfirmationProvider = ({ children }) => {
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);

  const awaitingPromiseRef = useRef();

  const handleSave = (response) => {
    awaitingPromiseRef.current.resolve(response);
    setOpen(false);
  };

  // const response = await openConfirmation(title, message);
  const openConfirmation = async (title, message) => {
    setMessage(message);
    setTitle(title);
    setOpen(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  return (
    <ConfirmationContext.Provider value={{ openConfirmation }}>
      {children}
      <ConfirmationDialog
        open={open}
        title={title}
        handleClose={handleSave}
        message={message}
      />
    </ConfirmationContext.Provider>
  );
};
