import {firestore} from "firebase/firebase-utils";
import {notification} from "./notification";

export async function saveTodo(todo) {
	try {
		await firestore.collection("todos").add(todo);
		notification("Success", "Added todo", "success");
	} catch (error) {
		console.log(error);
	}
}
