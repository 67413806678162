import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AuthContext } from "context/auth-context";
import { deleteNote } from "functions/deleteNote";
import React, { useContext } from "react";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

export default function NotesList({ notes, setNote }) {
  // eslint-disable-next-line
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const handleClickUrl = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  };

  const handleClickDelete = async (note) => {
    await deleteNote(note, user);
  };

  return (
    <React.Fragment>
      {notes &&
        notes.map((note, i) => (
          <Card variant="outlined" key={i}>
            <CardContent>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                {note.title}
              </Typography>
              <Typography variant="body1">{note.description}</Typography>
            </CardContent>
            <CardActions>
              <Grid item xs={11} zeroMinWidth>
                <Typography
                  style={{ cursor: "pointer" }}
                  variant="subtitle1"
                  noWrap
                  onClick={() => handleClickUrl(note.url)}
                >
                  {note.url}
                </Typography>
              </Grid>
              <Grid item xs={1} align="right">
                <Icon onClick={() => handleClickDelete(note)}>clear</Icon>
              </Grid>
            </CardActions>
          </Card>
        ))}
    </React.Fragment>
  );
}
