import {firestore} from "../firebase/firebase-utils";
import {notification} from "./notification";

export async function saveClient(state, user, edit = false) {
	try {
		console.log(state);
		if (edit) {
			await firestore.collection("clients").doc(state.id).set({...state, userEmail: user.email});
			notification("Success", "You have successfully updated the client", "success");
		} else {
			await firestore.collection("clients").add({...state, userEmail: user.email});
			notification("Success", "You have successfully saved a new client", "success");
		}
	} catch (error) {
		console.log(error);
	}
}
