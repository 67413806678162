import { firestore } from "../firebase/firebase-utils";
import backupLeads from "../test-functions/backup/leads_backup.json";

export async function importLeads() {
  console.log(backupLeads);
  try {
    const collectionRef = firestore.collection("leads");
    backupLeads.forEach(async (lead) => {
      await collectionRef.doc(lead.id).set(lead);
      console.log(lead.id + " imported into Firestore");
    });
  } catch (err) {
    console.log(err);
  }
}
