import {firestore} from "firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export async function loadThemes() {
	try {
		const snapshot = await firestore.collection("themes").get();
		const themes = mapSnapshot(snapshot);
		return themes;
	} catch (error) {
		console.log(error);
	}
}
