import { Box, Button, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import React, { useRef } from "react";
import AddIcon from "@material-ui/icons/Add";
import { arrayIsEmpty } from "functions/arrayIsEmpty";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    input: {
        marginTop: theme.spacing(1),
        margintBottom: theme.spacing(1),
    },
}));

export default function UploadFile({ handleClickUpload, progress }) {
    const classes = useStyles();

    const inputRef = useRef(null);
    const handleChange = (e) => {
        const files = e.target.files;
        handleClickUpload(files);
        console.log(files);
    };

    const handleClick = (event) => {
        inputRef.current.click();
    };

    return (
        <React.Fragment>
            <input type="file" onChange={handleChange} ref={inputRef} style={{ display: "none" }} multiple />
            {!arrayIsEmpty(progress) && (
                <Grid item xs={12}>
                    {progress.map((p) => (
                        <Box my={1}>
                            <LinearProgress variant="determinate" value={p} />
                        </Box>
                    ))}
                </Grid>
            )}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddIcon />}
                    className={classes.input}
                    onClick={handleClick}
                >
                    Upload File
                </Button>
            </Grid>
        </React.Fragment>
    );
}
