import gapi from "context/google-context";
import { notification } from "./notification";

const copyDoc = async (docId, documentName) => {
  var copyTitle = documentName;
  let request = {
    name: copyTitle,
  };
  try {
    const response = await gapi.client.drive.files.copy({
      fileId: docId,
      resource: request,
    });
    console.log("Document has been copied. Id number is: ");
    console.log(response.result.id);
    return response.result.id
  } catch (err) {
    console.log(err);
    notification("Opps", err.result.error.errors[0].message, "danger")
  }
};

export default copyDoc;
