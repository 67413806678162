import {Switch, Route} from "react-router-dom";
import {AuthContext} from "./context/auth-context";
import Homepage from "./pages/homepage";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import TestPage from "./pages/test";
import SignOut from "./pages/sign-out";
import "react-notifications-component/dist/theme.css";
import {useContext} from "react";
import {ThemeProvider, createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";
import OnSnapshotTable from "./pages/on-snapshot-table";
import {themeConfig} from "./config/themeConfig";
import LearningPage from "./pages/learning-page";
import UserProfile from "./pages/user-profile";
import ConnectGoogle from "./pages/connect-google";
import ManualOwner from "./pages/manual-owner";
import ParcelCreate from "./pages/parcel-create";
import ClientCreate from "./pages/client-create";
import SalePage from "./pages/salepage";
import React from "react";
import BusinessDevelopment from "./pages/business-development";
import SeedClients from "seeding/SeedClients";
import SeedParcels from "seeding/SeedParcels";
import { EditData } from "test-functions/EditData";

let theme = createMuiTheme({
	typography: {
		fontFamily: ["Nunito"].join(","),
	},
});
theme = responsiveFontSizes(theme);
themeConfig(theme);

function App() {
	const {user} = useContext(AuthContext);

	const canLogIn = () => {
		if (user) {
			if (user.emailVerified) {
				return true;
			}
		}
		return false;
	};

	return canLogIn() ? (
		<ThemeProvider theme={theme}>
			<div className="App">
				<Switch>
					<Route exact path="/" component={Homepage} />
					<Route exact path="/connect-google" component={ConnectGoogle} />
					<Route exact path="/sign-up" component={SignUp} />
					<Route exact path="/sign-in" component={SignIn} />
					<Route exact path="/test-page" component={TestPage} />
					<Route exact path="/sign-out" component={SignOut} />
					<Route exact path="/on-snapshot-table" component={OnSnapshotTable} />
					<Route exact path="/learning-page" component={LearningPage} />
					<Route exact path="/user-profile" component={UserProfile} />
					<Route exact path="/manual-owner" component={ManualOwner} />
					<Route exact path="/parcel-create" component={ParcelCreate} />
					<Route exact path="/client-create" component={ClientCreate} />
					<Route exact path="/sale-page" component={SalePage} />
					<Route exact path="/business-development" component={BusinessDevelopment} />
					<Route exact path="/seed-clients" component={SeedClients}/>
					<Route exact path="/seed-parcels" component={SeedParcels}/>
					<Route exact path="/edit-data" component={EditData} />
				</Switch>
			</div>
		</ThemeProvider>
	) : (
		<ThemeProvider theme={theme}>
			<div className="App">
				<Switch>
					<Route exact path="/" component={SignIn} />
					<Route exact path="/sign-up" component={SignUp} />
					<Route exact path="/sign-in" component={SignIn} />
					<Route exact path="/test-page" component={TestPage} />
					<Route exact path="/sign-out" component={SignOut} />
					<Route path="/" component={SignIn} />
				</Switch>
			</div>
		</ThemeProvider>
	);
}
export default App;
