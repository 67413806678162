import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {blue, green, red} from "@material-ui/core/colors";
import {Box, Grid, Icon} from "@material-ui/core";
import {useEffect} from "react";
import {loadOwnersFromParcel} from "functions/loadOwnersFromParcel";
import {Divider} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LabelsList from "components/labels-list.component";
import {Badge} from "@material-ui/core";
import {whatsapp} from "functions/whatsapp";
import {callNumber} from "functions/callNumber";
import {addAction} from "functions/addAction";
import {useContext} from "react";
import {AuthContext} from "context/auth-context";
import {loadProject} from "functions/loadProject";
import {toCurrency} from "functions/toCurrency";

const useStyles = makeStyles((theme) => ({
	root: {},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	avatar: {
		backgroundColor: red[500],
	},
	name: {
		fontWeight: "700",
		paddingRight: "10px",
	},
	status: {
		paddingRight: "4px",
		paddingLeft: "4px",
		borderRadius: "6px",
		fontSize: "11px",
		color: theme.palette.getContrastText(blue[500]),
		backgroundColor: blue[500],
	},
	package: {
		paddingRight: "4px",
		paddingLeft: "4px",
		borderRadius: "6px",
		fontSize: "9px",
		color: theme.palette.getContrastText(red[400]),
		backgroundColor: red[400],
	},
	icon: {
		cursor: "pointer",
		marginLeft: "3px",
		color: green[500],
	},
}));

export default function ParcelFlexbox({parcel, setOpen, setParcel, setOwners_}) {
	const classes = useStyles();
	const [owners, setOwners] = useState([]);
	const [project, setProject] = useState();
	const {user} = useContext(AuthContext);

	useEffect(
		() => {
			setProject(parcel.project);
			setOwners(parcel.owners);
			// eslint-disable-next-line
		},
		[parcel]
	);

	const handleClickLaunch = () => {
		setParcel(parcel);
		setOwners_(owners);
		setOpen(true);
	};

	const handleClickAction = async (action, owner) => {
		switch (action) {
			case "Whatsapp":
				whatsapp(owner.phone, owner.name);
				break;
			case "Call":
				callNumber(owner.phone);
				break;

			default:
				console.log(action);
		}
		if (owner) {
			action = action + " " + owner.name;
		}
		await addAction("parcels", parcel.id, action, "", user);
	};

	return (
		<React.Fragment>
			<Grid item xs={12} align="left">
				<LabelsList parcel={parcel} />
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={8} align="left">
					<Box fontWeight="fontWeightMedium" display="flex" alignItems="center">
						<Typography className={classes.name} noWrap color="textSecondary">
							{project && project.name}
						</Typography>

						<Icon
							style={{
								fontSize: "18px",
								color: "#818080",
								paddingLeft: "4px",
								cursor: "pointer",
							}}
							onClick={handleClickLaunch}
						>
							launch
						</Icon>
					</Box>
				</Grid>
				<Grid item xs={4} align="right">
					<Badge>
						<Typography
							variant="subtitle1"
							className={
								// @ts-ignore
								classes.status
							}
						>
							{parcel.parcelStatus}
						</Typography>
					</Badge>
				</Grid>
				<Grid item xs={4} align="left">
					<Box display="flex" alignItems="center">
						<Typography
							className={
								// @ts-ignore
								classes.name
							}
							noWrap
							color="textSecondary"
						>
							{parcel.parcelNumber}
						</Typography>
						<Typography variant="subtitle2" color="textSecondary">
							Type {parcel.type}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={8} align="right">
					<Badge>
						<Typography variant="subtitle2" className={classes.package}>
							{parcel.package.name}
						</Typography>
					</Badge>
				</Grid>
			</Grid>
			{owners.map((owner, i) => (
				<Grid container item xs={12} key={i}>
					<Grid item xs={4}>
						<Box display="flex" alignItems="center" style={{marginBottom: "3px"}}>
							<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
								account_circle
							</Icon>

							<Typography variant="body2" color="textSecondary">
								Owner {i + 1}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={8} align="left">
						<Typography variant="body2" color="textSecondary" style={{fontWeight: "700"}}>
							{owner.name}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Box display="flex" alignItems="center">
							<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
								phone
							</Icon>
							<Typography variant="body2" color="textSecondary">
								Phone:
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={5} align="left">
						<Typography variant="body2" color="textSecondary">
							{owner.phone}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Box display="flex" justifyContent="flex-end" alignItems="center">
							<WhatsAppIcon
								className={classes.icon}
								onClick={(e) => handleClickAction("Whatsapp", owner)}
								fontSize="small"
							/>
							<Icon
								className={classes.icon}
								onClick={(e) => handleClickAction("Call", owner)}
								fontSize="small"
							>
								phone
							</Icon>
						</Box>
					</Grid>
				</Grid>
			))}
			{parcel.lastAction && (
				<Grid container>
					<Grid item xs={4}>
						<Box display="flex" alignItems="center">
							<Icon fontSize="small" style={{color: red[500], marginRight: "5px"}}>
								keyboard
							</Icon>
							<Typography variant="body2" color="textSecondary" style={{fontWeight: "bold"}}>
								Last Action
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={8}>
						<Box>
							<Typography variant="body2" color="textSecondary">
								{parcel.lastAction.action}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								{parcel.lastAction.remark}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			)}
			<Grid container>
				<Grid item xs={4}>
					<Box display="flex" alignItems="center">
						<Icon fontSize="small" style={{color: red[500], marginRight: "5px"}}>
							attach_money
						</Icon>
						<Typography variant="body2" color="textSecondary" style={{fontWeight: "bold"}}>
							Balance
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={8}>
					<Box>
						<Typography variant="body2" color="textSecondary">
							{parcel.balance && toCurrency(parcel.balance)}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Box my={1}>
					<Divider light />
				</Box>
			</Grid>
		</React.Fragment>
	);
}
