import {
  Box,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  label: {
    paddingRight: "4px",
    paddingLeft: "4px",
    borderRadius: "6px",
    fontSize: "10px",
  },
}));

export default function LabelsList({ parcel, handleDeleteLabel }) {
  const classes = useStyles();

  return (
    <Box my={1} display="flex" flexWrap="wrap">
      {parcel.labels &&
        parcel.labels.map((label, i) => (
          <Typography
            key={i}
            className={classes.label}
            variant="subtitle2"
            style={{
              backgroundColor: label ? label.color : "white",
              color: label ? label.fontColor : "white",
            }}
          >
            {label && label.label}
            {handleDeleteLabel && (
              <IconButton
                size="small"
                onClick={() => {
                  handleDeleteLabel(label);
                }}
              >
                <Icon fontSize="small">clear</Icon>
              </IconButton>
            )}
          </Typography>
        ))}
    </Box>
  );
}
