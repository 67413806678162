import {Button, Icon, IconButton} from "@material-ui/core";
import {CircularProgress} from "@material-ui/core";
import {Box, Grid, TextField} from "@material-ui/core";
import {GoogleContext} from "../context/google-context";
import SaveIcon from "@material-ui/icons/Save";
import copyDoc from "../functions/copyDoc";
import updateText from "../functions/updateText";
import gapi from "../context/google-context";
import React, {useState} from "react";
import {useEffect} from "react";
import {useContext} from "react";
import {ListContext} from "../context/list-context";
import {getTemplates} from "../functions/getTemplates";
import {AuthContext} from "../context/auth-context";
import {InputContext} from "../context/input-context";
import {saveTemplate} from "../functions/saveTemplate";
import {deleteTemplate} from "../functions/handleDelete";
import {Typography} from "@material-ui/core";
import {getGoogleDoc} from "../functions/getGoogleDoc";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Divider} from "@material-ui/core";
import moment from "moment";
import {notification} from "../functions/notification";

const INITIAL_STATE = {label: "", displayName: ""};

export default function ConnectGoogle({parcel, owners, client, contacts, handleClose}) {
	const {googleAuth, googleUser} = useContext(GoogleContext);
	// eslint-disable-next-line
	const {user} = useContext(AuthContext);
	const {openGlobalList} = useContext(ListContext);
	const {openDialog} = useContext(InputContext);
	const [state, setState] = useState([INITIAL_STATE]);
	const [template, setTemplate] = useState({docId: "", title: ""});
	const [url, setUrl] = useState();
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [documentName, setDocumentName] = useState();

	useEffect(
		() => {
			if (parcel) {
				const documentName = parcel.project.name + "_" + parcel.parcelNumber;
				setDocumentName(documentName);
			}
			if (client) {
				const documentName = client.name + "_" + moment(new Date()).format("YYYY-MM-DD");
				setDocumentName(documentName);
			}
		},
		// eslint-disable-next-line
		[template]
	);

	useEffect(
		() => {
			console.log("Google Auth: " + googleAuth);
			console.log("Google User: " + googleUser);
			// eslint-disable-next-line
		},
		// eslint-disable-next-line
		[googleAuth]
	);

	const handleClickGoogle = async () => {
		const response = await gapi.auth2.getAuthInstance().signIn();
		console.log(response);
	};

	const handleChange = (e, i) => {
		const {name, value} = e.target;
		let newState = [...state];
		newState[i] = {...newState[i], [name]: value};
		setState(newState);
	};

	const handleClickSignOut = () => {
		console.log("signing out");
		gapi.auth2.getAuthInstance().signOut();
		setState([INITIAL_STATE]);
		setTemplate({docId: "", title: ""});
	};

	const handleGenerate = async (e) => {
		e.preventDefault();
		if (!template.docId) {
			notification("Opps", "Please select a template first", "warning");
			return;
		}
		setLoading(true);
		const newDocId = await copyDoc(template.docId, documentName);
		await updateText(state, newDocId);
		const url = `https://docs.google.com/document/d/${newDocId}`;
		setUrl(url);
		setLoading(false);
	};

	const addNewTemplate = async () => {
		const docId = await openDialog("Google Doc Id", "Please enter the Google Doc Id", "Google Doc Id", "");
		if (!docId) {
			return;
		}
		setLoading(true);
		const title = await getGoogleDoc(docId);
		if (!title) {
			setLoading(false);
			return;
		}
		const template = {docId, title};
		const newTemplate = await saveTemplate(template, googleUser, state);
		setTemplate(newTemplate);
		setLoading(false);
	};

	const handleAddTemplate = async () => {
		const templates = await getTemplates(googleUser);
		const response = await openGlobalList(templates, "Choose a Template", "title", "description", true, true);
		if (!response) {
			return;
		}
		if (response === "addAccount") {
			await addNewTemplate();
		} else {
			console.log(response);
			setLoading(true);
			const title = await getGoogleDoc(response.docId);
			setLoading(false);
			setTemplate({...response, title: title});
			if (response.state) {
				setState(response.state);
				console.log("setting state...");
			} else {
				console.log("NO state...");
				setState([INITIAL_STATE]);
			}
		}
	};

	const handleAdd = (i) => {
		const newState = [...state];
		newState.splice(i + 1, 0, INITIAL_STATE);
		setState(newState);
	};

	const handleRemove = (i) => {
		const newState = [...state];
		newState.splice(i, 1);
		setState(newState);
	};

	const handleSave = async () => {
		await saveTemplate(template, googleUser, state);
	};

	const handleDelete = async () => {
		const response = await openDialog("Confirm delete", "Type 'yes' to confirm delete", "yes", "");
		if (response.toLowerCase() === "yes") {
			await deleteTemplate(template);
			setTemplate({docId: "", title: ""});
			setState([INITIAL_STATE]);
		}
	};

	const handleClickDetails = () => {
		if (parcel) {
			let state = [
				{label: "{{owner_s_name_1}}", displayName: owners[0] ? owners[0].name : ""},
				{label: "{{owner_s_name_2}}", displayName: owners[1] ? owners[1].name : ""},
				{label: "{{owner_s_name_3}}", displayName: owners[2] ? owners[2].name : ""},
				{label: "{{owner_s_name_4}}", displayName: owners[3] ? owners[3].name : ""},
				{label: "{{email_address_1}}", displayName: owners[0] ? owners[0].email : ""},
				{label: "{{email_address_2}}", displayName: owners[1] ? owners[1].email : ""},
				{label: "{{email_address_3}}", displayName: owners[2] ? owners[2].email : ""},
				{label: "{{email_address_4}}", displayName: owners[3] ? owners[3].email : ""},
				{label: "{{nric_no_1}}", displayName: owners[0] ? owners[0].icNumber : ""},
				{label: "{{nric_no_2}}", displayName: owners[1] ? owners[1].icNumber : ""},
				{label: "{{nric_no_3}}", displayName: owners[2] ? owners[2].icNumber : ""},
				{label: "{{nric_no_4}}", displayName: owners[3] ? owners[3].icNumber : ""},
				{label: "{{postal_address}}", displayName: owners[0] ? owners[0].address : ""},
				{label: "{{parcel_no}}", displayName: parcel.parcelNumber},
				{label: "{{nationality_1}}", displayName: owners[0] ? owners[0].nationality : ""},
				{label: "{{nationality_2}}", displayName: owners[1] ? owners[1].nationality : ""},
				{label: "{{nationality_3}}", displayName: owners[2] ? owners[2].nationality : ""},
				{label: "{{nationality_4}}", displayName: owners[3] ? owners[3].nationality : ""},
				{label: "{{contact_no_1}}", displayName: owners[0] ? owners[0].phone : ""},
				{label: "{{contact_no_2}}", displayName: owners[1] ? owners[1].phone : ""},
				{label: "{{contact_no_3}}", displayName: owners[2] ? owners[2].phone : ""},
				{label: "{{contact_no_4}}", displayName: owners[3] ? owners[3].phone : ""},
				{label: "{{company_name}}", displayName: ""},
				{label: "{{company_registration_no}}", displayName: ""},
				{label: "{{country}}", displayName: ""},
				{label: "{{business_address}}", displayName: ""},
				{label: "{{person_in_charge_name}}", displayName: ""},
				{label: "{{email_address}}", displayName: ""},
				{label: "{{contact_no}}", displayName: ""},
				{label: "{{date_of_agreement}}", displayName: moment(new Date()).format("DD-MM-YYYY")},
			];
			setState(state);
		}
		if (client) {
			let state = [
				{label: "{{date}}", displayName: moment(new Date()).format("YYYY-MM-DD")},
				{label: "{{companyName}}", displayName: client.name},
				{label: "{{address}}", displayName: client.address},
				{label: "{{country}}", displayName: client.country},
				{label: "{{email}}", displayName: client.email},
				{label: "{{companyNumber}}", displayName: client.phone},
				{label: "{{name}}", displayName: contacts[0].name},
				{label: "{{designation}}", displayName: contacts[0].designation},
				{label: "{{phone}}", displayName: contacts[0].phone},
				{label: "{{contactEmail}}", displayName: contacts[0].email},
			];
			setState(state);
		}
	};

	const handleSaveToParcel = () => {
		if (!url) {
			notification("Opps", "Google Doc has not been generated", "warning");
			return;
		}
		if (parcel) {
			handleClose({
				name: `Google Doc: ${parcel.parcelNumber}`,
				downloadURL: url,
			});
		}
		if (client) {
			handleClose({
				name: `Google Doc: ${client.name}`,
				downloadURL: url,
			});
		}
	};

	return (
		<Box m={2}>
			{loading && <LinearProgress />}
			<Grid container justify="center">
				<Grid item xs={12} align="center">
					{!googleAuth ? (
						<Box m={2}>
							<Button variant="contained" color="primary" onClick={handleClickGoogle}>
								Connect Google
							</Button>
						</Box>
					) : (
						<Box m={2} display="flex" alignItems="center" justifyContent="center">
							<Button variant="contained" color="secondary" onClick={handleClickSignOut}>
								Sign out Google
							</Button>
							<Typography style={{paddingLeft: "10px"}} variant="body2">
								Google Account: {googleUser}
							</Typography>
						</Box>
					)}
				</Grid>
				<Grid container item xs={12} md={8} align="center" alignItems="center" spacing={1}>
					<Grid item xs={6} md={8}>
						<TextField
							variant="outlined"
							fullWidth
							label="Doc Template"
							name="docTitle"
							value={template.title}
							disabled
							InputLabelProps={{shrink: true}}
						/>
					</Grid>
					<Grid item xs={6} md={4}>
						<Button
							variant="contained"
							color="primary"
							size="small"
							startIcon={<SaveIcon />}
							onClick={handleAddTemplate}
							disabled={!googleAuth}>
							Choose Template
						</Button>
					</Grid>
					<Grid item xs={12} md={8} align="left">
						{template.id && (
							<Box my={1}>
								<Typography variant="subtitle2">
									<a href={`https://docs.google.com/document/d/${template.docId}`} target="blank">
										id: {template.docId}
									</a>
								</Typography>
							</Box>
						)}
					</Grid>
					{parcel && (
						<React.Fragment>
							<Grid item xs={12} align="left">
								<Box display="flex" alignItems="center">
									<Icon style={{color: "#616161"}}>home</Icon>
									<Typography variant="body1" style={{marginRight: "10px"}} color="textSecondary">
										Parcel Details:
									</Typography>
									<Typography variant="body1" style={{marginRight: "10px"}} color="textSecondary">
										{parcel.project.name}
									</Typography>
									<Typography variant="body1" color="textSecondary">
										{parcel.parcelNumber}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12} align="left">
								<Box my={1}>
									<Button variant="contained" color="primary" size="small" onClick={handleClickDetails}>
										Fill in Parcel Details
									</Button>
								</Box>
							</Grid>
						</React.Fragment>
					)}
					{client && (
						<React.Fragment>
							<Grid item xs={12} align="left">
								<Box display="flex" alignItems="center">
									<Icon style={{color: "#616161"}}>home</Icon>
									<Typography variant="body1" style={{marginRight: "10px"}} color="textSecondary">
										Client Details:
									</Typography>
									<Typography variant="body1" style={{marginRight: "10px"}} color="textSecondary">
										{client.name}
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12} align="left">
								<Box my={1}>
									<Button variant="contained" color="primary" size="small" onClick={handleClickDetails}>
										Fill in Client Details
									</Button>
								</Box>
							</Grid>
						</React.Fragment>
					)}
					<Grid item xs={12}>
						<Box my={2}>
							<Divider color="grey" />
						</Box>
					</Grid>
					<Grid item container xs={12} spacing={1}>
						<form onSubmit={handleGenerate} style={{width: "100%"}}>
							{state.map((doc, i) => (
								<Grid item container xs={12} alignItems="center" spacing={1} key={i}>
									<Grid item xs={5}>
										<TextField
											variant="outlined"
											fullWidth
											label="label"
											name="label"
											value={state[i].label}
											onChange={(e) => handleChange(e, i)}
											required
											InputLabelProps={{shrink: true}}
										/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											variant="outlined"
											fullWidth
											label="Name"
											name="displayName"
											value={state[i].displayName}
											onChange={(e) => handleChange(e, i)}
											InputLabelProps={{shrink: true}}
										/>
									</Grid>
									<Grid item xs={2}>
										<Box display="flex" alignItems="center" justifyContent="center">
											<Icon
												onClick={() => {
													handleAdd(i);
												}}>
												add
											</Icon>
											{i !== 0 && (
												<Icon
													onClick={() => {
														handleRemove(i);
													}}>
													remove
												</Icon>
											)}
										</Box>
									</Grid>
								</Grid>
							))}

							<Grid item xs={12} align="left">
								<Box m={1} display="flex">
									<Button variant="contained" color="primary" disabled={!googleAuth || loading} type="submit" size="small">
										Generate
									</Button>
									<Button
										variant="contained"
										color="secondary"
										disabled={!googleAuth || loading}
										style={{marginLeft: "5px"}}
										onClick={handleSave}
										size="small">
										Update Template
									</Button>
									<Button
										variant="contained"
										color="secondary"
										disabled={!googleAuth || loading}
										style={{marginLeft: "5px"}}
										onClick={addNewTemplate}
										size="small">
										Save as new
									</Button>
								</Box>
							</Grid>
						</form>
					</Grid>
					<Grid item xs={12}>
						{url && (
							<a href={url} target="blank">
								Google Doc Link
							</a>
						)}
					</Grid>
					{parcel && (
						<Grid item xs={12}>
							<Button variant="contained" color="primary" onClick={handleSaveToParcel} disabled={loading}>
								Save to Parcel Card
							</Button>
						</Grid>
					)}
					{client && (
						<Grid item xs={12}>
							<Button variant="contained" color="primary" onClick={handleSaveToParcel} disabled={loading}>
								Save to Parcel Card
							</Button>
						</Grid>
					)}
					<Grid item xs={12} align="left">
						{template.id && (
							<IconButton size="small" color="secondary" onClick={handleDelete}>
								Delete Template
							</IconButton>
						)}
					</Grid>
					<Grid item xs={12} align="center">
						{loading && <CircularProgress />}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
