import { Badge, Box } from "@material-ui/core";
import {Icon, IconButton, Tabs, Tab} from "@material-ui/core";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {blue, red} from "@material-ui/core/colors";
import { arrayIsEmpty } from "functions/arrayIsEmpty";
import {convertDate} from "functions/convertDate";
import moment from "moment";
import React from "react";
import { useState } from "react";

export default function Todos({todos, handleClickDone}) {
	const [tab, setTab] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
	};

	let filteredTodos = todos;
	const completedTodos = todos.filter((todo) => todo.completed);
	const mainTodos = todos.filter((todo) => !todo.completed);
	if (tab === 0) {
		filteredTodos = mainTodos;
	}
	if (tab === 1) {
		filteredTodos = completedTodos;
	}

	return (
		<React.Fragment>
			<Tabs
				value={tab}
				onChange={handleChangeTab}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
			>
				<Tab
					label={
						<Badge badgeContent={mainTodos && mainTodos.length} max={999} color="primary">
							To Dos
						</Badge>
					}
				/>
				<Tab
					label={
						<Badge badgeContent={completedTodos && completedTodos.length} max={999} color="primary">
							Completed
						</Badge>
					}
				/>
			</Tabs>
			<Box>
				{!arrayIsEmpty(filteredTodos) &&
					filteredTodos.map((todo, i) => (
						<Card style={{margin: "5px"}} key={i}>
							<CardContent>
								<Grid container>
									<Grid item xs={5}>
										<Typography variant="body2" color="textSecondary">
											Date:
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="body2" color="textSecondary">
											{moment(convertDate(todo.created)).format("YYYY-MM-DD")}
										</Typography>
									</Grid>
									<Grid item xs={1}>
										<IconButton
											size="small"
											onClick={() => handleClickDone(todo)}
											style={{position: "absolute", padding: "0px"}}
										>
											<Icon color="primary">
												{todo.completed ? "check_box" : "check_box_outline_blank"}
											</Icon>
										</IconButton>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2" color="textSecondary">
											Item:
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="body2" color="textSecondary">
											{todo.data}
										</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography variant="body2" color="textSecondary">
											Description:
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography variant="body2" color="textSecondary">
											{todo.description}
										</Typography>
									</Grid>
									<Grid item xs={5}>
										<Typography
											variant="body2"
											style={{
												color:
													new Date() > convertDate(todo.completedBy) ? red[500] : blue[700],
											}}
										>
											Completed by:
										</Typography>
									</Grid>
									<Grid item xs={7}>
										<Typography
											variant="body2"
											style={{
												color:
													new Date() > convertDate(todo.completedBy) ? red[500] : blue[500],
											}}
										>
											{moment(convertDate(todo.completedBy)).format("YYYY-MM-DD")}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					))}
			</Box>
		</React.Fragment>
	);
}
