import { firestore } from "firebase/firebase-utils";
import subscriptions_backup from "../test-functions/backup/subscriptions_backup.json";

export async function importSubscriptions() {
  try {
    subscriptions_backup.forEach(async (sub) => {
      await firestore.collection("subscriptions").doc(sub.id).set(sub);
      console.log(sub.id + " uploaded to Firestore");
    });
  } catch (error) {
    console.log(error);
  }
}
