import { firestore } from "firebase/firebase-utils";
import { notification } from "./notification";

export async function deleteTemplate(template) {
  try {
    await firestore.collection("templates").doc(template.id).delete();
    notification("Success", "Template deleted", "info");
  } catch (error) {
    console.log(error);
  }
}
