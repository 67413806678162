import firebase from "../firebase/firebase-utils";

export async function convertLeads() {
  try {
    const collectionRef = firebase.firestore().collection("leads");
    const snapShot = await collectionRef.get();
    const leads = snapShot.docs.map((doc) => doc.data());

    leads.forEach(async (lead, i) => {
      if (lead.id !== "388269409246447" && lead.id !== "531470518014409") {
        const { value, ...others } = lead;
        console.log(lead.created_time);
        console.log(new Date(lead.created_time));
        await collectionRef.doc(lead.id).set({ ...others, ...value });
        console.log(i + ". Converted");
      }
    });
  } catch (err) {
    console.log(err);
  }
}
