import {firestore} from "firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export function getTodos(user, setTodos) {
	try {
		return firestore
			.collection("todos")
			.where("assignTo", "==", user.email)
			.orderBy("completedBy", "asc")
			.onSnapshot((snapshot) => {
				const todos = mapSnapshot(snapshot);
				setTodos(todos);
			});
	} catch (error) {
		console.log(error);
	}
}
