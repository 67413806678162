import { firestore, storage } from "../firebase/firebase-utils";

export async function deleteAction(collection, action, docId) {
    try {
        console.log(action);
        console.log(collection);
        console.log(docId);
        const storageRef = storage.ref();
        const actionRef = firestore.collection(collection).doc(docId).collection("actions").doc(action.id);
        await actionRef.delete();

        //delete files from storage
        // if (action.files) {
        // 	action.files.forEach(async (file) => {
        // 		storageRef.child(file.folderRef).listAll().then((result) => {
        // 			result.items.forEach((imageRef) => {
        // 				imageRef.delete().then(() => {
        // 					console.log(imageRef.name + " is deleted");
        // 				});
        // 			});
        // 		});
        // 	});
        // }

        // Get the lastAction
        const snapshot = await firestore
            .collection(collection)
            .doc(docId)
            .collection("actions")
            .orderBy("created", "desc")
            .get();
        let lastAction = null;
        if (!snapshot.empty) {
            lastAction = { ...snapshot.docs[0].data(), id: snapshot.docs[0].id };
        }
        await firestore.collection(collection).doc(docId).update({ lastAction: lastAction });
        console.log(action.id + " action deleted from Firestore");
        console.log("Updated last action");
        console.log(lastAction);
    } catch (err) {
        console.log(err);
    }
}
