import { firestore } from "../firebase/firebase-utils";
import backupPages from "../test-functions/backup/pages_backup.json"

export async function importPages() {
  
  const collectionRef = firestore.collection("pages");
  console.log(backupPages);
  try {
    backupPages.forEach(async (page) => {
      await collectionRef.doc(page.id).set(page);
      console.log(page.id + " imported into Firestore");
    });
  } catch (err) {
    console.log(err);
  }
}
