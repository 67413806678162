import firebase from "../firebase/firebase-utils";

export function signOut() {
  try {
    // Sign out from Firebase
    firebase.auth().signOut();

    // Clear local storage "user" object
    localStorage.removeItem("user");
    localStorage.removeItem("authUser");
  } catch (err) {
    console.log(err.message);
  }
}
