import { auth } from "../firebase/firebase-utils";
import { notification } from "./notification";

export const forgetPassword = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email.trim());
    notification(
      "Reset password link has been sent",
      "Please check your email at " + email,
      "info"
    );
  } catch (err) {
    console.log(err.message);
    notification("Opps", err.message, "danger");
  }
};
