import exportFromJSON from "export-from-json";
import { firestore } from "firebase/firebase-utils";

export async function exportUsers() {
  try {
    const signUpSnapshot = await firestore.collection("leadsAppSignUps").get();
    const signUps = signUpSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const userEmails = signUps.map((signUp) => signUp.email);
    const additionalEmails = [
      "charles951121@gmail.com",
      "kohpohfen@gmail.com",
      "jasonmch02@gmail.com",
      "debbieleow.dl@gmail.com",
      "aya_raine@yahoo.com.au",
      "daveckw@gmail.com",
    ];
    const emails = userEmails.concat(additionalEmails);

    const users = await Promise.all(
      emails.map(async (email) => {
        const userSnapshot = await firestore
          .collection("users")
          .where("email", "==", email)
          .get();
        const user = { ...userSnapshot.docs[0].data(), id: userSnapshot.docs[0].id };
        return user;
      })
    );

    console.log(users);

    //Export as file
    const data = users;
    const fileName = "users_backup";
    const exportType = "json";
    exportFromJSON({ data, fileName, exportType });
  } catch (error) {
    console.log(error);
  }
}
