import axios from "axios";
import { firestore } from "../firebase/firebase-utils";

export async function populateLeadsData() {
  const collectionRef = firestore.collection("rawLeads");
  const snapShot = await collectionRef.get();
  const rawLeads = snapShot.docs.map((doc) => doc.data());

  rawLeads.forEach(async (rawLead) => {
    const value = rawLead.entry[0].changes[0].value;
    const leadId = value.leadgen_id;
    const campaignId = value.adgroup_id;
    const formId = value.form_id;

    // Get page data
    const querySnapshot = await firestore
      .collection("pages")
      .where("id", "==", value.page_id)
      .get();
    const longLivedPageToken = querySnapshot.docs[0].data().accessToken;
    const pageName = querySnapshot.docs[0].data().name;
    const uri = `https://graph.facebook.com/v10.0/${leadId}/?access_token=${longLivedPageToken}`;
    const response = await axios.get(uri);
    console.log(response.data);

    // Get Ad Campaign Name
    let campaignName = "";
    try {
      if (campaignId) {
        const urlCampaign = `https://graph.facebook.com/v10.0/${campaignId}/?&fields=name&access_token=${longLivedPageToken}`;
        const response2 = await axios(urlCampaign);
        campaignName = response2.data.name;
      }
    } catch (err) {
      console.log(err.message);
    }

    // Get Ad Form Name
    let formName = "";
    try {
      const urlForm = `https://graph.facebook.com/v10.0/${formId}/?&fields=name&access_token=${longLivedPageToken}`;
      const response3 = await axios(urlForm);
      console.log(response3);
      formName = response3.data.name;
    } catch (err) {
      console.log(err.message);
    }

    // Get existing lead data
    const leadRef = firestore.collection("leads").doc("leadId")
    const leadSnapshot = await leadRef.get();
    const lead = leadSnapshot.data();

    // Write to leads Firestore database
    await firestore
      .collection("leads")
      .doc(leadId)
      .set({
        ...response.data,
        ...value,
        source: "FB",
        pageName: pageName,
        campaignName: campaignName,
        formName: formName,
        ...lead,
      });
    console.log(leadId + " added into Firestore");
  });
}
