import {firestore} from "../firebase/firebase-utils";
import {mapSnapshot} from "./mapSnapshot";

export function loadClientsOnSnapshot(setClients, setLoading) {
	try {
		setLoading(true);
		return firestore.collection("clients").onSnapshot((snapshot) => {
			if (!snapshot.empty) {
				const clients = mapSnapshot(snapshot);
				setClients(clients);
				setLoading(false);
			}
		});
	} catch (error) {
		console.log(error);
		setLoading(false);
	}
}
