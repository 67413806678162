import React, {useContext} from "react";
import {Grid, Typography, Box, Card, IconButton, Icon} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {AuthContext} from "../context/auth-context";
import {Divider} from "@material-ui/core";
import {useState} from "react";
import {useEffect} from "react";
import {getTodos} from "functions/getTodos";
import {saveTodoDone} from "functions/saveTodoDone";
import Todos from "components/Todos/Todos";

const Homepage = ({history}) => {
	const {user} = useContext(AuthContext);
	const [todos, setTodos] = useState([]);

	useEffect(() => {
		const unsubscribe = getTodos(user, setTodos);
		return unsubscribe;
	}, []);

	const handleClickDone = async (todo) => {
		await saveTodoDone(todo);
	};

	return (
		<Box m={1}>
			<Card>
				<Box m={1} justifyContent="center">
					<Grid container spacing={2} justify="center">
						<Grid item xs={12} md={7} align="center">
							<Box m={2} display="flex" alignItems="center">
								<Typography variant="h5">Welcome {user.displayName}</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} md={7} align="center">
							<Box m={1}>
								<Divider />
							</Box>
						</Grid>
						<Grid item xs={12} md={7} align="left">
							<IconButton onClick={() => history.push("/business-development")} size="small">
								<Icon style={{paddingRight: "10px"}}>cases</Icon>
								<Typography variant="h6" align="center" color="textSecondary">
									Business Development Module
								</Typography>
							</IconButton>
						</Grid>
						<Grid item xs={12} md={7} align="left">
							<IconButton onClick={() => history.push("/sale-page")} size="small">
								<Icon style={{paddingRight: "10px"}}>storefront</Icon>
								<Typography variant="h6" align="center" color="textSecondary">
									Sales Module
								</Typography>
							</IconButton>
						</Grid>
						<Grid item xs={12} md={7} align="center">
							<Box m={1}>
								<Divider />
							</Box>
						</Grid>
						<Grid item xs={12} md={7} align="left">
							<Todos todos={todos} handleClickDone={handleClickDone} />
						</Grid>
					</Grid>
				</Box>
			</Card>
		</Box>
	);
};

export default withRouter(Homepage);
