import exportFromJSON from "export-from-json";
import { firestore } from "firebase/firebase-utils";

export async function exportSubscriptions() {
  try {
    const snapShot = await firestore.collection("subscriptions").get();
    const subscriptions = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    //Export as file
    const data = subscriptions;
    const fileName = "subscriptions_backup";
    const exportType = "json";
    exportFromJSON({ data, fileName, exportType });
  } catch (error) {
    console.log(error);
  }
}
