import {firestore} from "firebase/firebase-utils";

export async function updateDocuments(newDocuments, collection, docId) {
	try {
		await firestore.collection(collection).doc(docId).update({documents: newDocuments});
		console.log("Documents CheckList updated!");
	} catch (error) {
		console.log(error);
	}
}
