import { firestore } from "../firebase/firebase-utils";
import { convertDate } from "../functions/convertDate";

export async function exportRawLeads() {
  try {
    const snapshot = await firestore.collection("rawLeads").get();
    const rawLeads = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      time: convertDate(doc.data().entry[0].time),
    }));
    console.log(rawLeads);
    let filteredRawLeads = rawLeads.filter((lead) => {
      if (lead.time > new Date("2021-06-06T11:40:00+0800")) {
        return true;
      } else {
        return false;
      }
    });
    filteredRawLeads = filteredRawLeads.filter((lead) => {
      if (lead.entry[0].changes[0].value.page_id === "105403371520319") {
        return true;
      } else {
        return false
      }
    });
    console.log(filteredRawLeads);
  } catch (error) {
    console.log(error);
  }
}
