import {Button, Grid, TextField} from "@material-ui/core";
import {Box} from "@material-ui/core";
import {AuthContext} from "context/auth-context";
import {ListContext} from "context/list-context";
import {loadUsers} from "functions/loadUsers";
import {saveTodo} from "functions/saveTodo";
import {useContext} from "react";
import {useState} from "react";

export function AddTodo({handleClose, data}) {
	const [state, setState] = useState({date: "", description: ""});
	const [assignTo, setAssignTo] = useState();
	const {openGlobalList} = useContext(ListContext);
	const {user} = useContext(AuthContext);

	const handleChange = (e) => {
		const {name, value} = e.target;
		setState({...state, [name]: value});
	};

	const handleClickAssign = async () => {
		const users = await loadUsers();
		const user = await openGlobalList(users, "Select User", "displayName", "face", false, true);
		setAssignTo(user);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const todo = {
			created: new Date(),
            data: data,
			user: {id: user.id, name: user.displayName},
			completedBy: new Date(state.date),
			assignTo: assignTo ? assignTo.id : user.id,
			description: state.description,
			completed: false,
		};
		setState({date: "", description: ""});
		setAssignTo();
		await saveTodo(todo);
		console.log(todo);
		handleClose();
	};

	return (
		<Box m={3}>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							name="data"
							label="Item"
							fullWidth
							value={data}
							InputLabelProps={{shrink: true}}
                            disabled
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="date"
							variant="outlined"
							name="date"
							label="date"
							fullWidth
							value={state.date}
							onChange={handleChange}
							InputLabelProps={{shrink: true}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							name="description"
							label="Description"
							fullWidth
							value={state.description}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							name="assignTo"
							label="Assign To"
							fullWidth
							value={assignTo && assignTo.displayName}
							disabled
							onClick={handleClickAssign}
							InputLabelProps={{shrink: true}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" type="submit">
							Add Todo
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
}
