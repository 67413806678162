import {firestore} from "firebase/firebase-utils";
import {notification} from "./notification";

export async function saveOwner(state, user) {
	try {
		const owner = {
			...state,
			created: new Date(),
			createdBy: user.email,
		};
		if (!state.id) {
			await firestore.collection("owners").add(owner);
			console.log("Owner added into Firestore");
			console.log(owner);
			notification("Yes", "You have successfully created an owner", "success");
		} else {
			await firestore.collection("owners").doc(state.id).set({...state});
			notification("Yes", "You have successfully updated the owner", "success");
		}
	} catch (error) {
		console.log(error);
	}
}
