import { Grid, Icon, IconButton, makeStyles, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Box, Typography, Paper } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useState } from "react";
import { useContext } from "react";
import { ListContext } from "../context/list-context";
import { InputContext } from "../context/input-context";
import { AuthContext } from "../context/auth-context";
import { notification } from "../functions/notification";
import { loadProjects } from "../functions/loadProjects";
import { createProject } from "../functions/createProject";
import { saveParcel } from "../functions/saveParcel";
import { loadPackages } from "../functions/loadPackages";
import { savePackage } from "../functions/savePackage";
import { getOwners } from "../functions/getOwners";
import ManualOwner from "./manual-owner";
import FullScreenDialog from "../components/full-dialog";
import { useEffect } from "react";
import React from "react";
import { updateProject } from "functions/updateProject";
import { loadThemes } from "functions/loadThemes";
import { saveTheme } from "functions/saveTheme";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(1),
    },
}));

const INITIAL_VALUE = {
    parcelNumber: "",
    type: "",
    spaPrice: "",
    netPrice: "",
    size: "",
};

export default function ParcelCreate({ parcel, owners_, handleClose }) {
    const classes = useStyles();

    const [state, setState] = useState(INITIAL_VALUE);
    const [project, setProject] = useState({ id: "", name: "" });
    const [package_, setPackage] = useState({ id: "", name: "" });
    const [parcelStatus, setParcelStatus] = useState("");
    const [owners, setOwners] = useState([]);
    const { user } = useContext(AuthContext);
    const { openGlobalList } = useContext(ListContext);
    const { openDialog } = useContext(InputContext);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [theme, setTheme] = useState();
    const [primaryOwner, setPrimaryOwner] = useState();

    useEffect(() => {
        if (parcel) {
            setEdit(true);
            const {
                created,
                parcelNumber,
                type,
                spaPrice,
                netPrice,
                parcelStatus,
                size,
                project,
                theme,
                primaryOwner,
            } = parcel;
            setState({ created, parcelNumber, type, spaPrice, netPrice, size });
            setProject(project);
            setPackage(parcel.package);
            setParcelStatus(parcelStatus);
            setOwners(owners_);
            setTheme(theme);
            setPrimaryOwner(primaryOwner && primaryOwner);
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!primaryOwner) {
            notification("Opps", "Please select the primary owner by clicking", "info");
            return;
        }
        await saveParcel(
            project,
            state,
            primaryOwner,
            package_,
            parcelStatus,
            theme,
            owners,
            user,
            edit,
            parcel && parcel.id
        );
        setState(INITIAL_VALUE);
        setOwners([]);
        setProject({ id: "", name: "" });
        setPackage({ id: "", name: "" });
        setParcelStatus("");
        setPrimaryOwner();
        if (edit) {
            handleClose();
        }
    };

    const addNewProject = async () => {
        const projectName = await openDialog("Add new project", "Please enter the project name", "Project name", "");
        if (!projectName) {
            return;
        }
        const project = await createProject(projectName);
        console.log(project);
        setProject(project);
    };

    const hanldeClickProject = async () => {
        const projects = await loadProjects();
        const response = await openGlobalList(projects, "Select Project", "name", "location_city", true, true);
        if (!response) {
            return;
        }
        if (response === "addAccount") {
            await addNewProject();
        } else {
            setProject(response);
        }
    };

    const addPackage = async () => {
        const name = await openDialog("Add Package", "Please enter the name of the package", "package name", "");
        if (!name) {
            return;
        }
        const response = await savePackage(name, project, user);
        setPackage({ id: response.id, name: name });
    };

    const handleClickPackage = async () => {
        if (!project.id) {
            notification("Opps", "Please select a project first", "warning");
            return;
        }
        const packages = await loadPackages(project.id);
        const package_ = await openGlobalList(packages, "Select Package", "name", "workspaces", true, true);
        if (package_ === "addAccount") {
            await addPackage();
        } else {
            if (package_) {
                setPackage({ id: package_.id, name: package_.name });
                console.log(package_);
            }
        }
    };

    const handleClickParcel = async () => {
        const status = [{ name: "Bare unit" }, { name: "Partially furnised" }, { name: "Fully furnished" }];
        const parcelStatus = await openGlobalList(status, "Select Parcel Status", "name", "single_bed");
        if (!parcelStatus) {
            return;
        } else {
            setParcelStatus(parcelStatus.name);
        }
    };

    const addOwner = async () => {
        setOpen(true);
    };

    const handleClickAddOwner = async () => {
        const owners_ = await getOwners();
        const owner = await openGlobalList(owners_, "Add owner", "name", "contact_page", true, true);
        if (owner === "addAccount") {
            await addOwner();
        } else {
            if (!owner) {
                return;
            }
            setOwners([...owners, owner]);
        }
    };

    const handleClickDeleteOwner = (owner) => {
        const newOwners = owners.filter((doc) => doc.id !== owner.id);
        setOwners(newOwners);
        if (primaryOwner) {
            const found = newOwners.find((doc) => doc.id === primaryOwner.id);
            if (found) {
                return;
            } else {
                setPrimaryOwner();
            }
        }
    };

    const handleClickEditProject = async () => {
        const projectName = await openDialog(
            "Edit project name",
            "Please enter a new project name",
            "Project name",
            ""
        );
        if (projectName) {
            await updateProject(project.id, projectName);
            setProject({ id: project.id, name: projectName });
        }
    };

    const addTheme = async () => {
        const name = await openDialog("Add Theme", "Please enter the name of the theme", "theme name", "");
        if (!name) {
            return;
        }
        const response = await saveTheme(name, project, user);
        setTheme({ id: response.id, name: name });
    };

    const handleClickTheme = async () => {
        const themes = await loadThemes();
        const theme = await openGlobalList(themes, "Select theme", "name", "single_bed", true, true);
        if (!theme) {
            return;
        }
        if (theme === "addAccount") {
            await addTheme();
            return;
        }
        setTheme(theme);
    };

    return (
        <Box m={1}>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} md={7}>
                    <Grid item container xs={12}>
                        <Grid item xs={12} align="center">
                            <Box m={1}>
                                <Typography variant="h5">{parcel ? "Update Parcel" : "Create New Parcel"}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Paper className={classes.paper}>
                        <form onSubmit={handleSubmit}>
                            <Grid item container xs={12}>
                                <Grid item xs={11}>
                                    <Box m={1}>
                                        <Button variant="outlined" fullWidth onClick={hanldeClickProject}>
                                            {project.name || "Select Project"}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box m={1}>
                                        <IconButton onClick={handleClickEditProject} disabled={Boolean(!project)}>
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Parcel number"
                                            name="parcelNumber"
                                            value={state && state.parcelNumber}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Type"
                                            name="type"
                                            value={state && state.type}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="SPA Price"
                                            name="spaPrice"
                                            value={state && state.spaPrice}
                                            onChange={handleChange}
                                            required
                                            type="number"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Net Price"
                                            name="netPrice"
                                            value={state && state.netPrice}
                                            onChange={handleChange}
                                            required
                                            type="number"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Size"
                                            name="size"
                                            value={state && state.size}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={handleClickPackage}
                                            value={package_.name}
                                        >
                                            {package_.name || "Select Package"}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <Button variant="outlined" fullWidth onClick={handleClickParcel}>
                                            {parcelStatus || "Select Parcel Status"}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <Button variant="outlined" fullWidth onClick={handleClickTheme}>
                                            {theme?.name || "Select Theme"}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1} display="flex" alignItems="center">
                                        {owners.map((owner, i) => (
                                            <Box display="flex" alignItems="center">
                                                <Typography
                                                    variant="body1"
                                                    key={i}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => setPrimaryOwner(owner)}
                                                >
                                                    {owner.name}
                                                </Typography>
                                                <IconButton size="small" onClick={() => handleClickDeleteOwner(owner)}>
                                                    <Icon fontSize="small">clear</Icon>
                                                </IconButton>
                                            </Box>
                                        ))}
                                        <IconButton onClick={handleClickAddOwner}>
                                            <Icon>add</Icon>
                                            <Typography variant="body1">Owners</Typography>
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        {primaryOwner ? (
                                            <Typography variant="body2" color="textSecondary">
                                                Primary owner: {primaryOwner.name}
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                Please select a Primary Owner
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={1}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.button}
                                            startIcon={<SaveIcon />}
                                        >
                                            {edit ? "Update" : "Save"}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
            <FullScreenDialog
                open={open}
                handleClose={() => setOpen(false)}
                title="Add Owner"
                component={<ManualOwner />}
            />
        </Box>
    );
}
