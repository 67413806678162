import { firestore } from "firebase/firebase-utils";

export async function addNote(note, parcel) {
  try {
    const response = await firestore
      .collection("parcels")
      .doc(parcel.id)
      .collection("notes")
      .add(note);
    console.log("Note added into Firestore");
    return response.id;
  } catch (error) {
    console.log(error);
  }
}
