import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { blue, deepOrange, deepPurple } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  chip: {
    borderStyle: "solid",
    borderRadius: "5px",
    borderWidth: "1px",
    padding: "1px",
    marginLeft: "4px",
    marginRight: "4px",
  },
}));

export default function ChipDc({text, color="orange"}) {
  const classes = useStyles();

  return (
    <div style={{paddingLeft: "5px"}}>
      <Typography noWrap align="center" variant="subtitle2" className={`${classes.chip} ${classes[color]}`}>
        {text}
      </Typography>
    </div>
  );
}
