import { firestore } from "firebase/firebase-utils";
import { mapSnapshot } from "./mapSnapshot";

export async function getOwners() {
  try {
    const snapshot = await firestore.collection("owners").get();
    if (snapshot.empty) {
      return [];
    } else {
      const owners = mapSnapshot(snapshot);
      return owners;
    }
  } catch (error) {
    console.log(error);
  }
}
