import {firestore} from "firebase/firebase-utils";
import {convertPhone} from "functions/convertPhone";
import {getFirestoreData} from "functions/getFirestoreData";

export async function modifyNumbers() {
	try {
		const owners = await getFirestoreData("owners");
		const parcels = await getFirestoreData("parcels");
		parcels.forEach(async (parcel) => {
			let array = [];
			parcel.ownerIds.forEach((id) => {
				const found = owners.find((owner) => owner.id === id);
				if (found) {
					array.push(found);
				} else {
					console.log("Cannot find owner id: " + id);
				}
			});
			await firestore.collection("parcels").doc(parcel.id).update({owners: array});
            console.log("Parcels updated!")
		});
	} catch (error) {
		console.log(error);
	}
}
