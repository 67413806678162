import { firestore } from "firebase/firebase-utils";
import { mapSnapshot } from "./mapSnapshot";

export async function loadPackages(projectId) {
  try {
    const snapshot = await firestore
      .collection("packages")
      .where("projectId", "==", projectId)
      .get();
    if (snapshot.empty) {
      return [];
    } else {
      const packages = mapSnapshot(snapshot);
      console.log("Loaded packages");
      console.log(packages);
      return packages;
    }
  } catch (error) {
    console.log(error);
  }
}
