import { firestore } from "../firebase/firebase-utils";

export async function getWarriorNames(leads) {
  try {
    let allEmails = [];
    leads.forEach((lead) => {
      if (lead.warriors) {
        if (lead.warriors.length !== 0) {
          lead.warriors.forEach((warrior) => {
            allEmails.push(warrior);
          });
        }
      }
    });
    allEmails = allEmails.filter((c, index) => allEmails.indexOf(c) === index);

    const collectionRef = firestore.collection("users");
    const users = await Promise.all(
      allEmails.map(async (email) => {
        const querySnapshot = await collectionRef
          .where("email", "==", email)
          .get();
        if (querySnapshot.empty) {
          return { email: email, displayName: "NA" };
        }
        const user = querySnapshot.docs[0].data();
        return { email: user.email, displayName: user.displayName };
      })
    );

    const mappedLeads = leads.map((lead) => {
      if (!lead.warriors) {
        return { ...lead, assign: false };
      } else if (lead.warriors.length === 0) {
        return { ...lead, assign: false };
      }
      const assign = lead.warriors.map((warrior) => {
        return users.find((user) => {
          return user.email === warrior;
        });
      });
      const returnLead = {
        ...lead,
        assign: assign,
      };
      return returnLead;
    });
    return mappedLeads;
  } catch (error) {
    console.log(error);
  }
}
