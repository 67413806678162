import {firestore} from "firebase/firebase-utils";
import firebase from "firebase";
import {notification} from "./notification";

export async function saveTransaction(collection, docId, state, type, user) {
	try {
		let amount = state.amount;
		if (type === "invoice") {
			amount = amount * -1;
		}
		let transaction = {
			date: new Date(state.date),
			created: new Date(),
			createdBy: user.email,
			docId: docId,
			collection: collection,
			type: type,
			amount: amount,
			description: state.description,
		};
		const response = await firestore.collection("transactions").add(transaction);
		transaction = {...transaction, id: response.id};
		await firestore
			.collection(collection)
			.doc(docId)
			.update({transactions: firebase.firestore.FieldValue.arrayUnion(transaction)});
		console.log("Transaction is added!");
		console.log(transaction)
		notification("Success", "Added transaction", "success");
		return(transaction)
	} catch (error) {
		console.log(error);
	}
}
