import { firestore } from "../firebase/firebase-utils";

export async function savePackage(name, project, user) {
  try {
    const package_ = {
      name: name,
      projectId: project.id,
      created: new Date(),
      createdBy: user.email,
    };
    const response = await firestore.collection("packages").add(package_);
    return { ...package_, id: response.id };
  } catch (error) {
    console.log(error);
  }
}
