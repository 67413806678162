import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { gapi } from "gapi-script";

export const GoogleContext = createContext();

// Client ID and API key from the Developer Console
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const API_KEY = process.env.REACT_APP_API_KEY;

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  "https://docs.googleapis.com/$discovery/rest?version=v1",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES =
  "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/documents.readonly";

export const GoogleProvider = ({ children }) => {
  const [googleAuth, setGoogleAuth] = useState();
  const [googleUser, setGoogleUser] = useState();

  useEffect(() => {
    const fetchData = async () => {
      gapi.load("client:auth2", initClient);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Initializes the API client library and sets up sign-in state  listeners.
  const initClient = () => {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        () => {
          // Listen for sign-in state changes.
          const GoogleAuth = gapi.auth2.getAuthInstance();
          GoogleAuth.isSignedIn.listen(updateSigninStatus);
          updateSigninStatus(GoogleAuth.isSignedIn.get());
          console.log("gapi loaded");
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      const GoogleAuth = gapi.auth2.getAuthInstance();
      const GoogleUser = GoogleAuth.currentUser.get();
      setGoogleUser(GoogleUser.wt.cu);
      setGoogleAuth(true);
      console.log(gapi.auth2.getAuthInstance().currentUser);
    } else {
      setGoogleAuth(false);
      setGoogleUser(null);
    }
  };

  return (
    <GoogleContext.Provider value={{ googleAuth, setGoogleAuth, googleUser }}>
      {children}
    </GoogleContext.Provider>
  );
};

export default gapi;
