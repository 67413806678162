import {firestore} from "firebase/firebase-utils";
import {notification} from "./notification";

export async function saveParcel(
	project,
	state,
	primaryOwner = null,
	package_,
	parcelStatus,
	theme = null,
	owners,
	user,
	edit,
	id
) {
	if (!state.netPrice) {
		state.netPrice = 0;
	}
	const parcel = {
		...state,
		created: !edit ? new Date() : state.created,
		updated: new Date(),
		project: project,
		projectId: project.id,
		createdBy: user.email,
		package: package_,
		parcelStatus: parcelStatus,
		ownerIds: owners.map((owner) => owner.id),
		owners: owners,
		theme: theme,
		primaryOwner: primaryOwner ? primaryOwner : owners[0],
	};
	console.log(parcel);
	try {
		if (!edit) {
			//create new parcel
			const response = await firestore.collection("parcels").add(parcel);
			console.log("Created parcel in database: " + response.id);
			notification("Alright!", "New parcel created.", "success");
		} else {
			// update parcel
			console.log(parcel);
			await firestore.collection("parcels").doc(id).update(parcel);
			console.log("Parcel updated");
			notification("Yes!", "You have updated the parcel", "success");
		}
	} catch (error) {
		console.log(error);
	}
}
