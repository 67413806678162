import {firestore} from "firebase/firebase-utils";
import firebase from "firebase";

export async function saveLabel(collection, label, doc, user) {
	try {
		const object = {...label, userEmail: user.email};
		const leadRef = firestore.collection(collection).doc(doc.id);
		await leadRef.update({
			labels: firebase.firestore.FieldValue.arrayUnion(object),
		});
		console.log("Label updated into Firestore");
	} catch (error) {
		console.log(error);
	}
}
