export function mapParcelSnapshot(snapshot) {
	if (snapshot.empty) {
		return [];
	} else {
		return snapshot.docs.map((doc) => {
			let balance = 0;
			if (doc.data().transactions) {
				doc.data().transactions.forEach((transaction) => {
					balance = balance + parseFloat(transaction.amount);
				});
			}
			return {...doc.data(), id: doc.id, balance: balance};
		});
	}
}
