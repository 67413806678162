import exportFromJSON from "export-from-json";
import { firestore } from "../firebase/firebase-utils";

export async function exportLeads() {
  const collectionRef = firestore.collection("leads");

  const snapShot = await collectionRef.where("source","==", "FB").get();
  const leads = snapShot.docs.map(doc=>({...doc.data(), id: doc.id}));
  console.log(leads);
  const jsonLeads = JSON.stringify(leads);
  console.log(jsonLeads);

   //Export as file
   const data = leads;
   const fileName = "leads_backup";
   const exportType = "json";
   exportFromJSON({ data, fileName, exportType });
}
