import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { AuthContext } from "../context/auth-context";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router";
import BouncingBalls from "../components/bouncing-balls";
import { signOut } from "../functions/signOut";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://leads.djcsystem.com/">
        DJC Venture
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignOut = ({ history }) => {

  const {setUser} = useContext(AuthContext);

  useEffect(() => {
    signOut();
    setUser(null);
    const timer = setTimeout(() => {
      
      history.push("/");
    }, 1000);
   
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);
  
  return (
    <Container component="main" maxWidth="xs">
      <Box m={3}>
        <Grid container justify="center">
          <Grid item xs={5}>
            <Typography align="center">Loggin Out...</Typography>
          </Grid>
        </Grid>
      </Box>

      <BouncingBalls />
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withRouter(SignOut);
