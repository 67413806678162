import React, {useContext} from "react";
import {Grid, Typography, Box, makeStyles, LinearProgress} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {AuthContext} from "../context/auth-context";
import {useEffect} from "react";
import {useState} from "react";
import SalesFlexboxContainer from "../components/sales-flexbox/sales-flexbox.container";
import { loadClientsOnSnapshot } from "../functions/loadClientsOnSnapshot";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	control: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
	},
}));

const SalePage = ({history}) => {
	// eslint-disable-next-line
	const classes = useStyles();
	const {user} = useContext(AuthContext);
	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [number, setNumber] = useState(50);

	useEffect(
		() => {
			const unsubscribe1 = loadClientsOnSnapshot(setClients, setLoading);

			return () => {
				unsubscribe1();
			};
			// eslint-disable-next-line
		},
		[number]
	);

	return (
		<Box m={1}>
			{loading && <LinearProgress />}
			<Grid container spacing={1} justify="center">
				<Grid item xs={12} md={8} align="center">
					<Box m={2}>
						<Typography variant="h5">Welcome {user.displayName}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={8} align="center">
					<Grid container item>
						<SalesFlexboxContainer clients={clients} />
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default withRouter(SalePage);
