import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import { blue } from "@material-ui/core/colors";
import { InputContext } from "context/input-context";

const labels = [
  "Prospect",
  "Tentative",
  "Definite",
  "Confirmed",
  "Cancelled",
  "Terminated",
];

const labelColors = [
  "#fef23a",
  "#5eff17",
  "#1980A8",
  "#d5a65d",
  "#ff5151",
  "#ff5d5d",
];

const fontColors = [
  "#000000",
  "#000000",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#ffffff",
];

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function SelectLabel(props) {
  const classes = useStyles();
  const { onClose,  open } = props;
  const { openDialog } = useContext(InputContext);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleAddLabel = async () => {
    const labelName = await openDialog(
      "Enter Label",
      "Enter a lable name",
      "label",
      ""
    );
    console.log(labelName);
    const value = { label: labelName, color: "#5cfaff", fontColor: "#000000" };
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Select Label</DialogTitle>
      <List>
        {labels.map((label, i) => (
          <ListItem
            button
            onClick={() =>
              handleListItemClick({
                label: label,
                color: labelColors[i],
                fontColor: fontColors[i],
              })
            }
            key={label}
          >
            <ListItemAvatar>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: labelColors[i] }}
              >
                <PersonIcon style={{ color: labelColors[i] }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={label} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleAddLabel()}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add label" />
        </ListItem>
      </List>
    </Dialog>
  );
}
