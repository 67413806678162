import {firestore} from "firebase/firebase-utils";

export async function loadProject(id) {
	try {
		const doc = await firestore.collection("projects").doc(id).get();
		if (doc.exists) {
			const project = {...doc.data(), id: doc.id};
            return project
		} else {
			return null;
		}
	} catch (error) {
		console.log(error);
	}
}
