//Configure Material UI Theme
export function  themeConfig(theme) {
  theme.typography.h3 = {
    fontSize: "1.2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  };

  theme.typography.body1 = {
    fontSize: "0.95rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  };

  theme.typography.subtitle1 = {
    fontSize: "0.75rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.85rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.85rem",
    },
  };

  theme.typography.subtitle2 = {
    fontSize: "0.6rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.7rem",
    },
  };


}
