import { storage } from "../firebase/firebase-utils";
import firebase from "firebase";
import { notification } from "./notification";

export async function uploadFiles(file, fileRef, setProgress, i, length) {
    if (!file) {
        return null;
    }
    const storageRef = storage.ref();

    let ref = { resolve: () => null, reject: () => null };
    let downloadUrlPromise = new Promise((resolve, reject) => {
        ref = { resolve, reject };
    });

    let uploadTask = storageRef.child(fileRef).put(file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prev) => {
                if (!prev) {
                    const newProgress = Array(length).fill(0);
                    newProgress[i] = progress;
                    return newProgress;
                } else {
                    const newProgress = [...prev];
                    newProgress[i] = progress;
                    return newProgress;
                }
            });
        },
        (error) => {
            console.log(error);
            notification("Opps", error.message, "danger");
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                ref.resolve(downloadURL);
            });
        }
    );
    return await downloadUrlPromise;
}
