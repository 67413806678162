import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {blue, green, red} from "@material-ui/core/colors";
import {Box, Grid, Icon} from "@material-ui/core";
import {useEffect} from "react";
import {Divider} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LabelsList from "../../components/labels-list.component";
import {Badge} from "@material-ui/core";
import {whatsapp} from "../../functions/whatsapp";
import {callNumber} from "../../functions/callNumber";
import {addAction} from "../../functions/addAction";
import {useContext} from "react";
import {AuthContext} from "../../context/auth-context";

const useStyles = makeStyles((theme) => ({
	root: {},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	avatar: {
		backgroundColor: red[500],
	},
	name: {
		fontWeight: "700",
		paddingRight: "10px",
	},
	status: {
		paddingRight: "4px",
		paddingLeft: "4px",
		borderRadius: "6px",
		fontSize: "11px",
		color: theme.palette.getContrastText(blue[500]),
		backgroundColor: blue[500],
	},
	package: {
		paddingRight: "4px",
		paddingLeft: "4px",
		borderRadius: "6px",
		fontSize: "11px",
		color: theme.palette.getContrastText(red[400]),
		backgroundColor: red[400],
	},
	icon: {
		cursor: "pointer",
		marginLeft: "3px",
		color: green[500],
	},
}));

export default function SaleFlexbox({client, setOpen, setClient, setContacts_}) {
	const classes = useStyles();
	const [contacts, setContacts] = useState();
	const {user} = useContext(AuthContext);

	useEffect(
		() => {
			const fetchData = async () => {
				const contacts = client.contacts;
				setContacts(contacts);
			};
			fetchData();
			// eslint-disable-next-line
		},
		[client]
	);

	const handleClickLaunch = () => {
		setClient(client);
		setContacts(contacts);
		setOpen(true);
	};

	const handleClickAction = async (action, contact) => {
		switch (action) {
			case "Whatsapp":
				whatsapp(contact.phone, contact.name);
				break;
			case "Call":
				callNumber(contact.phone);
				break;

			default:
				console.log(action);
		}
		if (contact) {
			action = action + " " + contact.name;
		}
		await addAction("clients", client.id, action, "", user);
	};

	return (
		<React.Fragment>
			<Grid item xs={12} align="left">
				<LabelsList parcel={client} />
			</Grid>

			<Grid container item xs={12}>
				<Grid item xs={12} align="left">
					<Box fontWeight="fontWeightMedium" display="flex" alignItems="center">
						<Typography className={classes.name} noWrap color="textSecondary">
							{client.name}
						</Typography>

						<Icon
							style={{
								fontSize: "18px",
								color: "#818080",
								paddingLeft: "4px",
								cursor: "pointer",
							}}
							onClick={handleClickLaunch}>
							launch
						</Icon>
					</Box>
				</Grid>

				<Grid item xs={4} align="left">
					<Box display="flex" alignItems="center">
						<Icon fontSize="small" style={{color: "#458cad"}}>
							phone
						</Icon>{" "}
						<Typography variant="subtitle1">{client.phone}</Typography>
					</Box>
				</Grid>

				<Grid item xs={6} align="left">
					<Box display="flex" alignItems="center">
						<Typography className={classes.name} noWrap color="textSecondary" />
						<Typography variant="subtitle2" color="textSecondary" />
					</Box>
				</Grid>

				<Grid item xs={6} align="right">
					<Badge>
						<Typography variant="subtitle1" className={classes.package} />
					</Badge>
				</Grid>
			</Grid>
			{contacts && contacts.map((contact, i) => (
				<Grid container item xs={12} key={i}>
					<Grid item xs={4}>
						<Box display="flex" alignItems="center" style={{marginBottom: "3px"}}>
							<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
								account_circle
							</Icon>

							<Typography variant="body2" color="textSecondary">
								Contact {i + 1}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={8} align="left">
						<Typography variant="body2" color="textSecondary" style={{fontWeight: "700"}}>
							{contact.name}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Box display="flex" alignItems="center">
							<Icon style={{color: blue[500], marginRight: "4px"}} fontSize="small">
								phone
							</Icon>
							<Typography variant="body2" color="textSecondary">
								Phone:
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={5} align="left">
						<Typography variant="body2" color="textSecondary">
							{contact.phone}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Box display="flex" justifyContent="flex-end" alignItems="center">
							<WhatsAppIcon
								className={classes.icon}
								onClick={(e) => handleClickAction("Whatsapp", contact)}
								fontSize="small"
							/>
							<Icon
								className={classes.icon}
								onClick={(e) => handleClickAction("Call", contact)}
								fontSize="small">
								phone
							</Icon>
						</Box>
					</Grid>
				</Grid>
			))}
			<Grid item xs={12}>
				<Box my={1}>
					<Divider light />
				</Box>
			</Grid>
		</React.Fragment>
	);
}
